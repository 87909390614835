<template>
<scroll-fixed-header :fixed.sync="fixed" :threshold="163">
    <nav id="dynamic" class="hidden sm:block inset-x-0 top-0 z-10">
        <!-- class="hidden sm:block fixed inset-x-0 top-0 z-10"  -->
        <div class="max-w-7xl mx-auto bg-gray-50">
            <div class="relative">
                <div class="flex border divide-x divide-gray-200">
                    <!-- <img v-if="fixed == true" width="60px" src="../assets/img/nralogo.png" alt="South Sudan Revenue Authority" /> -->
                    <div style="padding: 7px" v-if="fixed == true" class="flex items-center justify-center">
                        <img class="w-auto h-9 mr-2" src="../assets/img/SSRA.png" alt="South Sudan Revenue Authority" />
                        <h1 class="uppercase font-bold text-3xl">NRA</h1>
                    </div>
                    <router-link :to="{name: 'index'}" 
                    :class="['index'].includes($route.name) ? 'py-3' : ' py-4'" 
                    class="px-8 text-sm text-gray-500 outline-none focus:outline-none py-3 inline-flex items-center uppercase">
                        <span :class="['index'].includes($route.name) ? 'bg-gray-900 text-gray-100 px-2 py-1 rounded' : '' ">Home</span>
                    </router-link>

                    <router-link :to="{
                  name: 'indIntro',
                  params: { id: '1', slug: 'individual-taxpayer' },
                }" 
                :class="['indIntro', 'tinRegistration', 'tax-for-ind', 'indFaqs'].includes($route.name) ? 'py-3' : ' py-4'" 
                    class="px-8 text-sm text-gray-500 outline-none focus:outline-none py-3 inline-flex items-center uppercase"
                >
                        <span :class="['indIntro', 'tinRegistration', 'tax-for-ind', 'indFaqs'].includes($route.name) ? 'bg-gray-900 text-gray-100 px-2 py-1 rounded' : '' ">Individual</span>
                    </router-link>

                    <router-link :to="{
                  name: 'bsnIntro',
                  params: { id: '1', slug: 'what-is-considered-a-business' },
                }" 
                :class="['bsnIntro', 'bsnTinReg', 'taxApp', 'penalties', 'deductions', 'taxComp', 'bsnFaqs'].includes($route.name) ? 'py-3' : ' py-4'" 
                    class="px-8 text-sm text-gray-500 outline-none focus:outline-none py-3 inline-flex items-center uppercase"
                >
                        <span :class="['bsnIntro', 'bsnTinReg', 'taxApp', 'penalties', 'deductions', 'taxComp', 'bsnFaqs'].includes($route.name) ? 'bg-gray-900 text-gray-100 px-2 py-1 rounded' : '' ">Business</span>
                    </router-link>

                    <router-link :to="{
                  name: 'invIntro',
                  params: { id: '1', slug: 'investment-in-south-sudan' },
                }" 
                :class="['invIntro'].includes($route.name) ? 'py-3' : ' py-4'" 
                    class="px-8 text-sm text-gray-500 outline-none focus:outline-none py-3 inline-flex items-center uppercase"
                >
                        <span :class="['invIntro'].includes($route.name) ? 'bg-gray-900 text-gray-100 px-2 py-1 rounded' : '' ">Investors</span>
                    </router-link>

                    <router-link :to="{
                  name: 'agentsIntro',
                  params: { id: '1', slug: 'agents' },
                }" 
                 :class="['agentsIntro'].includes($route.name) ? 'py-3' : ' py-4'" 
                    class="px-8 text-sm text-gray-500 outline-none focus:outline-none py-3 inline-flex items-center uppercase"
                >
                        <span :class="['agentsIntro'].includes($route.name) ? 'bg-gray-900 text-gray-100 px-2 py-1 rounded' : '' ">Agents</span>
                    </router-link>

                    <div style="padding: 12px" v-if="fixed == true" class="hidden sm:flex items-center justify-end sm:flex-1 lg:w-0">
                        <div v-if="loginRegLink" class="sm:flex items-center justify-end space-x-4 sm:flex-1 lg:w-0">
                            <a v-bind:href="loginRegLink.login" target="_blank" class="btn btn-blue">
                                LOG IN
                            </a>
                            <a v-bind:href="loginRegLink.register" target="_blank" class="btn btn-orange">
                                <svg class="h-5 w-5 text-gray-900 mr-2 -ml-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 11V7a4 4 0 118 0m-4 8v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2z" />
                                </svg>
                                <span>REGISTER</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </nav>
</scroll-fixed-header>
</template>

<script>
import gql from "graphql-tag";
export default {
    data() {
        return {
            fixed: false,
            //  stickyHeight:[],

            home: [],
            keyword: [],
            newsUpdates: [],
            headerImage: [],
            api_url: process.env.VUE_APP_STRAPI_API_URL,
            routeParam: this.$route.params.id,
        };
    },
    methods: {
        search() {
            console.log(this.keyword)
            this.$router.push("/search?" + this.keyword.search);
            localStorage.setItem('keyword', this.keyword.search)
        }
    },
    mounted() {
        window.scrollTo(0, 0);
    },

    apollo: {
        home: gql `
      query {
        home {
          section1 {
            title
            heading
            par1
            par2
          }
          section2 {
            title
            heading
            title1
            par1
            title2
            par2
            title3
            par3
          }
          section3 {
            title1
            body1
            title2
            body2
            title3
            body3
            title4
            body4
          }
          section4 {
            title1
            title2
          }
          section5 {
            title
            heading
          }
        }
      }
    `,
        loginRegLink: gql `
      # authLink
      query {
        loginRegLink {
          login
          register
        }
      }
    `,
        newsUpdates: gql `
      query {
        newsUpdates {
          id
          date
          body
          videoUrl
          youtubeUrl
          image {
            url
          }
        }
      }
    `,
        headerImage: gql `
      query headerImage {
        headerImage {
          homepage {
            url
          }
        }
      }
    `,
    },
};
</script>
