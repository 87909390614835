import Vue from "vue";
import App from "./App.vue";
import JwPagination from "jw-vue-pagination";
Vue.component("jw-pagination", JwPagination);

import VueApollo from "vue-apollo";
import apolloClient from "./vue-apollo";

import VueRouter from "vue-router";
Vue.use(VueRouter);

import VueAxios from "vue-axios";
import axios from "axios";
Vue.use(VueAxios, axios);

import ScrollFixedHeader from "vuejs-scroll-fixed-header";
Vue.use(ScrollFixedHeader);

import VuePageTransition from "vue-page-transition";
Vue.use(VuePageTransition);

import "./assets/build/tailwind.css";
import "./assets/tailwind.css";
import "./assets/build/mobileDrop/style.css";

Vue.config.productionTip = false;

Vue.use(VueApollo);

const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
});

import VueGtag from "vue-gtag";
Vue.use(VueGtag, {
  config: { id: "G-RZHBFK154P" },
});

const router = new VueRouter({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "index",
      components: require("./pages/Home.vue"),
    },
    {
      path: "/news/",
      name: "news",
      components: require("./pages/News.vue"),
    },

    // individual menu pages
    {
      path: "/individual/introduction/:id/:slug/",
      name: "indIntro",
      components: require("./individual/Introduction.vue"),
    },
    {
      path: "/individual/tin-registration/:id/:slug/",
      name: "tinRegistration",
      components: require("./individual/tinRegistration"),
    },
    {
      path: "/individual/taxes-for-individuals/:id/:slug/",
      name: "tax-for-ind",
      components: require("./individual/tax-for-ind.vue"),
    },
    {
      path: "/individual/frequently-asked-questions/",
      name: "indFaqs",
      components: require("./individual/freq-Ask-Que.vue"),
    },

    //Business pages
    {
      path: "/business/introduction/:id/:slug/",
      name: "bsnIntro",
      components: require("./business/introduction.vue"),
    },
    {
      path: "/business/tin-registration-for-business/:id/:slug/",
      name: "bsnTinReg",
      components: require("./business/tinReg.vue"),
    },
    {
      path: "/business/taxes-applied-to-business/:id/:slug/",
      name: "taxApp",
      components: require("./business/taxApplied.vue"),
    },
    {
      path: "/business/penalties/:id/:slug/",
      name: "penalties",
      components: require("./business/penalties.vue"),
    },
    {
      path: "/business/deduction-and-depreciation/:id/:slug/",
      name: "deductions",
      components: require("./business/deductions.vue"),
    },
    {
      path: "/business/tax-compliance-certificate/:id/:slug/",
      name: "taxComp",
      components: require("./business/taxComp.vue"),
    },
    {
      path: "/business/frequently-asked-questions/",
      name: "bsnFaqs",
      components: require("./business/freq-Ask-Que.vue"),
    },

    //agents
    {
      path: "/agents/introduction/:id/:slug/",
      name: "agentsIntro",
      components: require("./agents/introduction.vue"),
    },

    //investors
    {
      path: "/investors/introduction/:id/:slug",
      name: "invIntro",
      components: require("./investors/introduction.vue"),
    },

    // about
    {
      path: "/about/about-us/",
      name: "about-us",
      components: require("./about/aboutUs.vue"),
    },
    {
      path: "/about/leadership/",
      name: "leadership",
      components: require("./about/leadership.vue"),
    },
    {
      path: "/about/our-locations/",
      name: "locations",
      components: require("./about/our-locations.vue"),
    },

    // Topbar menu links

    {
      path: "/tools/",
      name: "tools",
      components: require("./pages/tools.vue"),
    },
    {
      path: "/tools/tin-search/",
      name: "tinSearch",
      components: require("./pages/tinSearch.vue"),
    },
    {
      path: "/tools/e-invoice-search/",
      name: "invoiceSearch",
      components: require("./pages/eInvoiceSearch.vue"),
    },

    {
      path: "/tools/verify-tcc/",
      name: "verify_tcc",
      components: require("./pages/VerifyTCC.vue"),
    },
    {
      path: "/media-center/",
      name: "mediaCenter",
      components: require("./media/mediaCenter.vue"),
    },
    //individual news for media news
    {
      path: "/media-center/:id/media-story/",
      name: "mediaStory",
      components: require("./media/mediaStory.vue"),
    },

    {
      path: "/legal/data-and-privacy/",
      name: "dataPrivacy",
      components: require("./legal/dataAndPrivacy.vue"),
    },
    {
      path: "/search/",
      name: "search",
      components: require("./pages/searchResult.vue"),
    },
    {
      path: "/resources/",
      name: "resources",
      components: require("./resources/resources.vue"),
    },
    {
      path: "/reports/",
      name: "reports",
      components: require("./resources/reports.vue"),
    },
    {
      path: "/application-status/",
      name: "tinStatus",
      components: require("./pages/tinStatus.vue"),
    },
    {
      path: "/career/",
      name: "career",
      components: require("./pages/career.vue"),
    },
  ],
});

new Vue({
  apolloProvider,
  router,
  render: (h) => h(App),
}).$mount("#app");
