<template>
  <div
    class="w-auto col-span-3 md:col-span-1 hidden md:block"
    x-data="{selected:0}"
  >
    <div class="flex flex-col overflow-hidden border-b border-gray-300">
      <router-link
        :to="{ name: 'about-us' }"
        class="block text-xl font-extrabold text-gray-900 py-3 cursor-pointer"
      >
        About Us
        <div class="relative float-right">
          <svg
            class="h-5 w-5 absolute top-1 right-0 transition-all duration-300 text-gray-700"
            x-bind:style="selected == 6 ? 'transform: rotate(90deg); color: #000000;' : ''"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M9 5l7 7-7 7"
            />
          </svg>
        </div>
      </router-link>
    </div>

    <div class="flex flex-col overflow-hidden border-b border-gray-300">
      <router-link
        :to="{ name: 'leadership' }"
        class="block text-xl font-extrabold text-gray-900 py-3 cursor-pointer"
      >
        Leadership
        <div class="relative float-right">
          <svg
            class="h-5 w-5 absolute top-1 right-0 transition-all duration-300 text-gray-300"
            x-bind:style="selected == 6 ? 'transform: rotate(90deg); color: #000000;' : ''"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M9 5l7 7-7 7"
            />
          </svg>
        </div>
      </router-link>
    </div>

    <div class="flex flex-col overflow-hidden border-b border-gray-300">
      <a
        href="#"
        class="block text-xl font-extrabold text-gray-900 py-3 cursor-pointer"
      >
        Our Locations
        <div class="relative float-right">
          <svg
            class="h-5 w-5 absolute top-1 right-0 transition-all duration-300 text-gray-300"
            x-bind:style="selected == 6 ? 'transform: rotate(90deg); color: #000000;' : ''"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M9 5l7 7-7 7"
            />
          </svg>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
import gql from "graphql-tag";

export default {
  name: "",
  data() {
    return {
      abouts: [],
      api_url: process.env.VUE_APP_STRAPI_API_URL,
      routeParam: this.$route.params.id,
    };
  },

  apollo: {
    abouts: gql`
      query abouts {
        abouts {
          id
          title
          heading
          intro
          slug
        }
      }
    `,
    leaderships: gql`
      query leaderships {
        leaderships {
          id
          title
          heading
          intro
          slug
        }
      }
    `,
    ourLocations: gql`
      query ourLocations {
        ourLocations {
          id
          title
          heading
          intro
          slug
        }
      }
    `,
    // variables() {
    //   return {
    //     id: this.routeParam,
    //   };
    // },
  },
};
</script>
