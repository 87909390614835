<template>
  <!-- Main NAV -->

  <scroll-fixed-header :fixed.sync="fixed" :threshold="163">
    <nav class="hidden sm:block">
      <div class="max-w-7xl mx-auto bg-gray-50">
        <div class="relative">
          <div class="flex border divide-x divide-gray-200">
            <!-- <img v-if="fixed == true" width="60px" src="./nralogo.png" alt="South Sudan Revenue Authority" /> -->
            <div
              style="padding: 7px"
              v-if="fixed == true"
              class="flex items-center justify-center"
            >
              <img
                class="w-auto h-9 mr-2"
                src="../../assets/img/SSRA.png"
                alt="South Sudan Revenue Authority"
              />
              <h1 class="uppercase font-bold text-3xl">NRA</h1>
            </div>
            <router-link
              :to="{ name: 'index' }"
              class="px-8 text-sm text-gray-500 outline-none focus:outline-none py-4 inline-flex items-center uppercase"
            >
              <span>Home</span>
            </router-link>

            <router-link
              :to="{ name: 'indIntro', params: { id: '1', slug:'individual-taxpayer' } }"
              class="px-8 text-sm text-gray-500 outline-none focus:outline-none py-4 inline-flex items-center uppercase"
            >
              <span>Individual</span>
            </router-link>

            <router-link
              :to="{
                name: 'bsnIntro',
                params: { id: '1', slug:'what-is-considered-a-business' },
              }"
              class="px-8 text-sm text-gray-500 outline-none focus:outline-none py-4 inline-flex items-center uppercase"
            >
              <span>Business</span>
            </router-link>

            <router-link
              :to="{
                name: 'invIntro',
                params: { id: '1', slug:'investment-in-south-sudan' },
              }"
              class="px-8 text-sm text-gray-500 outline-none focus:outline-none py-4 inline-flex items-center uppercase"
            >
              <span>Investors</span>
            </router-link>

            <router-link
              :to="{ name: 'agentsIntro', params: { id: '1', slug:'agents' } }"
              class="px-8 text-sm text-gray-500 outline-none focus:outline-none py-4 inline-flex items-center uppercase"
            >
              <span class="bg-gray-900 text-gray-100 px-2 py-1 rounded"
                >Agents</span
              >
            </router-link>

            <div
              style="padding: 12px"
              v-if="fixed == true"
              class="hidden sm:flex items-center justify-end sm:flex-1 lg:w-0"
            >
              <div
                class="sm:flex items-center justify-end space-x-4 sm:flex-1 lg:w-0"
              >
                <a v-bind:href="loginRegLink.login" target="_blank" class="btn btn-blue">
                  LOG IN
                </a>
                <a v-bind:href="loginRegLink.register" target="_blank" class="btn btn-orange">
                  <svg
                    class="h-5 w-5 text-gray-900 mr-2 -ml-3"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M8 11V7a4 4 0 118 0m-4 8v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2z"
                    />
                  </svg>
                  <span>REGISTER</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </scroll-fixed-header>
</template>

<script>
import gql from "graphql-tag";
export default {
  data() {
    return {
      fixed: false,
      loginRegLink: [],
    };
  },

  apollo: {
    loginRegLink: gql`
      query loginRegLink {
        loginRegLink {
          login
          register
        }
      }
    `,
  },
};
</script>

<style>
</style>
