var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-auto col-span-3 md:col-span-1 hidden md:block",attrs:{"x-data":"{selected:0}"}},[_c('div',{staticClass:"flex flex-col overflow-hidden border-b border-gray-300"},[_c('h3',{staticClass:"block text-xl font-extrabold text-gray-900 py-3 cursor-pointer",attrs:{"id":"0","x-on:click":"selected !== 0 ? selected = 0 : selected = null"}},[_vm._v(" Introduction "),_c('div',{staticClass:"relative float-right"},[_c('svg',{staticClass:"h-5 w-5 absolute top-1 right-0 transition-all duration-300 text-gray-300",attrs:{"x-bind:style":"selected == 0 ? 'transform: rotate(90deg); color: #000000;' : ''","xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","stroke":"currentColor"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M9 5l7 7-7 7"}})])])]),_c('div',{staticClass:"relative overflow-hidden transition-all max-h-0 duration-300",attrs:{"x-ref":"section1","x-bind:style":"selected == 0 ? 'max-height: ' + $refs.section1.scrollHeight + '200px' : ''"}},[_c('div',{staticClass:"py-6"},[_c('ul',{staticClass:"space-y-4"},_vm._l((_vm.individualIntroductions),function(indIntro){return _c('li',{key:indIntro.id,staticClass:"text-base truncate pb-3"},[_c('router-link',{key:indIntro.id,staticClass:"hover:text-gray-700",attrs:{"to":{
                name: 'indIntro',
                params: { id: indIntro.id, slug: indIntro.slug },
              }}},[_c('a',[_vm._v(_vm._s(indIntro.heading))])])],1)}),0)])])]),_c('div',{staticClass:"flex flex-col overflow-hidden border-b border-gray-300"},[_c('h3',{staticClass:"block text-xl font-extrabold text-gray-900 py-3 cursor-pointer",attrs:{"x-on:click":"selected !== 1 ? selected = 1 : selected = null"}},[_vm._v(" TIN Registration "),_c('div',{staticClass:"relative float-right"},[_c('svg',{staticClass:"h-5 w-5 absolute top-1 right-0 transition-all duration-300 text-gray-300",attrs:{"x-bind:style":"selected == 1 ? 'transform: rotate(90deg); color: #000000;' : ''","xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","stroke":"currentColor"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M9 5l7 7-7 7"}})])])]),_c('div',{staticClass:"relative overflow-hidden transition-all max-h-0 duration-300",attrs:{"x-ref":"section2","x-bind:style":"selected == 1 ? 'max-height: ' + $refs.section2.scrollHeight + 'px' : ''"}},[_c('div',{staticClass:"py-6"},[_c('ul',{staticClass:"space-y-4"},_vm._l((_vm.tinRegistrations),function(tin){return _c('li',{key:tin.id,staticClass:"text-base truncate pb-3"},[_c('router-link',{key:tin.id,staticClass:"hover:text-gray-700",attrs:{"to":{
                name: 'tinRegistration',
                params: { id: tin.id, slug: tin.slug },
              }}},[_vm._v(" "+_vm._s(tin.heading)+" ")])],1)}),0)])])]),_c('div',{staticClass:"flex flex-col overflow-hidden border-b border-gray-300"},[_c('h3',{staticClass:"block text-xl font-extrabold text-gray-900 py-3 cursor-pointer",attrs:{"x-on:click":"selected !== 2 ? selected = 2 : selected = null"}},[_vm._v(" Taxes for individuals "),_c('div',{staticClass:"relative float-right"},[_c('svg',{staticClass:"h-5 w-5 absolute top-1 right-0 transition-all duration-300 text-gray-300",attrs:{"x-bind:style":"selected == 2 ? 'transform: rotate(90deg); color: #000000;' : ''","xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","stroke":"currentColor"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M9 5l7 7-7 7"}})])])]),_c('div',{staticClass:"relative overflow-hidden transition-all max-h-0 duration-300",attrs:{"x-ref":"section3","x-bind:style":"selected == 2 ? 'max-height: ' + $refs.section3.scrollHeight + 'px' : ''"}},[_c('div',{staticClass:"py-6"},[_c('ul',{staticClass:"space-y-4"},_vm._l((_vm.taxesForIndividuals),function(tax){return _c('li',{key:tax.id,staticClass:"text-base truncate pb-3"},[_c('router-link',{key:tax.id,staticClass:"hover:text-gray-700",attrs:{"to":{
                name: 'tax-for-ind',
                params: { id: tax.id, slug: tax.slug },
              }}},[_vm._v(" "+_vm._s(tax.heading)+" ")])],1)}),0)])])]),_c('div',{staticClass:"flex flex-col overflow-hidden border-b border-gray-300"},[_c('router-link',{staticClass:"block text-xl font-extrabold text-gray-900 py-3 cursor-pointer",attrs:{"to":{ name: 'indFaqs' }}},[_vm._v(" Frequently Asked Questions "),_c('div',{staticClass:"relative float-right"},[_c('svg',{staticClass:"h-5 w-5 absolute top-1 right-0 text-gray-300",attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","stroke":"currentColor"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M9 5l7 7-7 7"}})])])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }