<template>
<div>
    <!-- Main NAV -->

    <scroll-fixed-header :fixed.sync="fixed" :threshold="163">
        <nav class="hidden sm:block">
            <div class="max-w-7xl mx-auto bg-gray-50">
                <div class="relative">
                    <div class="flex border divide-x divide-gray-200">
                        <div style="padding: 7px" v-if="fixed == true" class="flex items-center justify-center">
                            <img class="w-auto h-9 mr-2" src="../assets/img/SSRA.png" alt="South Sudan Revenue Authority" />
                            <h1 class="uppercase font-bold text-3xl">NRA</h1>
                        </div>

                        <router-link :to="{ name: 'index' }" class="px-8 text-sm text-gray-500 outline-none focus:outline-none py-4 inline-flex items-center uppercase">
                            <span>Home</span>
                        </router-link>

                        <router-link :to="{
                  name: 'indIntro',
                  params: { id: '1', slug: 'individual-taxpayer' },
                }" class="px-8 text-sm text-gray-500 outline-none focus:outline-none py-4 inline-flex items-center uppercase">
                            <span>Individual</span>
                        </router-link>

                        <router-link :to="{
                  name: 'bsnIntro',
                  params: { id: '1', slug: 'what-is-considered-a-business' },
                }" class="px-8 text-sm text-gray-500 outline-none focus:outline-none py-4 inline-flex items-center uppercase">
                            <span>Business</span>
                        </router-link>

                        <router-link :to="{
                  name: 'invIntro',
                  params: { id: '1', slug: 'investment-in-south-sudan' },
                }" class="px-8 text-sm text-gray-500 outline-none focus:outline-none py-4 inline-flex items-center uppercase">
                            <span>Investors</span>
                        </router-link>

                        <router-link :to="{
                  name: 'agentsIntro',
                  params: { id: '1', slug: 'agents' },
                }" class="px-8 text-sm text-gray-500 outline-none focus:outline-none py-4 inline-flex items-center uppercase">
                            <span>Agents</span>
                        </router-link>

                        <div style="padding: 12px" v-if="fixed == true" class="hidden sm:flex items-center justify-end sm:flex-1 lg:w-0">
                            <div class="sm:flex items-center justify-end space-x-4 sm:flex-1 lg:w-0">
                                <a v-bind:href="loginRegLink.login" target="_blank" class="btn btn-blue">
                                    LOG IN
                                </a>
                                <a v-bind:href="loginRegLink.register" target="_blank" class="btn btn-orange">
                                    <svg class="h-5 w-5 text-gray-900 mr-2 -ml-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 11V7a4 4 0 118 0m-4 8v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2z" />
                                    </svg>
                                    <span>REGISTER</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    </scroll-fixed-header>

    <!-- End Head -->

    <nav class="hidden sm:flex bg-white border-b border-gray-200" aria-label="Breadcrumb">
        <ol class="max-w-screen-xl w-full mx-auto p-4 flex space-x-4 sm:px-6 lg:px-8 uppercase border-l border-r">
            <li class="flex">
                <div class="flex items-center">
                    <router-link :to="{ name: 'index' }" class="ml-4 text-xs text-gray-500 hover:text-gray-700">Home</router-link>
                </div>
            </li>
            <li class="flex">
                <div class="flex items-center">
                    <svg class="flex-shrink-0 h-5 w-5 text-gray-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                    </svg>
                    <router-link :to="{ name: '' }" class="ml-4 text-xs text-gray-500 hover:text-gray-700">Latest News Updates</router-link>
                </div>
            </li>
        </ol>
    </nav>
    <main class="relative">
        <div class="relative vh-70 bg-gray-900 max-w-7xl mx-auto">
            <img class="absolute inset-0 w-full h-full object-cover z-0" src="../assets/img/heroimg.jpg" alt="NRA" />
            <div class="relative">
                <div class="px-8 py-12 md:pt-16 md:pb-28 md:px-24">
                    <p class="mt-3 uppercase text-xs sm:text-base text-nra-yellow-500 sm:mt-5">
                        TAX INFORMATION
                    </p>
                    <h1 class="text-2xl sm:text-xl ont-extrabold text-white sm:mt-1 lg:mt-1 lg:text-4xl">
                        <span class="sm:block">Latest News Updates</span>
                    </h1>
                </div>
            </div>
        </div>
        <div class="bg-white">
            <div class="pb-16 sm:pb-20">
                <div class="max-w-7xl mx-auto p-8 md:p-24 sm:p-24 lg:px-24 bg-white border-t-8 border-nra-blue-500 -mt-2 relative">
                    <b>Latest News Updates</b>
                    <hr />
                    <div class="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none sm:px-0">
                        <div class="flex flex-col overflow-hidden" v-for="news in newsUpdates" v-bind:key="news.id">
                            <div class="flex-shrink-0">
                                <!-- <img :src="api_url + article.image.url" alt="" height="100" /> -->
                                <!-- <img class="h-48 w-full object-cover" src="../assets/img/countingmoney.jpg" alt="" /> -->
                                <img class="h-48 w-full object-cover" :src="api_url + news.image.url" alt="" />
                            </div>
                            <div class="flex-1 py-6 flex flex-col justify-between">
                                <div class="flex-1">
                                    <p class="text-sm font-medium text-gray-500 uppercase">
                                        <time datetime="2020-03-10">
                                            {{ news.date }}
                                        </time>
                                    </p>
                                    <a href="#" class="block mt-2">
                                        <p class="text-xl font-semibold text-gray-900">
                                            {{ news.body }}
                                        </p>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>
</template>

<script>
import gql from "graphql-tag";

export default {
    data() {
        return {
            fixed: false,
            newsUpdates: [],
            loginRegLink: [],
            api_url: process.env.VUE_APP_STRAPI_API_URL,
            routeParam: this.$route.params.id,
        };
    },
    components: {
        // Header,
    },
    apollo: {
        newsUpdates: gql `
      query newsUpdates {
        newsUpdates {
          id
          date
          body
          image {
            url
          }
        }
      }
    `,
        loginRegLink: gql `
      query loginRegLink {
        loginRegLink {
          login
          register
        }
      }
    `,
        // variables() {
        //   return {
        //     id: this.routeParam,
        //   };
        // },
    },
};
</script>

<style></style>
