<template>
  <div>
    <scroll-fixed-header :fixed.sync="fixed" :threshold="163">
      <nav class="hidden sm:block">
        <div class="max-w-7xl mx-auto bg-gray-50">
          <div class="relative">
            <div class="flex border divide-x divide-gray-200">
              <div
                style="padding: 7px"
                v-if="fixed == true"
                class="flex items-center justify-center"
              >
                <img
                  class="w-auto h-9 mr-2"
                  src="../assets/img/SSRA.png"
                  alt="South Sudan Revenue Authority"
                />
                <h1 class="uppercase font-bold text-3xl">NRA</h1>
              </div>

              <router-link
                :to="{ name: 'index' }"
                class="px-8 text-sm text-gray-500 outline-none focus:outline-none py-4 inline-flex items-center uppercase"
              >
                <span>Home</span>
              </router-link>

              <router-link
                :to="{
                  name: 'indIntro',
                  params: { id: '1', slug: 'individual-taxpayer' },
                }"
                class="px-8 text-sm text-gray-500 outline-none focus:outline-none py-4 inline-flex items-center uppercase"
              >
                <span>Individual</span>
              </router-link>

              <router-link
                :to="{
                  name: 'bsnIntro',
                  params: { id: '1', slug: 'what-is-considered-a-business' },
                }"
                class="px-8 text-sm text-gray-500 outline-none focus:outline-none py-4 inline-flex items-center uppercase"
              >
                <span>Business</span>
              </router-link>

              <router-link
                :to="{
                  name: 'invIntro',
                  params: { id: '1', slug: 'investment-in-south-sudan' },
                }"
                class="px-8 text-sm text-gray-500 outline-none focus:outline-none py-4 inline-flex items-center uppercase"
              >
                <span>Investors</span>
              </router-link>

              <router-link
                :to="{
                  name: 'agentsIntro',
                  params: { id: '1', slug: 'agents' },
                }"
                class="px-8 text-sm text-gray-500 outline-none focus:outline-none py-4 inline-flex items-center uppercase"
              >
                <span>Agents</span>
              </router-link>

              <div
                style="padding: 12px"
                v-if="fixed == true"
                class="hidden sm:flex items-center justify-end sm:flex-1 lg:w-0"
              >
                <div
                  class="sm:flex items-center justify-end space-x-4 sm:flex-1 lg:w-0"
                >
                  <a
                    v-bind:href="loginRegLink.login"
                    target="_blank"
                    class="btn btn-blue"
                  >
                    LOG IN
                  </a>
                  <a
                    v-bind:href="loginRegLink.register"
                    target="_blank"
                    class="btn btn-orange"
                  >
                    <svg
                      class="h-5 w-5 text-gray-900 mr-2 -ml-3"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M8 11V7a4 4 0 118 0m-4 8v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2z"
                      />
                    </svg>
                    <span>REGISTER</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </scroll-fixed-header>

    <!-- End Head -->

    <nav
      class="hidden sm:flex bg-white border-b border-gray-200"
      aria-label="Breadcrumb"
    >
      <ol
        class="max-w-screen-xl w-full mx-auto p-4 flex space-x-4 sm:px-6 lg:px-8 uppercase border-l border-r"
      >
        <li class="flex">
          <div class="flex items-center">
            <router-link
              :to="{ name: 'index' }"
              class="ml-4 text-xs text-gray-500 hover:text-gray-700"
              >Home</router-link
            >
          </div>
        </li>
        <li class="flex">
          <div class="flex items-center">
            <svg
              class="flex-shrink-0 h-5 w-5 text-gray-300"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clip-rule="evenodd"
              />
            </svg>
            <router-link
              :to="{ name: '' }"
              class="ml-4 text-xs text-gray-500 hover:text-gray-700"
              >Tools</router-link
            >
          </div>
        </li>
        <li class="flex">
          <div class="flex items-center">
            <svg
              class="flex-shrink-0 h-5 w-5 text-gray-300"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clip-rule="evenodd"
              />
            </svg>
            <router-link
              :to="{ name: '' }"
              class="ml-4 text-xs text-gray-500 hover:text-gray-700"
              >Invoice Search</router-link
            >
          </div>
        </li>
      </ol>
    </nav>

    <!-- main -->

    <main class="relative">
      <div class="bg-gray-50">
        <div class="max-w-7xl mx-auto relative">
          <div class="relative overflow-hidden">
            <div
              class="hidden lg:block lg:absolute lg:inset-0"
              aria-hidden="true"
            >
              <svg
                class="absolute top-0 right-1/2 transform translate-x-64 -translate-y-8"
                width="640"
                height="784"
                fill="none"
                viewBox="0 0 640 784"
              >
                <defs>
                  <pattern
                    id="9ebea6f4-a1f5-4d96-8c4e-4c2abf658047"
                    x="118"
                    y="0"
                    width="20"
                    height="20"
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x="0"
                      y="0"
                      width="4"
                      height="4"
                      class="text-gray-200"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  y="72"
                  width="640"
                  height="640"
                  class="text-gray-100"
                  fill="currentColor"
                />
                <rect
                  x="118"
                  width="404"
                  height="784"
                  fill="url(#9ebea6f4-a1f5-4d96-8c4e-4c2abf658047)"
                />
              </svg>
            </div>
            <div class="relative">
              <main
                class="mt-16 mx-auto max-w-7xl px-4 sm:mt-24 sm:px-6 lg:my-32"
              >
                <div class="lg:grid lg:grid-cols-12 lg:gap-1">
                  <div class="sm:text-center lg:col-span-9 lg:text-left">
                    <h1>
                      <span
                        class="text-2xl block tracking-tight font-extrabold xl:text-4xl text-gray-900"
                      >
                        e-Invoice Verification
                      </span>
                    </h1>
                    <p
                      class="mt-1 text-base text-gray-500 sm:text-xl lg:text-lg xl:text-xl"
                    >
                      Verify a B2B e-invoice
                    </p>
                    <div
                      class="mt-8 sm:max-w-lg sm:mx-auto sm:text-center lg:text-left lg:mx-0"
                    >
                      <form
                        @submit.prevent="search"
                        action=""
                        method=""
                        class="mt-3 sm:flex"
                      >
                        <label for="einvoice-number" class="sr-only"
                          >einvoice-number</label
                        >
                        <input
                          v-if="show_alert == false"
                          required
                          type="text"
                          name="einvoice-number"
                          id="einvoice-number"
                          @input="
                            info.invoice = $event.target.value.toUpperCase()
                          "
                          v-model="info.invoice"
                          class="block w-full py-3 px-4 text-base rounded-md placeholder-gray-500 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:flex-1 ring-1 ring-black ring-opacity-5"
                          placeholder="Enter e-invoice Number"
                        />

                        <input
                          v-if="validate != '' && show_alert == true"
                          disabled
                          v-model="info.invoice"
                          class="block w-full py-3 px-4 text-base rounded-md placeholder-gray-500 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:flex-1 ring-1 ring-black ring-opacity-5"
                        />
                        <button
                          type="submit"
                          class="mt-3 w-full px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-nra-blue-500 shadow-sm hover:bg-nra-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:flex-shrink-0 sm:inline-flex sm:items-center sm:w-auto"
                        >
                          Search
                        </button>
                      </form>
                    </div>
                    <br />

                    <div
                      class="sm:max-w-lg sm:mx-auto lg:col-span-full lg:text-left lg:mx-0 grid gap-2"
                    >
                      <!-- VALID INVOICE -->
                      <div
                        v-if="validate == 'valid' && show_alert == true"
                        class="sm:max-w-lg bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden"
                      >
                        <div class="p-4">
                          <div class="flex items-start">
                            <div class="flex-shrink-0">
                              <!-- Heroicon name: outline/check-circle -->
                              <svg
                                class="h-6 w-6 text-green-500"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M6.267 3.455a3.066 3.066 0 001.745-.723 3.066 3.066 0 013.976 0 3.066 3.066 0 001.745.723 3.066 3.066 0 012.812 2.812c.051.643.304 1.254.723 1.745a3.066 3.066 0 010 3.976 3.066 3.066 0 00-.723 1.745 3.066 3.066 0 01-2.812 2.812 3.066 3.066 0 00-1.745.723 3.066 3.066 0 01-3.976 0 3.066 3.066 0 00-1.745-.723 3.066 3.066 0 01-2.812-2.812 3.066 3.066 0 00-.723-1.745 3.066 3.066 0 010-3.976 3.066 3.066 0 00.723-1.745 3.066 3.066 0 012.812-2.812zm7.44 5.252a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                  clip-rule="evenodd"
                                />
                              </svg>
                            </div>
                            <div class="ml-3 w-0 flex-1 pt-0.5">
                              <p class="text-sm font-medium text-green-500">
                                e-Invoice
                                <span class="font-bold">{{
                                  info.invoice
                                }}</span>
                                is Valid
                              </p>
                            </div>
                            <div class="ml-4 flex-shrink-0 flex">
                              <button
                                v-on:click="
                                  show_alert = false;
                                  info.invoice = '';
                                  validate = '';
                                "
                                class="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                              >
                                <span class="sr-only">Close</span>
                                <!-- Heroicon name: solid/x -->
                                <svg
                                  class="h-5 w-5"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  aria-hidden="true"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                    clip-rule="evenodd"
                                  />
                                </svg>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>

                      <!-- INVALID INVOICE -->
                      <div
                        v-if="validate == 'invalid' && show_alert == true"
                        class="sm:max-w-lg bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden"
                      >
                        <div class="p-4">
                          <div class="flex items-start">
                            <div class="flex-shrink-0">
                              <!-- Heroicon name: outline/check-circle -->
                              <svg
                                class="h-6 w-6 text-red-500"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                                  clip-rule="evenodd"
                                />
                              </svg>
                            </div>
                            <div class="ml-3 w-0 flex-1 pt-0.5">
                              <p class="text-sm font-medium text-red-500">
                                e-Invoice
                                <span class="font-bold">{{
                                  info.invoice
                                }}</span>
                                is invalid
                              </p>
                            </div>
                            <div class="ml-4 flex-shrink-0 flex">
                              <button
                                v-on:click="
                                  show_alert = false;
                                  info.invoice = '';
                                  validate = '';
                                "
                                class="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                              >
                                <span class="sr-only">Close</span>
                                <!-- Heroicon name: solid/x -->
                                <svg
                                  class="h-5 w-5"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  aria-hidden="true"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                    clip-rule="evenodd"
                                  />
                                </svg>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center"
                  >
                    <svg
                      class="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-8 scale-75 origin-top sm:scale-100 lg:hidden"
                      width="640"
                      height="784"
                      fill="none"
                      viewBox="0 0 640 784"
                      aria-hidden="true"
                    >
                      <defs>
                        <pattern
                          id="4f4f415c-a0e9-44c2-9601-6ded5a34a13e"
                          x="118"
                          y="0"
                          width="20"
                          height="20"
                          patternUnits="userSpaceOnUse"
                        >
                          <rect
                            x="0"
                            y="0"
                            width="4"
                            height="4"
                            class="text-gray-200"
                            fill="currentColor"
                          />
                        </pattern>
                      </defs>
                      <rect
                        y="72"
                        width="640"
                        height="640"
                        class="text-gray-50"
                        fill="currentColor"
                      />
                      <rect
                        x="118"
                        width="404"
                        height="784"
                        fill="url(#4f4f415c-a0e9-44c2-9601-6ded5a34a13e)"
                      />
                    </svg>
                  </div>
                </div>
              </main>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import gql from "graphql-tag";

export default {
  data() {
    return {
      fixed: false,
      show_alert: false,
      loginRegLink: [],
      info: {},
      data: {},
      token: "",
      validate: "",
      api_url: process.env.VUE_APP_STRAPI_API_URL,
      routeParam: this.$route.params.id,
    };
  },

  components: {
    // Header,
  },
  apollo: {
    loginRegLink: gql`
      query loginRegLink {
        loginRegLink {
          login
          register
        }
      }
    `,
  },
  mounted() {
    window.scrollTo(0, 0);
    this.info.invoice = this.$route.query.invoice_number;
    if (this.info.invoice) this.search();
  },
  methods: {
    search() {
      const api = this.axios.create({
        headers: {
          Authorization:
            "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6IjJxN1RkM21qcWRRMjNwTlA3VEoifQ.SYKYZUSa7ip-zfphUw1OXHyRXNdg3F4Fn_V1mM7UI_4",
        }
      });

      api
        .get(
          `https://etax.nra.gov.ss/api/b2b/invoices/get/${this.info.invoice}`
        )
        .then((response) => {
          if (response.data) {
            this.show_alert = true;
            this.validate = "valid";
          }
        })
        .catch((err) => {
          err;
          this.show_alert = true;
          this.validate = "invalid";
        });
    },
  },
};
</script>
