<template>
  <!-- Mobile Content Navigation -->
  <div class="px-6 py-9 bg-gray-200 border border-gray-300 md:hidden sm:block">
    <div class="dropdown inline-block w-full relative">
      <button
        class="bg-white w-full text-gray-700 font-semibold py-2 px-4 pr-2 rounded inline-flex items-between justify-between focus:outline-none"
      >
        <span class="mr-1">Select Topic</span>
        <svg
          class="fill-current h-5 w-5 relative top-1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <path
            d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
          />
        </svg>
      </button>
      <ul
        class="dropdown-content bg-white border border-gray-300 rounded-md dropdown-menu absolute hidden text-gray-700 mt-1 pt-1 z-10 w-full"
      >
        <li class="font-bold">
          <p class="text-gray-400 text-sm py-2 px-4 block whitespace-no-wrap">
            Section 1/7: Introduction
          </p>
        </li>
        <!-- Active Menu -->
        <!-- <li class="font-bold text-sm bg-gray-100 text-nra-blue-500"><a class="ml-4 py-3 px-4 block whitespace-no-wrap" href="#">Individual Taxpayer</a></li> -->

        <div>
          <li v-for="bsn in businessIntroductions" v-bind:key="bsn.id">
            <router-link
              :to="{
                name: 'bsnIntro',
                params: { id: bsn.id, slug: bsn.slug },
              }"
              :key="bsn.id"
              class="ml-4 py-2 px-4 block whitespace-no-wrap"
              >{{ bsn.heading }}</router-link
            >
          </li>
        </div>

        <li class="font-bold">
          <p class="text-gray-400 text-sm py-2 px-4 block whitespace-no-wrap">
            Section 2/7: TIN Registration for business
          </p>
        </li>
        <div>
          <li v-for="tin in tinRegBuses" v-bind:key="tin.id">
            <router-link
              :to="{
                name: 'bsnTinReg',
                params: { id: tin.id, slug: tin.slug },
              }"
              :key="tin.id"
              class="ml-4 py-2 px-4 block whitespace-no-wrap"
              >{{ tin.heading }}</router-link
            >
          </li>
        </div>
        <li class="font-bold">
          <p class="text-gray-400 text-sm py-2 px-4 block whitespace-no-wrap">
            Section 3/7: Taxes applied to Businesses
          </p>
        </li>
        <div>
          <li v-for="tax in taxAppBsns" v-bind:key="tax.id">
            <router-link
              :to="{
                name: 'taxApp',
                params: { id: tax.id, slug: tax.slug },
              }"
              :key="tax.id"
              class="ml-4 py-2 px-4 block whitespace-no-wrap"
              >{{ tax.heading }}</router-link
            >
          </li>
        </div>
        <li class="font-bold">
          <p class="text-gray-400 text-sm py-2 px-4 block whitespace-no-wrap">
            Section 4/7: Penalties
          </p>
        </li>
        <div>
          <li v-for="penalty in penalties" v-bind:key="penalty.id">
            <router-link
              :to="{
                name: 'penalties',
                params: { id: penalty.id, slug: penalty.slug },
              }"
              :key="penalty.id"
              class="ml-4 py-2 px-4 block whitespace-no-wrap"
              >{{ penalty.heading }}</router-link
            >
          </li>
        </div>
        <li class="font-bold">
          <p class="text-gray-400 text-sm py-2 px-4 block whitespace-no-wrap">
            Section 5/7: Deduction and depreciation
          </p>
        </li>
        <div>
          <li v-for="dnd in dedAndDeps" v-bind:key="dnd.id">
            <router-link
              :to="{
                name: 'deductions',
                params: { id: dnd.id, slug: dnd.slug },
              }"
              :key="dnd.id"
              class="ml-4 py-2 px-4 block whitespace-no-wrap"
              >{{ dnd.heading }}</router-link
            >
          </li>
        </div>
        <li class="font-bold">
          <p class="text-gray-400 text-sm py-2 px-4 block whitespace-no-wrap">
            Section 6/7: Tax Compliance Certificate
          </p>
        </li>
        <div>
          <li v-for="tcc in taxComCerts" v-bind:key="tcc.id">
            <router-link
              :to="{
                name: 'taxComp',
                params: { id: tcc.id, slug: tcc.slug },
              }"
              :key="tcc.id"
              class="ml-4 py-2 px-4 block whitespace-no-wrap"
              >{{ tcc.heading }}</router-link
            >
          </li>
        </div>
        <li class="font-bold">
          <p class="text-gray-400 text-sm py-2 px-4 block whitespace-no-wrap">
            Section 7/7: Frequently Asked Questions
          </p>
        </li>
        <li>
          <router-link
            :to="{ name: 'bsnFaqs' }"
            class="ml-4 py-3 px-4 block whitespace-no-wrap"
            >Frequently Asked Questions</router-link
          >
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import gql from "graphql-tag";

export default {
  name: "",
  data() {
    return {
      businessIntroductions: [],
      tinRegBuses: [],
      taxAppBsns: [],
      penalties: [],
      dedAndDeps: [],
      taxComCerts: [],
      api_url: process.env.VUE_APP_STRAPI_API_URL,
      routeParam: this.$route.params.id,
    };
  },
  apollo: {
    businessIntroductions: gql`
      query businessIntroductions {
        businessIntroductions {
          id
          heading
          slug
        }
      }
    `,
    tinRegBuses: gql`
      query tinRegBuses {
        tinRegBuses {
          id
          heading
          slug
        }
      }
    `,
    taxAppBsns: gql`
      query taxAppBsns {
        taxAppBsns {
          id
          heading
          slug
        }
      }
    `,
    penalties: gql`
      query penalties {
        penalties {
          id
          heading
          slug
        }
      }
    `,
    dedAndDeps: gql`
      query dedAndDeps {
        dedAndDeps {
          id
          heading
          slug
        }
      }
    `,
    taxComCerts: gql`
      query taxComCerts {
        taxComCerts {
          id
          heading
          slug
        }
      }
    `,
    // variables() {
    //   return {
    //     id: this.routeParam,
    //   };
    // },
  },
};
</script>
<style scoped>
a.router-link-exact-active {
  --tw-text-opacity: 1;
  color: rgba(0, 93, 180, var(--tw-text-opacity));
}
a.router-link-exact-active {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
a.router-link-exact-active {
  font-weight: 700;
}
a.router-link-exact-active {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
}
</style>