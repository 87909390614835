<template>
  <div class="relative bg-white">
    <!-- Top Header -->
    <Header />

    <!-- Pages will be rendered here -->

    <vue-page-transition name="fade-in-down">
      <router-view :key="$route.fullPath"></router-view>
    </vue-page-transition>

    <!-- Footer -->
    <Footer />
  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
import Footer from "./components/Footer";
import Header from "./components/Header";

export default {
  name: "App",
  data() {
    return {};
  },
  components: {
    Footer,
    Header,
  },
  methods: {
    track () {
      this.$gtag.pageview({ page_path: '/' })
      
      this.$gtag.pageview('/')
      
      this.$gtag.pageview(this.$route)
    }
  }
}
</script>

