<template>
<div>
    <MainNav />
      <nav
      class="hidden sm:flex bg-white border-b border-gray-200"
      aria-label="Breadcrumb"
    >
      <ol class="
          max-w-screen-xl
          w-full
          mx-auto
          p-4
          flex
          space-x-4
          sm:px-6
          lg:px-8
          uppercase
          border-l border-r
        ">
        <li class="flex">
          <div class="flex items-center">
            <router-link
              :to="{ name: 'index' }"
              class="ml-4 text-xs text-gray-500 hover:text-gray-700"
            >Home</router-link>
          </div>
        </li>
        <li class="flex">
          <div class="flex items-center">
            <svg
              class="flex-shrink-0 h-5 w-5 text-gray-300"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clip-rule="evenodd"
              />
            </svg>
            <router-link
              :to="{ name: '' }"
              class="ml-4 text-xs text-gray-500 hover:text-gray-700"
            >Careers</router-link>
          </div>
        </li>
      </ol>
    </nav>
    <main class="relative">
 
      <div class="relative vh-70 bg-gray-900 max-w-7xl mx-auto">
        <img
          v-if="headerImage.resource"
          class="absolute inset-0 w-full h-full object-cover z-0"
          :src="api_url + headerImage.resource.url"
          alt="NRA"
        />
        <div class="relative">
          <div class="px-8 py-12 md:pt-16 md:pb-28 md:px-24">
            <h1 class="
                text-2xl
                sm:text-xl
                ont-extrabold
                text-white
                sm:mt-1
                lg:mt-1 lg:text-4xl
              ">
              <!-- <span class="sm:block"></span> -->
              <span class="sm:block">Careers</span>
            </h1>
          </div>
        </div>
      </div>
      <div class="bg-white">
        <div class="pb-16 sm:pb-20">
          <div class="
              max-w-7xl
              mx-auto
              p-8
              md:p-24
              sm:p-24
              lg:px-24
              bg-white
              border-t-8 border-nra-blue-500
              -mt-2
              relative
            ">
            <div class="grid grid-cols-3 gap-12">
              <div class="w-auto col-span-3 md:col-span-1">
                <!-- Resources Search -->
                <div class="mb-4 pb-4">
                  <form @submit.prevent="isShowing = true">
                    <label
                      for="search-resources"
                      class="block text-sm font-medium text-gray-700 sr-only"
                    >Search Career</label>
                    <div class="
                        mt-1
                        flex
                        border border-gray-300
                        rounded-md
                        shadow-sm
                        p-1
                      ">
                      <div class="
                          relative
                          flex
                          items-stretch
                          flex-grow
                          focus-within:z-10
                        ">
                        <input
                          v-on:keyup="filter()"
                          required
                          v-model="message.filterval"
                          type="text"
                          name="search-resources"
                          id="search-resources"
                          class="
                            focus:ring-nra-blue-500 focus:border-nra-blue-500
                            block
                            w-full
                            rounded-none rounded-l-md
                            pl-3
                            sm:text-sm
                            border-gray-300
                          "
                          placeholder="Search Careers"
                        />
                      </div>
                      <button
                        @click="filter"
                        class="
                          -ml-px
                          relative
                          inline-flex
                          items-center
                          space-x-2
                          px-3
                          py-2
                          text-sm
                          font-medium
                          rounded-md
                          text-gray-700
                          bg-nra-blue-500
                          hover:bg-nra-blue-500
                          focus:outline-none
                          focus:ring-1
                          focus:ring-nra-blue-500
                          focus:border-nra-blue-500
                        "
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-6 w-6 text-white"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                          />
                        </svg>
                      </button>
                    </div>
                  </form>
                </div>
              </div>

              <!-- ... -->
              <div class="w-auto col-span-3 md:col-span-2">
                <!-- Resource Item -->

                <!-- <div v-if="!filteredFiles.title">
                  <b>
                    <p class="mb-8 mt-3 ml-2 text-sm">Showieeng results for</p>
                    <hr />
                  </b>
                </div> -->
                <div
                  v-for="resource in filteredFiles"
                  v-bind:key="resource.id"
                  class="
                    md:flex
                    justify-between
                    p-2
                    border border-gray-300
                    p-1
                    rounded-md
                    mb-4
                    md:pr-4
                  "
                >
                  <div class="flex">
                    <div class="
                        mr-4
                        flex-shrink-0
                        relative
                        bg-nra-blue-50
                        rounded-sm
                        p-5
                      ">
                      <div class="bg-nra-blue-500 rounded-sm p-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-10 w-10 mx-auto my-2 text-nra-blue-50"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4zm2 6a1 1 0 011-1h6a1 1 0 110 2H7a1 1 0 01-1-1zm1 3a1 1 0 100 2h6a1 1 0 100-2H7z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </div>
                    </div>
                    <div class="md:mt-4 md:mr-8">
                      <a
                        v-bind:href="api_url + resource.document.url"
                        target="_blank"
                        class="text-xl font-bold hover:text-nra-blue-500"
                      >
                        {{ resource.title }}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-5 w-5 inline -mt-1"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                          />
                        </svg>
                      </a>
                      <p class="mt-1 text-sm text-gray-500">
                        Published: {{ resource.date }}
                      </p>
                    </div>
                  </div>
                  <div class="md:ml-8">
                    <a
                      :href="api_url + resource.document.url"
                      download
                      target="_blank"
                      class="
                        inline-flex
                        items-center
                        mt-5
                        md:mt-10
                        px-3
                        py-2
                        border border-nra-blue-500
                        shadow-sm
                        text-sm
                        leading-4
                        font-medium
                        rounded-md
                        text-gray-700
                        bg-white
                        hover:bg-gray-50
                        focus:outline-none
                        focus:ring-2
                        focus:ring-offset-2
                        focus:ring-nra-blue-500
                      "
                      rel="noopener noreferrer"
                    >
                      DOWNLOAD
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 w-5 inline ml-3"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                        />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
</div>
</template>

<script>
import MainNav from "@/components/MainNav.vue"

export default {
    data() {
        return {
      headerImage: [],
      isShowing: false,
      api_url: process.env.VUE_APP_STRAPI_API_URL,
      routeParam: this.$route.params.id,
      resources: [],
      result2: [],
      list: [],
      message: {},
      selectedCategory: "all",
      filteredFiles: [],
        }
    },
    components:{
        MainNav
    },
      mounted() {
    window.scrollTo(0, 0);

    this.axios.get(`https://cms.nra.gov.ss/careers`).then((response) => {
   
                 this.resources = response.data;
           this.filteredFiles = response.data
      
    });

  },
  created() {
    this.filteredFiles = this.resources;
  },
  methods: {
    filter() {
      let filterval = this.message.filterval;
      this.filteredFiles = this.resources.filter((resource) =>
        resource.title.toLowerCase().includes(filterval.toLowerCase()) ||
        resource.date.toLowerCase().includes(filterval.toLowerCase())
      );
    },
    checkbx() {
      var category = this.selectedCategory;

      if (category === "all") {
        this.filteredFiles = this.resources;
      } else {
        this.filteredFiles = this.resources.filter(function (document) {
          return document.type === category;
        });
      }
    },
  },

}
</script>

<style>

</style>