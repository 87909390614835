<template>
  <div>
    <scroll-fixed-header :fixed.sync="fixed" :threshold="163">
      <nav class="hidden sm:block">
        <div class="max-w-7xl mx-auto bg-gray-50">
          <div class="relative">
            <div class="flex border divide-x divide-gray-200">
              <div
                style="padding: 7px"
                v-if="fixed == true"
                class="flex items-center justify-center"
              >
                <img
                  class="w-auto h-9 mr-2"
                  src="../assets/img/SSRA.png"
                  alt="South Sudan Revenue Authority"
                />
                <h1 class="uppercase font-bold text-3xl">NRA</h1>
              </div>

              <router-link
                :to="{ name: 'index' }"
                class="px-8 text-sm text-gray-500 outline-none focus:outline-none py-4 inline-flex items-center uppercase"
              >
                <span>Home</span>
              </router-link>

              <router-link
                :to="{
                  name: 'indIntro',
                  params: { id: '1', slug: 'individual-taxpayer' },
                }"
                class="px-8 text-sm text-gray-500 outline-none focus:outline-none py-4 inline-flex items-center uppercase"
              >
                <span>Individual</span>
              </router-link>

              <router-link
                :to="{
                  name: 'bsnIntro',
                  params: { id: '1', slug: 'what-is-considered-a-business' },
                }"
                class="px-8 text-sm text-gray-500 outline-none focus:outline-none py-4 inline-flex items-center uppercase"
              >
                <span>Business</span>
              </router-link>

              <router-link
                :to="{
                  name: 'invIntro',
                  params: { id: '1', slug: 'investment-in-south-sudan' },
                }"
                class="px-8 text-sm text-gray-500 outline-none focus:outline-none py-4 inline-flex items-center uppercase"
              >
                <span>Investors</span>
              </router-link>

              <router-link
                :to="{
                  name: 'agentsIntro',
                  params: { id: '1', slug: 'agents' },
                }"
                class="px-8 text-sm text-gray-500 outline-none focus:outline-none py-4 inline-flex items-center uppercase"
              >
                <span>Agents</span>
              </router-link>

              <div
                style="padding: 12px"
                v-if="fixed == true"
                class="hidden sm:flex items-center justify-end sm:flex-1 lg:w-0"
              >
                <div
                  class="sm:flex items-center justify-end space-x-4 sm:flex-1 lg:w-0"
                >
                  <a
                    v-bind:href="loginRegLink.login"
                    target="_blank"
                    class="btn btn-blue"
                  >
                    LOG IN
                  </a>
                  <a
                    v-bind:href="loginRegLink.register"
                    target="_blank"
                    class="btn btn-orange"
                  >
                    <svg
                      class="h-5 w-5 text-gray-900 mr-2 -ml-3"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M8 11V7a4 4 0 118 0m-4 8v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2z"
                      />
                    </svg>
                    <span>REGISTER</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </scroll-fixed-header>

    <!-- End Head -->

    <nav
      class="hidden sm:flex bg-white border-b border-gray-200"
      aria-label="Breadcrumb"
    >
      <ol
        class="max-w-screen-xl w-full mx-auto p-4 flex space-x-4 sm:px-6 lg:px-8 uppercase border-l border-r"
      >
        <li class="flex">
          <div class="flex items-center">
            <router-link
              :to="{ name: 'index' }"
              class="ml-4 text-xs text-gray-500 hover:text-gray-700"
              >Home</router-link
            >
          </div>
        </li>
        <li class="flex">
          <div class="flex items-center">
            <svg
              class="flex-shrink-0 h-5 w-5 text-gray-300"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clip-rule="evenodd"
              />
            </svg>
            <router-link
              :to="{ name: '' }"
              class="ml-4 text-xs text-gray-500 hover:text-gray-700"
              >Resources</router-link
            >
          </div>
        </li>
        <li class="flex">
          <div class="flex items-center">
            <svg
              class="flex-shrink-0 h-5 w-5 text-gray-300"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clip-rule="evenodd"
              />
            </svg>
            <router-link
              :to="{ name: '' }"
              class="ml-4 text-xs text-gray-500 hover:text-gray-700"
              >Tools</router-link
            >
          </div>
        </li>
      </ol>
    </nav>

    <!-- main -->

    <main class="relative">
      <div class="bg-gray-50">
        <div class="max-w-7xl mx-auto relative">
          <div class="relative overflow-hidden">
            <div
              class="hidden lg:block lg:absolute lg:inset-0"
              aria-hidden="true"
            >
              <svg
                class="absolute top-0 right-1/2 transform translate-x-64 -translate-y-8"
                width="640"
                height="784"
                fill="none"
                viewBox="0 0 640 784"
              >
                <defs>
                  <pattern
                    id="9ebea6f4-a1f5-4d96-8c4e-4c2abf658047"
                    x="118"
                    y="0"
                    width="20"
                    height="20"
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x="0"
                      y="0"
                      width="4"
                      height="4"
                      class="text-gray-200"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  y="72"
                  width="640"
                  height="640"
                  class="text-gray-100"
                  fill="currentColor"
                />
                <rect
                  x="118"
                  width="404"
                  height="784"
                  fill="url(#9ebea6f4-a1f5-4d96-8c4e-4c2abf658047)"
                />
              </svg>
            </div>
            <div class="relative">
              <main
                class="mt-16 mx-auto max-w-7xl px-4 sm:mt-24 sm:px-6 my-20 lg:my-32"
              >
                <div
                  class="md:grid md:grid-cols-12 md:gap-12 space-y-5 md:space-y-0"
                >
                  <div class="md:col-span-6">
                    <div class="bg-white overflow-hidden shadow rounded-lg">
                      <div class="px-5 md:px-12 py-5 md:py-12">
                        <p class="mt-2 text-3xl font-bold text-gray-900">
                          e-Invoice Verification
                        </p>
                        <p class="mt-4 text-lg text-gray-500">
                          Verify an e-Invoice by using
                          <br class="hidden md:block" />
                          the invoice number?
                        </p>
                      </div>
                      <div class="bg-gray-50 px-4 py-4 sm:px-6">
                        <router-link
                          :to="{ name: 'invoiceSearch' }"
                          class="inline-block ml-3 flex-1 flex items-center justify-between"
                        >
                          <p class="text-lg text-blue-700">Verify</p>
                          <p
                            class="text-lg md:ml-6 whitespace-nowrap font-medium text-blue-700 hover:text-blue-600"
                          >
                            <span aria-hidden="true">&rarr;</span>
                          </p>
                        </router-link>
                      </div>
                    </div>
                  </div>
                  <div class="md:col-span-6">
                    <div class="bg-white overflow-hidden shadow rounded-lg">
                      <div class="px-5 md:px-12 py-5 md:py-12">
                        <p class="mt-2 text-3xl font-bold text-gray-900">
                          TIN Verification
                        </p>
                        <p class="mt-4 text-lg text-gray-500">
                          Confirm the validity a Taxpayer
                          <br class="hidden md:block" />
                          Identification Number
                        </p>
                      </div>
                      <div class="bg-gray-50 px-4 py-4 sm:px-6">
                        <router-link
                          :to="{ name: 'tinSearch' }"
                          class="inline-block ml-3 flex-1 flex items-center justify-between"
                        >
                          <p class="text-lg text-blue-700">Verify</p>
                          <p
                            class="text-lg md:ml-6 whitespace-nowrap font-medium text-blue-700 hover:text-blue-600"
                          >
                            <span aria-hidden="true">&rarr;</span>
                          </p>
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="md:grid md:grid-cols-12 md:gap-12 space-y-5 md:space-y-0 mt-5"
                >
                  <div class="md:col-span-6">
                    <div class="bg-white overflow-hidden shadow rounded-lg">
                      <div class="px-5 md:px-12 py-5 md:py-12">
                        <p class="mt-2 text-3xl font-bold text-gray-900">
                          TCC Verification
                        </p>
                        <p class="mt-4 text-lg text-gray-500">
                          Verify Tax Clearance Certificate
                        </p>
                      </div>

                      <div class="bg-gray-50 px-4 py-4 sm:px-6">
                        <router-link
                          :to="{ name: 'verify_tcc' }"
                          class="inline-block ml-3 flex-1 flex items-center justify-between"
                        >
                          <p class="text-lg text-blue-700">Verify</p>
                          <p
                            class="text-lg md:ml-6 whitespace-nowrap font-medium text-blue-700 hover:text-blue-600"
                          >
                            <span aria-hidden="true">&rarr;</span>
                          </p>
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </main>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import gql from "graphql-tag";

export default {
  data() {
    return {
      fixed: false,
      tin_btn: true,
      show_alert: true,
      //   newsUpdates: [],
      loginRegLink: [],
      info: {},
      data: {},
      token: "",
      validate: "",
      invoice_validate: "",
      api_url: process.env.VUE_APP_STRAPI_API_URL,
      routeParam: this.$route.params.id,
    };
  },

  components: {
    // Header,
  },
  apollo: {
    loginRegLink: gql`
      query loginRegLink {
        loginRegLink {
          login
          register
        }
      }
    `,
  },
  mounted() {
    this.axios
      .post(" https://etax.nra.gov.ss/api/oauth/generate/token", {
        key: "BjeayvmuEtaFQICT",
        secret: "8nNKPCKWvE1wgfZxqCEo7acVPkV22AWp",
      })
      .then((response) => {
        this.token = response.token;
      })

      .catch((err) => {
        console.log(err);
      });

    window.scrollTo(0, 0);
  },
  methods: {
    search() {
      const api = this.axios.create({
        headers: {
          Authorization: "Bearer " + this.token,
        },
      });

      api
        .get(
          `https://etax.nra.gov.ss/api/tin/search?query[tin]=${this.info.tin}`
        )
        .then((response) => {
          if (response.data) {
            this.show_alert = true;
            this.validate = response.data.status;
          }
        })
        .catch((err) => console.log(err));
    },
    validate_invoice() {
      const api = this.axios.create({
        headers: {
          Authorization: "Bearer " + this.token,
        },
      });

      api
        .get(
          `https://etax.nra.gov.ss/api/b2b/invoices/get/${this.data.invoice}`
        )
        .then((response) => {
          if (response.data) {
            // this.invoice_validate = response.data.status;
            this.show_alert = true;
            console.log("==> " + this.data.invoice);
          }
        })
        .catch((err) => console.log(err));
    },
  },
};
</script>
