<template>
<div>
    <!-- Main NAV -->

    <scroll-fixed-header :fixed.sync="fixed" :threshold="163">
        <nav class="hidden sm:block">
            <div class="max-w-7xl mx-auto bg-gray-50">
                <div class="relative">
                    <div class="flex border divide-x divide-gray-200">
                        <div style="padding: 7px" v-if="fixed == true" class="flex items-center justify-center">
                            <img class="w-auto h-9 mr-2" src="../assets/img/SSRA.png" alt="South Sudan Revenue Authority" />
                            <h1 class="uppercase font-bold text-3xl">NRA</h1>
                        </div>

                        <router-link :to="{ name: 'index' }" class="px-8 text-sm text-gray-500 outline-none focus:outline-none py-4 inline-flex items-center uppercase">
                            <span>Home</span>
                        </router-link>

                        <router-link :to="{
                  name: 'indIntro',
                  params: { id: '1', slug: 'individual-taxpayer' },
                }" class="px-8 text-sm text-gray-500 outline-none focus:outline-none py-4 inline-flex items-center uppercase">
                            <span>Individual</span>
                        </router-link>

                        <router-link :to="{
                  name: 'bsnIntro',
                  params: { id: '1', slug: 'what-is-considered-a-business' },
                }" class="px-8 text-sm text-gray-500 outline-none focus:outline-none py-4 inline-flex items-center uppercase">
                            <span>Business</span>
                        </router-link>

                        <router-link :to="{
                  name: 'invIntro',
                  params: { id: '1', slug: 'investment-in-south-sudan' },
                }" class="px-8 text-sm text-gray-500 outline-none focus:outline-none py-4 inline-flex items-center uppercase">
                            <span>Investors</span>
                        </router-link>

                        <router-link :to="{
                  name: 'agentsIntro',
                  params: { id: '1', slug: 'agents' },
                }" class="px-8 text-sm text-gray-500 outline-none focus:outline-none py-4 inline-flex items-center uppercase">
                            <span>Agents</span>
                        </router-link>

                        <div style="padding: 12px" v-if="fixed == true" class="hidden sm:flex items-center justify-end sm:flex-1 lg:w-0">
                            <div class="sm:flex items-center justify-end space-x-4 sm:flex-1 lg:w-0">
                                <a v-bind:href="loginRegLink.login" target="_blank" class="btn btn-blue">
                                    LOG IN
                                </a>
                                <a v-bind:href="loginRegLink.register" target="_blank" class="btn btn-orange">
                                    <svg class="h-5 w-5 text-gray-900 mr-2 -ml-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 11V7a4 4 0 118 0m-4 8v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2z" />
                                    </svg>
                                    <span>REGISTER</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    </scroll-fixed-header>

    <!-- End Head -->

    <nav class="hidden sm:flex bg-white border-b border-gray-200" aria-label="Breadcrumb">
        <ol class="max-w-screen-xl w-full mx-auto p-4 flex space-x-4 sm:px-6 lg:px-8 uppercase border-l border-r">
            <li class="flex">
                <div class="flex items-center">
                    <router-link :to="{ name: 'index' }" class="ml-4 text-xs text-gray-500 hover:text-gray-700">Home</router-link>
                </div>
            </li>
            <li class="flex">
                <div class="flex items-center">
                    <svg class="flex-shrink-0 h-5 w-5 text-gray-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                    </svg>
                    <router-link :to="{ name: '' }" class="ml-4 text-xs text-gray-500 hover:text-gray-700">Legal</router-link>
                </div>
            </li>
            <li class="flex">
                <div class="flex items-center">
                    <svg class="flex-shrink-0 h-5 w-5 text-gray-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                    </svg>
                    <router-link :to="{ name: '' }" class="ml-4 text-xs text-gray-500 hover:text-gray-700">Data and Privacy</router-link>
                </div>
            </li>
        </ol>
    </nav>
    <main class="relative">
        <div class="relative vh-70 bg-gray-900 max-w-7xl mx-auto">
            <!-- <img class="absolute inset-0 w-full h-full object-cover z-0" src="../assets/img/hero-investors.jpg" alt="NRA" /> -->
            <img v-if="headerImage.legal" class="absolute inset-0 w-full h-full object-cover z-0" :src="api_url + headerImage.legal.url" alt="NRA" />
            <div class="relative bg-gradient-to-r from-black">
                <div class="px-8 py-12 md:pt-16 md:pb-28 md:px-24">
                    <p class="mt-3 uppercase text-xs sm:text-base text-nra-yellow-500 sm:mt-5">
                        LEGAL
                    </p>
                    <h1 class="text-2xl sm:text-xl ont-extrabold text-white sm:mt-1 lg:mt-1 lg:text-4xl">
                        <span class="sm:block">Data and Privacy</span>
                    </h1>
                </div>
            </div>
        </div>

        <div class="md:max-w-4xl mx-auto">
            <div class="my-10 px-8 md:px-0">
                <div class="w-full">
                    <h2 class="font-bold text-2xl mb-10">{{ privacy.head1 }}</h2>
                    <p class="mb-8">
                        {{ privacy.par1 }}
                    </p>
                    <p class="mb-8">
                        {{ privacy.par2 }}
                    </p>
                    <p class="mb-8">
                        {{ privacy.par3 }}
                    </p>
                    <h3 class="font-bold text-xl mb-10">{{ privacy.head2 }}</h3>
                    <p class="mb-8">
                        {{ privacy.par4 }}
                    </p>
                    <ul v-if="privacy.list" class="list-disc ml-10 mb-10">
                        <li v-if="privacy.list.item1">
                            {{ privacy.list.item1 }}
                        </li>
                        <li v-if="privacy.list.item2">
                            {{ privacy.list.item2 }}
                        </li>
                        <li v-if="privacy.list.item3">
                            {{ privacy.list.item3 }}
                        </li>
                        <li v-if="privacy.list.item4">
                            {{ privacy.list.item4 }}
                        </li>
                        <li v-if="privacy.list.item5">
                            {{ privacy.list.item5 }}
                        </li>
                    </ul>
                    <p class="mb-8">
                        {{ privacy.par5 }}
                    </p>
                    <p class="mb-8">
                        {{ privacy.par6 }}
                    </p>
                    <p class="mb-8">
                        {{ privacy.par7 }}
                    </p>
                    <h3 class="font-bold text-xl mb-10">
                        {{ privacy.head3 }}
                    </h3>
                    <p class="mb-8">
                        {{ privacy.par8 }}
                    </p>
                    <h3 class="font-bold text-xl mb-10">
                        {{ privacy.head4 }}
                    </h3>
                    <p class="mb-8">
                        {{ privacy.par9 }}
                    </p>
                    <ul v-if="privacy.list1" class="list-disc ml-10 mb-10">
                        <li v-if="privacy.list1.item1">
                            {{ privacy.list1.item1 }}
                        </li>
                        <li v-if="privacy.list1.item2">
                            {{ privacy.list1.item2 }}
                        </li>
                        <li v-if="privacy.list1.item3">
                            {{ privacy.list1.item3 }}
                        </li>
                        <li v-if="privacy.list1.item4">
                            {{ privacy.list1.item4 }}
                        </li>
                        <li v-if="privacy.list1.item5">
                            {{ privacy.list1.item5 }}
                        </li>
                    </ul>
                    <p class="mb-8">
                        {{ privacy.par10 }}
                    </p>
                    <p class="mb-8">
                        {{ privacy.par11 }}
                    </p>

                    <h3 class="font-bold text-xl mb-10">
                        {{ privacy.head5 }}
                    </h3>
                    <p class="mb-8">
                        {{ privacy.par12 }}
                    </p>
                    <p class="mb-8">
                        {{ privacy.par13 }}
                    </p>
                    <p class="mb-8">
                        {{ privacy.par14 }}
                    </p>
                    <h3 class="font-bold text-xl mb-10">
                        {{ privacy.head6 }}
                    </h3>
                    <p class="mb-8">
                        {{ privacy.par15 }}
                    </p>
                    <ul v-if="privacy.list2" class="list-disc ml-10 mb-10">
                        <li v-if="privacy.list2.item1">
                            {{ privacy.list2.item1 }}
                        </li>
                        <li v-if="privacy.list2.item2">
                            {{ privacy.list2.item2 }}
                        </li>
                        <li v-if="privacy.list2.item3">
                            {{ privacy.list2.item3 }}
                        </li>
                        <li v-if="privacy.list2.item4">
                            {{ privacy.list2.item4 }}
                        </li>
                        <li v-if="privacy.list2.item5">
                            {{ privacy.list2.item5 }}
                        </li>
                    </ul>
                    <p class="mb-8">
                        {{ privacy.par16 }}
                    </p>
                    <p class="mb-8">
                        {{ privacy.par17 }}
                    </p>
                    <p class="mb-8">
                        {{ privacy.par18 }}
                    </p>
                    <h3 class="font-bold text-xl mb-10">{{ privacy.head7 }}</h3>
                    <p class="mb-8">
                        {{ privacy.par19 }}
                    </p>
                    <p class="mb-8">{{ privacy.par20 }}</p>
                    <ul v-if="privacy.list3" class="list-disc ml-10 mb-10">
                        <li v-if="privacy.list3.item1">
                            {{ privacy.list3.item1 }}
                        </li>
                        <li v-if="privacy.list3.item2">
                            {{ privacy.list3.item2 }}
                        </li>
                        <li v-if="privacy.list3.item3">
                            {{ privacy.list3.item3 }}
                        </li>
                        <li v-if="privacy.list3.item4">
                            {{ privacy.list3.item4 }}
                        </li>
                        <li v-if="privacy.list3.item5">
                            {{ privacy.list3.item5 }}
                        </li>
                        <!-- <span class="font-bold">personal NRA account.</span> -->
                    </ul>
                    <p class="mb-8">
                        {{ privacy.par21 }}
                    </p>
                    <h3 class="font-bold text-xl mb-10">{{ privacy.head8 }}</h3>
                    <p class="mb-8">
                        {{ privacy.par22 }}
                    </p>
                    <p class="mb-8">
                        {{ privacy.par23 }}
                    </p>
                    <p class="mb-8">
                        {{ privacy.par24 }}
                    </p>
                    <p class="mb-8">
                        {{ privacy.par25 }}
                    </p>
                </div>
            </div>
        </div>
    </main>
</div>
</template>

<script>
import gql from "graphql-tag";

export default {
    data() {
        return {
            fixed: false,
            privacy: [],
            loginRegLink: [],
            headerImage: [],
            api_url: process.env.VUE_APP_STRAPI_API_URL,
            routeParam: this.$route.params.id,
        };
    },
    components: {
        // Header,
    },
    mounted() {
        window.scrollTo(0, 0);
    },
    apollo: {
        privacy: gql `
      query {
        privacy {
          head1
          par1
          par2
          par3
          head2
          par4
          list {
            item1
            item2
            item3
            item4
            item5
          }
          par5
          par6
          par7
          head3
          par8
          head4
          par9
          list1 {
            item1
            item2
            item3
            item4
            item5
          }
          par10
          par11
          head5
          par12
          par13
          par14
          head6
          par15
          list2 {
            item1
            item2
            item3
            item4
            item5
          }
          par16
          par17
          par18
          head7
          par19
          par20
          list3 {
            item1
            item2
            item3
            item4
            item5
          }
          par21
          head8
          par22
          par23
          par24
          par25
        }
      }
    `,
        loginRegLink: gql `
      query loginRegLink {
        loginRegLink {
          login
          register
        }
      }
    `,
        headerImage: gql `
      query headerImage {
        headerImage {
          legal {
            url
          }
        }
      }
    `,
    },
};
</script>

<style></style>
