var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('scroll-fixed-header',{attrs:{"fixed":_vm.fixed,"threshold":163},on:{"update:fixed":function($event){_vm.fixed=$event}}},[_c('nav',{staticClass:"hidden sm:block inset-x-0 top-0 z-10",attrs:{"id":"dynamic"}},[_c('div',{staticClass:"max-w-7xl mx-auto bg-gray-50"},[_c('div',{staticClass:"relative"},[_c('div',{staticClass:"flex border divide-x divide-gray-200"},[(_vm.fixed == true)?_c('div',{staticClass:"flex items-center justify-center",staticStyle:{"padding":"7px"}},[_c('img',{staticClass:"w-auto h-9 mr-2",attrs:{"src":require("../assets/img/SSRA.png"),"alt":"South Sudan Revenue Authority"}}),_c('h1',{staticClass:"uppercase font-bold text-3xl"},[_vm._v("NRA")])]):_vm._e(),_c('router-link',{staticClass:"px-8 text-sm text-gray-500 outline-none focus:outline-none py-3 inline-flex items-center uppercase",class:['index'].includes(_vm.$route.name) ? 'py-3' : ' py-4',attrs:{"to":{name: 'index'}}},[_c('span',{class:['index'].includes(_vm.$route.name) ? 'bg-gray-900 text-gray-100 px-2 py-1 rounded' : ''},[_vm._v("Home")])]),_c('router-link',{staticClass:"px-8 text-sm text-gray-500 outline-none focus:outline-none py-3 inline-flex items-center uppercase",class:['indIntro', 'tinRegistration', 'tax-for-ind', 'indFaqs'].includes(_vm.$route.name) ? 'py-3' : ' py-4',attrs:{"to":{
                  name: 'indIntro',
                  params: { id: '1', slug: 'individual-taxpayer' },
                }}},[_c('span',{class:['indIntro', 'tinRegistration', 'tax-for-ind', 'indFaqs'].includes(_vm.$route.name) ? 'bg-gray-900 text-gray-100 px-2 py-1 rounded' : ''},[_vm._v("Individual")])]),_c('router-link',{staticClass:"px-8 text-sm text-gray-500 outline-none focus:outline-none py-3 inline-flex items-center uppercase",class:['bsnIntro', 'bsnTinReg', 'taxApp', 'penalties', 'deductions', 'taxComp', 'bsnFaqs'].includes(_vm.$route.name) ? 'py-3' : ' py-4',attrs:{"to":{
                  name: 'bsnIntro',
                  params: { id: '1', slug: 'what-is-considered-a-business' },
                }}},[_c('span',{class:['bsnIntro', 'bsnTinReg', 'taxApp', 'penalties', 'deductions', 'taxComp', 'bsnFaqs'].includes(_vm.$route.name) ? 'bg-gray-900 text-gray-100 px-2 py-1 rounded' : ''},[_vm._v("Business")])]),_c('router-link',{staticClass:"px-8 text-sm text-gray-500 outline-none focus:outline-none py-3 inline-flex items-center uppercase",class:['invIntro'].includes(_vm.$route.name) ? 'py-3' : ' py-4',attrs:{"to":{
                  name: 'invIntro',
                  params: { id: '1', slug: 'investment-in-south-sudan' },
                }}},[_c('span',{class:['invIntro'].includes(_vm.$route.name) ? 'bg-gray-900 text-gray-100 px-2 py-1 rounded' : ''},[_vm._v("Investors")])]),_c('router-link',{staticClass:"px-8 text-sm text-gray-500 outline-none focus:outline-none py-3 inline-flex items-center uppercase",class:['agentsIntro'].includes(_vm.$route.name) ? 'py-3' : ' py-4',attrs:{"to":{
                  name: 'agentsIntro',
                  params: { id: '1', slug: 'agents' },
                }}},[_c('span',{class:['agentsIntro'].includes(_vm.$route.name) ? 'bg-gray-900 text-gray-100 px-2 py-1 rounded' : ''},[_vm._v("Agents")])]),(_vm.fixed == true)?_c('div',{staticClass:"hidden sm:flex items-center justify-end sm:flex-1 lg:w-0",staticStyle:{"padding":"12px"}},[(_vm.loginRegLink)?_c('div',{staticClass:"sm:flex items-center justify-end space-x-4 sm:flex-1 lg:w-0"},[_c('a',{staticClass:"btn btn-blue",attrs:{"href":_vm.loginRegLink.login,"target":"_blank"}},[_vm._v(" LOG IN ")]),_c('a',{staticClass:"btn btn-orange",attrs:{"href":_vm.loginRegLink.register,"target":"_blank"}},[_c('svg',{staticClass:"h-5 w-5 text-gray-900 mr-2 -ml-3",attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","stroke":"currentColor"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M8 11V7a4 4 0 118 0m-4 8v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2z"}})]),_c('span',[_vm._v("REGISTER")])])]):_vm._e()]):_vm._e()],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }