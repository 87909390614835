var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-6 py-9 bg-gray-200 border border-gray-300 md:hidden sm:block"},[_c('div',{staticClass:"dropdown inline-block w-full relative"},[_c('button',{staticClass:"bg-white w-full text-gray-700 font-semibold py-2 px-4 pr-2 rounded inline-flex items-between justify-between focus:outline-none"},[_c('span',{staticClass:"mr-1"},[_vm._v("Select Topic")]),_c('svg',{staticClass:"fill-current h-5 w-5 relative top-1",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 20 20"}},[_c('path',{attrs:{"d":"M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"}})])]),_c('ul',{staticClass:"dropdown-content bg-white border border-gray-300 rounded-md dropdown-menu absolute hidden text-gray-700 mt-1 pt-1 z-10 w-full"},[_vm._m(0),_c('div',_vm._l((_vm.individualIntroductions),function(indIntro){return _c('li',{key:indIntro.id,attrs:{"id":"active"}},[_c('router-link',{key:indIntro.id,staticClass:"ml-4 py-2 px-4 block whitespace-no-wrap",attrs:{"to":{
              name: 'indIntro',
              params: { id: indIntro.id, slug: indIntro.slug },
            }}},[_vm._v(_vm._s(indIntro.heading))])],1)}),0),_vm._m(1),_c('div',_vm._l((_vm.tinRegistrations),function(tin){return _c('li',{key:tin.id},[_c('router-link',{key:tin.id,staticClass:"ml-4 py-2 px-4 block whitespace-no-wrap",attrs:{"to":{
              name: 'tinRegistration',
              params: { id: tin.id, slug: tin.slug },
            }}},[_vm._v(_vm._s(tin.heading))])],1)}),0),_vm._m(2),_c('div',_vm._l((_vm.taxesForIndividuals),function(tax){return _c('li',{key:tax.id},[_c('router-link',{key:tax.id,staticClass:"ml-4 py-2 px-4 block whitespace-no-wrap",attrs:{"to":{
              name: 'tax-for-ind',
              params: { id: tax.id, slug: tax.slug },
            }}},[_vm._v(_vm._s(tax.heading))])],1)}),0),_vm._m(3),_c('li',[_c('router-link',{staticClass:"ml-4 py-3 px-4 block whitespace-no-wrap",attrs:{"to":{ name: 'indFaqs' }}},[_vm._v("Frequently Asked Questions")])],1)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"font-bold"},[_c('p',{staticClass:"text-gray-400 text-sm py-2 px-4 block whitespace-no-wrap"},[_vm._v(" Section 1/4: Introduction ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"font-bold"},[_c('p',{staticClass:"text-gray-400 text-sm py-2 px-4 block whitespace-no-wrap"},[_vm._v(" Section 2/4: TIN Registration ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"font-bold"},[_c('p',{staticClass:"text-gray-400 text-sm py-2 px-4 block whitespace-no-wrap"},[_vm._v(" Section 3/4: Taxes for individuals ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"font-bold"},[_c('p',{staticClass:"text-gray-400 text-sm py-2 px-4 block whitespace-no-wrap"},[_vm._v(" Section 4/4: Frequently Asked Questions ")])])}]

export { render, staticRenderFns }