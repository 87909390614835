<template>
  <div>
    <!-- Main NAV -->
   <MainNav />
    <!-- End Head -->

    <nav
      class="hidden sm:flex bg-white border-b border-gray-200"
      aria-label="Breadcrumb"
    >
      <ol
        class="max-w-screen-xl w-full mx-auto p-4 flex space-x-4 sm:px-6 lg:px-8 uppercase border-l border-r"
      >
        <li class="flex">
          <div class="flex items-center">
            <router-link
              :to="{ name: 'index' }"
              class="ml-4 text-xs text-gray-500 hover:text-gray-700"
              >Home</router-link
            >
          </div>
        </li>
        <li class="flex">
          <div class="flex items-center">
            <svg
              class="flex-shrink-0 h-5 w-5 text-gray-300"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clip-rule="evenodd"
              />
            </svg>
            <router-link
              :to="{ name: '' }"
              class="ml-4 text-xs text-gray-500 hover:text-gray-700"
              >Individual</router-link
            >
          </div>
        </li>
        <li class="flex">
          <div class="flex items-center">
            <svg
              class="flex-shrink-0 h-5 w-5 text-gray-300"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clip-rule="evenodd"
              />
            </svg>
            <router-link
              :to="{ name: '' }"
              class="ml-4 text-xs text-gray-500 hover:text-gray-700"
              >Taxes for individuals</router-link
            >
          </div>
        </li>
      </ol>
    </nav>
    <main class="relative">
      <div class="relative vh-70 bg-gray-900 max-w-7xl mx-auto">
        <!-- <img
          class="absolute inset-0 w-full h-full object-cover z-0"
          src="../assets/img/hero-individual.jpg"
          alt="NRA"
        /> -->
        <img
          v-if="headerImage.individual"
          class="absolute inset-0 w-full h-full object-cover z-0"
          :src="api_url + headerImage.individual.url"
          alt="NRA"
        />
        <div class="relative">
          <div class="px-8 py-12 md:pt-16 md:pb-28 md:px-24">
            <p
              class="mt-3 uppercase text-xs sm:text-base text-nra-yellow-500 sm:mt-5"
            >
              TAX INFORMATION
            </p>
            <h1
              class="text-2xl sm:text-xl ont-extrabold text-white sm:mt-1 lg:mt-1 lg:text-4xl"
            >
              <span class="sm:block">{{ taxesForIndividual.heading }}</span>
            </h1>
          </div>
        </div>
      </div>
      <div class="bg-white">
        <div class="pb-16 sm:pb-20">
          <mobile-drop />
          <div
            class="max-w-7xl mx-auto p-8 md:p-24 sm:p-24 lg:px-24 bg-white border-t-8 border-nra-blue-500 -mt-2 relative"
          >
            <div class="grid grid-cols-3 gap-12">
              <!-- sidenav -->
              <!-- <sideNav /> -->

              <div
                class="w-auto col-span-3 md:col-span-1 hidden md:block"
                x-data="{selected:2}"
              >
                <div
                  class="flex flex-col overflow-hidden border-b border-gray-300"
                >
                  <h3
                    id="0"
                    class="block text-xl font-extrabold text-gray-900 py-3 cursor-pointer"
                    x-on:click="selected !== 0 ? selected = 0 : selected = null"
                  >
                    Introduction
                    <div class="relative float-right">
                      <svg
                        class="h-5 w-5 absolute top-1 right-0 transition-all duration-300 text-gray-300"
                        x-bind:style="selected == 0 ? 'transform: rotate(90deg); color: #000000;' : ''"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M9 5l7 7-7 7"
                        />
                      </svg>
                    </div>
                  </h3>
                  <div
                    class="relative overflow-hidden transition-all max-h-0 duration-300"
                    x-ref="section1"
                    x-bind:style="selected == 0 ? 'max-height: ' + $refs.section1.scrollHeight + 'px' : ''"
                  >
                    <div class="py-6">
                      <ul class="space-y-4">
                        <li
                          class="text-base truncate pb-3"
                          v-for="indIntro in individualIntroductions"
                          v-bind:key="indIntro.id"
                        >
                          <router-link
                            :to="{
                              name: 'indIntro',
                              params: { id: indIntro.id, slug: indIntro.slug },
                            }"
                            :key="indIntro.id"
                            class="hover:text-gray-700"
                          >
                            {{ indIntro.heading }}
                          </router-link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div
                  class="flex flex-col overflow-hidden border-b border-gray-300"
                >
                  <h3
                    class="block text-xl font-extrabold text-gray-900 py-3 cursor-pointer"
                    x-on:click="selected !== 1 ? selected = 1 : selected = null"
                  >
                    TIN Registration
                    <div class="relative float-right">
                      <svg
                        class="h-5 w-5 absolute top-1 right-0 transition-all duration-300 text-gray-300"
                        x-bind:style="selected == 1 ? 'transform: rotate(90deg); color: #000000;' : ''"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M9 5l7 7-7 7"
                        />
                      </svg>
                    </div>
                  </h3>
                  <div
                    class="relative overflow-hidden transition-all max-h-0 duration-300"
                    x-ref="section2"
                    x-bind:style="selected == 1 ? 'max-height: ' + $refs.section2.scrollHeight + 'px' : ''"
                  >
                    <div class="py-6">
                      <ul class="space-y-4">
                        <li
                          class="text-base truncate pb-3"
                          v-for="tin in tinRegistrations"
                          v-bind:key="tin.id"
                        >
                          <router-link
                            :to="{
                              name: 'tinRegistration',
                              params: { id: tin.id, slug: tin.slug },
                            }"
                            :key="tin.id"
                            class="hover:text-gray-700"
                          >
                            {{ tin.heading }}
                          </router-link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div
                  class="flex flex-col overflow-hidden border-b border-gray-300"
                >
                  <h3
                    class="block text-xl font-extrabold text-gray-900 py-3 cursor-pointer"
                    x-on:click="selected !== 2 ? selected = 2 : selected = null"
                  >
                    Taxes for individuals
                    <div class="relative float-right">
                      <svg
                        class="h-5 w-5 absolute top-1 right-0 transition-all duration-300 text-gray-300"
                        x-bind:style="selected == 2 ? 'transform: rotate(90deg); color: #000000;' : ''"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M9 5l7 7-7 7"
                        />
                      </svg>
                    </div>
                  </h3>
                  <div
                    class="relative overflow-hidden transition-all max-h-0 duration-300"
                    x-ref="section3"
                    x-bind:style="selected == 2 ? 'max-height: ' + $refs.section3.scrollHeight + '200px' : ''"
                  >
                    <div class="py-6">
                      <ul class="space-y-4">
                        <li
                          class="text-base truncate pb-3"
                          v-for="tax in taxesForIndividuals"
                          v-bind:key="tax.id"
                        >
                          <router-link
                            :to="{
                              name: 'tax-for-ind',
                              params: { id: tax.id, slug: tax.slug },
                            }"
                            :key="tax.id"
                            class="hover:text-gray-700"
                          >
                            {{ tax.heading }}
                          </router-link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div
                  class="flex flex-col overflow-hidden border-b border-gray-300"
                >
                  <router-link
                    :to="{ name: 'indFaqs' }"
                    class="block text-xl font-extrabold text-gray-900 py-3 cursor-pointer"
                  >
                    Frequently Asked Questions
                    <div class="relative float-right">
                      <svg
                        class="h-5 w-5 absolute top-1 right-0 text-gray-300"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M9 5l7 7-7 7"
                        />
                      </svg>
                    </div>
                  </router-link>
                </div>
              </div>

              <!-- ... -->
              <div
                v-if="taxesForIndividual.body"
                class="w-auto col-span-3 md:col-span-2"
              >
                <div class="border-b border-gray-300 pb-6 mb-6">
                  <p
                    id="0"
                    class="border-l-4 border-nra-cyan-500 pl-1 font-bold text-xs"
                  >
                    SECTION 3/4
                  </p>
                  <h2
                    class="text-3xl font-extrabold text-gray-900 sm:text-4xl mt-2"
                  >
                    {{ taxesForIndividual.title }}
                  </h2>
                </div>

                <h2 class="text-xl font-extrabold text-gray-900 mt-8">
                  {{ taxesForIndividual.heading }}
                </h2>
                <p class="mt-4 text-lg text-gray-800">
                  {{ taxesForIndividual.intro }}
                </p>

                <div
                  class="mt-4 overflow-hidden border-b border-gray-200"
                  v-if="taxesForIndividual.slug == 'advance-tax'"
                >
                  <table class="min-w-full divide-y divide-gray-200">
                    <thead class="bg-nra-yellow-50 border-t-2 border-black">
                      <tr>
                        <th
                          scope="col"
                          class="px-6 py-3 text-left font-bold tracking-wider"
                        >
                          Filing date
                        </th>
                        <th
                          scope="col"
                          class="px-6 py-3 text-left font-bold tracking-wider"
                        >
                          Tax Period
                        </th>
                      </tr>
                    </thead>
                    <tbody class="bg-white divide-y divide-gray-200">
                      <tr v-for="ft in ftTables" v-bind:key="ft.id">
                        <td class="px-6 py-4 whitespace-nowrap font-medium">
                          {{ ft.date }}
                        </td>
                        <td class="px-6 py-4 whitespace-nowrap">
                          {{ ft.period }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div
                  class="mt-4 overflow-hidden border-b border-gray-200"
                  v-if="taxesForIndividual.slug == 'personal-income-tax-pit'"
                >
                  <table class="min-w-full divide-y divide-gray-200">
                    <thead class="bg-nra-yellow-50 border-t-2 border-black">
                      <tr>
                        <th
                          scope="col"
                          class="px-6 py-3 text-left font-bold tracking-wider"
                        >
                          Personal Income Tax
                        </th>
                        <th
                          scope="col"
                          class="px-6 py-3 text-left font-bold tracking-wider"
                        >
                          Percentage
                        </th>
                      </tr>
                    </thead>
                    <tbody class="bg-white divide-y divide-gray-200">
                      <tr v-for="pp in ppTables" v-bind:key="pp.id">
                        <td class="px-6 py-4 whitespace-nowrap font-medium">
                          {{ pp.pit }}
                        </td>
                        <td class="px-6 py-4 whitespace-nowrap">
                          {{ pp.perc }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <h2 class="text-xl font-extrabold text-gray-900 mt-8">
                  {{ taxesForIndividual.body.htitle }}
                </h2>
                <p class="mt-4 text-lg text-gray-800">
                  {{ taxesForIndividual.body.defn1 }}
                </p>

                <ol class="list-decimal mt-4 text-lg text-gray-800 pl-6">
                  <li v-if="taxesForIndividual.list.item1">
                    {{ taxesForIndividual.list.item1 }}
                  </li>
                  <li v-if="taxesForIndividual.list.item2">
                    {{ taxesForIndividual.list.item2 }}
                  </li>
                  <li v-if="taxesForIndividual.list.item3">
                    {{ taxesForIndividual.list.item3 }}
                  </li>
                  <li v-if="taxesForIndividual.list.item4">
                    {{ taxesForIndividual.list.item4 }}
                  </li>
                  <li v-if="taxesForIndividual.list.item5">
                    {{ taxesForIndividual.list.item5 }}
                  </li>
                  <li v-if="taxesForIndividual.list.item6">
                    {{ taxesForIndividual.list.item6 }}
                  </li>
                  <li v-if="taxesForIndividual.list.item7">
                    {{ taxesForIndividual.list.item7 }}
                  </li>
                </ol>

                <h2 class="text-xl font-extrabold text-gray-900 mt-8">
                  {{ taxesForIndividual.body.wtitle }}
                </h2>
                <p class="mt-4 text-lg text-gray-800">
                  {{ taxesForIndividual.body.defn2 }}
                </p>
                <p class="mt-4 text-lg text-gray-800">
                  {{ taxesForIndividual.body.defn3 }}
                </p>

                <ol class="list-decimal mt-4 text-lg text-gray-800 pl-6">
                  <li v-if="taxesForIndividual.list.item8">
                    {{ taxesForIndividual.list.item8 }}
                  </li>
                  <li v-if="taxesForIndividual.list.item9">
                    {{ taxesForIndividual.list.item9 }}
                  </li>
                </ol>

                <div
                  class="mt-4 overflow-hidden border-b border-gray-200"
                  v-if="taxesForIndividual.slug == 'advance-tax'"
                >
                  <table class="min-w-full divide-y divide-gray-200">
                    <thead class="bg-nra-yellow-50 border-t-2 border-black">
                      <tr>
                        <th
                          scope="col"
                          class="px-6 py-3 text-left font-bold tracking-wider"
                        >
                          Category
                        </th>
                        <th
                          scope="col"
                          class="px-6 py-3 text-left font-bold tracking-wider"
                        >
                          Description
                        </th>
                        <th
                          scope="col"
                          class="px-6 py-3 text-left font-bold tracking-wider"
                        >
                          Rate
                        </th>
                      </tr>
                    </thead>
                    <tbody class="bg-white divide-y divide-gray-200">
                      <tr v-for="cdr in cdrTables" v-bind:key="cdr.id">
                        <td class="px-6 py-4 whitespace-nowrap font-medium">
                          {{ cdr.category }}
                        </td>
                        <td class="px-6 py-4 whitespace-nowrap">
                          {{ cdr.description }}
                        </td>
                        <td class="px-6 py-4 whitespace-nowrap">
                          {{ cdr.rate }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <cta />
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import gql from "graphql-tag";
// import sideNav from './nav/sideNav'
import MainNav from "@/components/MainNav.vue"
import mainNav from "./nav/mainNav";
import cta from "../components/callToAction";
import mobileDrop from "./nav/mobileDrop";

export default {
  data() {
    return {
      taxesForIndividual: [],
      ppTables: [],
      ftTables: [],
      cdrTables: [],
      headerImage: [],

      individualIntroductions: [],
      tinRegistrations: [],
      taxesForIndividuals: [],
      api_url: process.env.VUE_APP_STRAPI_API_URL,
      routeParam: this.$route.params.id,
    };
  },
  components: {
    // sideNav,
    MainNav,
    mainNav,
    cta,
    mobileDrop,
  },
  mounted() {
    window.scrollTo(0, 0);
  },

  apollo: {
    taxesForIndividual: {
      query: gql`
        query taxesForIndividual($id: ID!) {
          taxesForIndividual(id: $id) {
            id
            title
            heading
            intro
            slug
            body {
              htitle
              defn1
              defn2
              defn3
              wtitle
            }
            list {
              item1
              item2
              item3
              item4
              item5
              item6
              item7
              item8
              item9
            }
          }
        }
      `,
      variables() {
        return {
          id: this.routeParam,
        };
      },
    },

    ppTables: gql`
      query ppTables {
        ppTables {
          id
          pit
          perc
        }
      }
    `,
    ftTables: gql`
      query pptables {
        ftTables {
          id
          date
          period
        }
      }
    `,
    cdrTables: gql`
      query cdrTables {
        cdrTables {
          id
          category
          description
          rate
        }
      }
    `,
    individualIntroductions: gql`
      query individualIntroduction {
        individualIntroductions {
          id
          heading
          slug
        }
      }
    `,
    tinRegistrations: gql`
      query tinRegistration {
        tinRegistrations {
          id
          heading
          intro
          slug
        }
      }
    `,
    taxesForIndividuals: gql`
      query taxesForIndividuals {
        taxesForIndividuals {
          id
          heading
          intro
          slug
        }
      }
    `,
    headerImage: gql`
      query headerImage {
        headerImage {
          individual {
            url
          }
        }
      }
    `,
  },
};
</script>
