<template>
  <div
    class="w-auto col-span-3 md:col-span-1 hidden md:block"
    x-data="{selected:0}"
  >
    <div class="flex flex-col overflow-hidden border-b border-gray-300">
      <h3
        id="0"
        class="block text-xl font-extrabold text-gray-900 py-3 cursor-pointer"
        x-on:click="selected !== 0 ? selected = 0 : selected = null"
      >
        Introduction
        <div class="relative float-right">
          <svg
            class="h-5 w-5 absolute top-1 right-0 transition-all duration-300 text-gray-300"
            x-bind:style="selected == 0 ? 'transform: rotate(90deg); color: #000000;' : ''"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M9 5l7 7-7 7"
            />
          </svg>
        </div>
      </h3>
      <div
        class="relative overflow-hidden transition-all max-h-0 duration-300"
        x-ref="section1"
        x-bind:style="selected == 0 ? 'max-height: ' + $refs.section1.scrollHeight + '200px' : ''"
      >
        <div class="py-6">
          <ul class="space-y-4">
            <li
              class="text-base truncate pb-3"
              v-for="indIntro in individualIntroductions"
              v-bind:key="indIntro.id"
            >
              <router-link
                :to="{
                  name: 'indIntro',
                  params: { id: indIntro.id, slug: indIntro.slug },
                }"
                :key="indIntro.id"
                class="hover:text-gray-700"
              >
                <a>{{ indIntro.heading }}</a>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="flex flex-col overflow-hidden border-b border-gray-300">
      <h3
        class="block text-xl font-extrabold text-gray-900 py-3 cursor-pointer"
        x-on:click="selected !== 1 ? selected = 1 : selected = null"
      >
        TIN Registration
        <div class="relative float-right">
          <svg
            class="h-5 w-5 absolute top-1 right-0 transition-all duration-300 text-gray-300"
            x-bind:style="selected == 1 ? 'transform: rotate(90deg); color: #000000;' : ''"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M9 5l7 7-7 7"
            />
          </svg>
        </div>
      </h3>
      <div
        class="relative overflow-hidden transition-all max-h-0 duration-300"
        x-ref="section2"
        x-bind:style="selected == 1 ? 'max-height: ' + $refs.section2.scrollHeight + 'px' : ''"
      >
        <div class="py-6">
          <ul class="space-y-4">
            <li
              class="text-base truncate pb-3"
              v-for="tin in tinRegistrations"
              v-bind:key="tin.id"
            >
              <router-link
                :to="{
                  name: 'tinRegistration',
                  params: { id: tin.id, slug: tin.slug },
                }"
                :key="tin.id"
                class="hover:text-gray-700"
              >
                {{ tin.heading }}
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="flex flex-col overflow-hidden border-b border-gray-300">
      <h3
        class="block text-xl font-extrabold text-gray-900 py-3 cursor-pointer"
        x-on:click="selected !== 2 ? selected = 2 : selected = null"
      >
        Taxes for individuals
        <div class="relative float-right">
          <svg
            class="h-5 w-5 absolute top-1 right-0 transition-all duration-300 text-gray-300"
            x-bind:style="selected == 2 ? 'transform: rotate(90deg); color: #000000;' : ''"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M9 5l7 7-7 7"
            />
          </svg>
        </div>
      </h3>
      <div
        class="relative overflow-hidden transition-all max-h-0 duration-300"
        x-ref="section3"
        x-bind:style="selected == 2 ? 'max-height: ' + $refs.section3.scrollHeight + 'px' : ''"
      >
        <div class="py-6">
          <ul class="space-y-4">
            <li
              class="text-base truncate pb-3"
              v-for="tax in taxesForIndividuals"
              v-bind:key="tax.id"
            >
              <router-link
                :to="{
                  name: 'tax-for-ind',
                  params: { id: tax.id, slug: tax.slug },
                }"
                :key="tax.id"
                class="hover:text-gray-700"
              >
                {{ tax.heading }}
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="flex flex-col overflow-hidden border-b border-gray-300">
      <router-link
        :to="{ name: 'indFaqs' }"
        class="block text-xl font-extrabold text-gray-900 py-3 cursor-pointer"
      >
        Frequently Asked Questions
        <div class="relative float-right">
          <svg
            class="h-5 w-5 absolute top-1 right-0 text-gray-300"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M9 5l7 7-7 7"
            />
          </svg>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import gql from "graphql-tag";

export default {
  name: "",
  data() {
    return {
      individualIntroductions: [],
      tinRegistrations: [],
      taxesForIndividuals: [],
      api_url: process.env.VUE_APP_STRAPI_API_URL,
      routeParam: this.$route.params.id,
    };
  },

  apollo: {
    individualIntroductions: gql`
      query individualIntroduction {
        individualIntroductions {
          id
          heading
          slug
        }
      }
    `,
    tinRegistrations: gql`
      query tinRegistration {
        tinRegistrations {
          id
          heading
          intro
          slug
        }
      }
    `,
    taxesForIndividuals: gql`
      query taxesForIndividuals {
        taxesForIndividuals {
          id
          heading
          intro
          slug
        }
      }
    `,
    // variables() {
    //   return {
    //     id: this.routeParam,
    //   };
    // },
  },
};
</script>
