var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-6 py-9 bg-gray-200 border border-gray-300 md:hidden sm:block"},[_c('div',{staticClass:"dropdown inline-block w-full relative"},[_c('button',{staticClass:"bg-white w-full text-gray-700 font-semibold py-2 px-4 pr-2 rounded inline-flex items-between justify-between focus:outline-none"},[_c('span',{staticClass:"mr-1"},[_vm._v("Select Topic")]),_c('svg',{staticClass:"fill-current h-5 w-5 relative top-1",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 20 20"}},[_c('path',{attrs:{"d":"M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"}})])]),_c('ul',{staticClass:"dropdown-content bg-white border border-gray-300 rounded-md dropdown-menu absolute hidden text-gray-700 mt-1 pt-1 z-10 w-full"},[_vm._m(0),_c('div',_vm._l((_vm.businessIntroductions),function(bsn){return _c('li',{key:bsn.id},[_c('router-link',{key:bsn.id,staticClass:"ml-4 py-2 px-4 block whitespace-no-wrap",attrs:{"to":{
              name: 'bsnIntro',
              params: { id: bsn.id, slug: bsn.slug },
            }}},[_vm._v(_vm._s(bsn.heading))])],1)}),0),_vm._m(1),_c('div',_vm._l((_vm.tinRegBuses),function(tin){return _c('li',{key:tin.id},[_c('router-link',{key:tin.id,staticClass:"ml-4 py-2 px-4 block whitespace-no-wrap",attrs:{"to":{
              name: 'bsnTinReg',
              params: { id: tin.id, slug: tin.slug },
            }}},[_vm._v(_vm._s(tin.heading))])],1)}),0),_vm._m(2),_c('div',_vm._l((_vm.taxAppBsns),function(tax){return _c('li',{key:tax.id},[_c('router-link',{key:tax.id,staticClass:"ml-4 py-2 px-4 block whitespace-no-wrap",attrs:{"to":{
              name: 'taxApp',
              params: { id: tax.id, slug: tax.slug },
            }}},[_vm._v(_vm._s(tax.heading))])],1)}),0),_vm._m(3),_c('div',_vm._l((_vm.penalties),function(penalty){return _c('li',{key:penalty.id},[_c('router-link',{key:penalty.id,staticClass:"ml-4 py-2 px-4 block whitespace-no-wrap",attrs:{"to":{
              name: 'penalties',
              params: { id: penalty.id, slug: penalty.slug },
            }}},[_vm._v(_vm._s(penalty.heading))])],1)}),0),_vm._m(4),_c('div',_vm._l((_vm.dedAndDeps),function(dnd){return _c('li',{key:dnd.id},[_c('router-link',{key:dnd.id,staticClass:"ml-4 py-2 px-4 block whitespace-no-wrap",attrs:{"to":{
              name: 'deductions',
              params: { id: dnd.id, slug: dnd.slug },
            }}},[_vm._v(_vm._s(dnd.heading))])],1)}),0),_vm._m(5),_c('div',_vm._l((_vm.taxComCerts),function(tcc){return _c('li',{key:tcc.id},[_c('router-link',{key:tcc.id,staticClass:"ml-4 py-2 px-4 block whitespace-no-wrap",attrs:{"to":{
              name: 'taxComp',
              params: { id: tcc.id, slug: tcc.slug },
            }}},[_vm._v(_vm._s(tcc.heading))])],1)}),0),_vm._m(6),_c('li',[_c('router-link',{staticClass:"ml-4 py-3 px-4 block whitespace-no-wrap",attrs:{"to":{ name: 'bsnFaqs' }}},[_vm._v("Frequently Asked Questions")])],1)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"font-bold"},[_c('p',{staticClass:"text-gray-400 text-sm py-2 px-4 block whitespace-no-wrap"},[_vm._v(" Section 1/7: Introduction ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"font-bold"},[_c('p',{staticClass:"text-gray-400 text-sm py-2 px-4 block whitespace-no-wrap"},[_vm._v(" Section 2/7: TIN Registration for business ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"font-bold"},[_c('p',{staticClass:"text-gray-400 text-sm py-2 px-4 block whitespace-no-wrap"},[_vm._v(" Section 3/7: Taxes applied to Businesses ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"font-bold"},[_c('p',{staticClass:"text-gray-400 text-sm py-2 px-4 block whitespace-no-wrap"},[_vm._v(" Section 4/7: Penalties ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"font-bold"},[_c('p',{staticClass:"text-gray-400 text-sm py-2 px-4 block whitespace-no-wrap"},[_vm._v(" Section 5/7: Deduction and depreciation ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"font-bold"},[_c('p',{staticClass:"text-gray-400 text-sm py-2 px-4 block whitespace-no-wrap"},[_vm._v(" Section 6/7: Tax Compliance Certificate ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"font-bold"},[_c('p',{staticClass:"text-gray-400 text-sm py-2 px-4 block whitespace-no-wrap"},[_vm._v(" Section 7/7: Frequently Asked Questions ")])])}]

export { render, staticRenderFns }