<template>
  <div>
    <!-- main nav -->
    <mainNav />

    <!-- breadcumbs -->
    <nav
      class="hidden sm:flex bg-white border-b border-gray-200"
      aria-label="Breadcrumb"
    >
      <ol
        class="max-w-screen-xl w-full mx-auto p-4 flex space-x-4 sm:px-6 lg:px-8 uppercase border-l border-r"
      >
        <li class="flex">
          <div class="flex items-center">
            <router-link
              :to="{ name: 'index' }"
              class="ml-4 text-xs text-gray-500 hover:text-gray-700"
              >Home</router-link
            >
          </div>
        </li>
        <li class="flex">
          <div class="flex items-center">
            <svg
              class="flex-shrink-0 h-5 w-5 text-gray-300"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clip-rule="evenodd"
              />
            </svg>
            <router-link
              :to="{ name: '' }"
              class="ml-4 text-xs text-gray-500 hover:text-gray-700"
              >About</router-link
            >
          </div>
        </li>
        <li class="flex">
          <div class="flex items-center">
            <svg
              class="flex-shrink-0 h-5 w-5 text-gray-300"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clip-rule="evenodd"
              />
            </svg>
            <router-link
              :to="{ name: '' }"
              class="ml-4 text-xs text-gray-500 hover:text-gray-700"
              >About Us</router-link
            >
          </div>
        </li>
      </ol>
    </nav>
    <main class="relative">
      <div class="relative vh-70 bg-gray-900 max-w-7xl mx-auto">
        <!-- <img
          class="absolute inset-0 w-full h-full object-cover z-0"
          src="../assets/img/heroimg.jpg"
          alt="NRA"
        /> -->
        <img
          v-if="headerImage.about"
          class="absolute inset-0 w-full h-full object-cover z-0"
          :src="api_url + headerImage.about.url"
          alt="NRA"
        />
        <div class="relative">
          <div class="px-8 py-12 md:pt-16 md:pb-28 md:px-24">
            <h1
              class="text-2xl sm:text-xl ont-extrabold text-white sm:mt-1 lg:mt-1 lg:text-4xl"
            >
              <span class="sm:block">{{ aboutUs.title }}</span>
            </h1>
          </div>
        </div>
      </div>
      <div class="bg-white">
        <div class="pb-16 sm:pb-20">
          <mobileDrop />
          <div
            class="max-w-7xl mx-auto p-8 md:p-24 sm:p-24 lg:px-24 bg-white border-t-8 border-nra-blue-500 -mt-2 relative"
          >
            <div class="grid grid-cols-3 gap-12">
              <sideNav />

              <div v-if="aboutUs.list2" class="w-auto col-span-3 md:col-span-2">
                <div class="border-b border-gray-300 pb-6 mb-6">
                  <p
                    id="0"
                    class="border-l-4 border-nra-cyan-500 pl-1 font-bold text-xs"
                  >
                    SECTION 1/4
                  </p>
                  <h2
                    class="text-3xl font-extrabold text-gray-900 sm:text-4xl mt-2"
                  >
                    {{ aboutUs.title }}
                  </h2>
                </div>

                <h2 class="text-xl font-extrabold text-gray-900">
                  <!-- {{ about.heading }} -->
                </h2>
                <p class="mt-4 text-lg text-gray-800">{{ aboutUs.par1 }}</p>
               <div v-html="aboutUs.branches"></div>

                <h3 class="text-xl font-bold text-gray-900 mt-8">
                  {{ aboutUs.head1 }}
                </h3>
                <ul class="list-disc mt-4 text-lg text-gray-800 pl-6">
                  <li v-if="aboutUs.list1.item1">{{ aboutUs.list1.item1 }}</li>
                  <li v-if="aboutUs.list1.item2">{{ aboutUs.list1.item2 }}</li>
                  <li v-if="aboutUs.list1.item3">{{ aboutUs.list1.item3 }}</li>
                  <li v-if="aboutUs.list1.item4">{{ aboutUs.list1.item4 }}</li>
                </ul>

                <h3 class="text-xl font-bold text-gray-900 mt-8">
                  {{ aboutUs.head2 }}
                </h3>
                <ul class="list-disc mt-4 text-lg text-gray-800 pl-6">
                  <li>
                    <span class="font-semibold">Revenue Collection</span>
                    {{ aboutUs.list2.item1 }}
                  </li>
                  <li>
                    <span class="font-semibold">Trade Facilitation</span>
                    {{ aboutUs.list2.item2 }}
                  </li>
                  <li>
                    <span class="font-semibold">Border control</span>
                    {{ aboutUs.list2.item3 }}
                  </li>
                </ul>

                <h3 class="text-xl font-bold text-gray-900 mt-8">
                  {{ aboutUs.head3 }}
                </h3>
               <div v-html="aboutUs.mission"></div>
                <ul class="list-disc mt-4 text-lg text-gray-800 pl-6">
                  <li v-if="aboutUs.list3.item1">{{ aboutUs.list3.item1 }}</li>
                  <li v-if="aboutUs.list3.item2">{{ aboutUs.list3.item2 }}</li>
                  <li v-if="aboutUs.list3.item3">{{ aboutUs.list3.item3 }}</li>
                  <li v-if="aboutUs.list3.item4">{{ aboutUs.list3.item4 }}</li>
                </ul>

                <h3 class="text-xl font-semibold text-gray-900 mt-8">
                  {{ aboutUs.head4 }}
                </h3>
                <ul class="list-disc mt-4 text-lg text-gray-800 pl-6">
                  <li v-if="aboutUs.list4.item1">{{ aboutUs.list4.item1 }}</li>
                  <li v-if="aboutUs.list4.item2">{{ aboutUs.list4.item2 }}</li>
                  <li v-if="aboutUs.list4.item3">{{ aboutUs.list4.item3 }}</li>
                  <li v-if="aboutUs.list4.item4">{{ aboutUs.list4.item4 }}</li>
                  <li v-if="aboutUs.list4.item4">{{ aboutUs.list4.item5 }}</li>
                </ul>

                <h3 class="text-xl font-semibold text-gray-900 mt-8">
                  {{ aboutUs.head5 }}
                </h3>
                <ul class="list-disc mt-4 text-lg text-gray-800 pl-6">
                  <li v-if="aboutUs.list5.item1">{{ aboutUs.list5.item1 }}</li>
                  <li v-if="aboutUs.list5.item2">{{ aboutUs.list5.item2 }}</li>
                  <li v-if="aboutUs.list5.item3">{{ aboutUs.list5.item3 }}</li>
                  <li v-if="aboutUs.list5.item4">{{ aboutUs.list5.item4 }}</li>
                  <li v-if="aboutUs.list5.item5">{{ aboutUs.list5.item5 }}</li>
                  <li v-if="aboutUs.list5.item6">{{ aboutUs.list5.item6 }}</li>
                  <li v-if="aboutUs.list5.item7">{{ aboutUs.list5.item7 }}</li>
                  <li v-if="aboutUs.list5.item8">{{ aboutUs.list5.item8 }}</li>
                  <li v-if="aboutUs.list5.item9">{{ aboutUs.list5.item9 }}</li>
                  <li v-if="aboutUs.list5.item10">
                    {{ aboutUs.list5.item10 }}
                  </li>
                </ul>

                <!-- <cta /> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import gql from "graphql-tag";
import sideNav from "./nav/sideNav";
import mainNav from "./nav/mainNav";
// import cta from "../components/callToAction";
import mobileDrop from "./nav/mobileDrop";

export default {
  data() {
    return {
      aboutUs: [],
      headerImage: [],
      api_url: process.env.VUE_APP_STRAPI_API_URL,
      routeParam: this.$route.params.id,
    };
  },
  components: {
    sideNav,
    mainNav,
    mobileDrop,
    // cta,
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  apollo: {
    aboutUs: gql`
      query aboutUs {
        aboutUs {
          title
          par1
          head1
          list1 {
            item1
            item2
            item3
            item4
            item5
          }
          head2
          list2 {
            item1
            item2
            item3
            item4
            item5
          }
          head3
          mission
          branches
          list3 {
            item1
            item2
            item3
            item4
            item5
          }
          head4
          list4 {
            item1
            item2
            item3
            item4
            item5
          }
          head5
          list5 {
            item1
            item2
            item3
            item4
            item5
            item6
            item7
            item8
            item9
            item10
          }
        }
      }
    `,
    headerImage: gql`
      query headerImage {
        headerImage {
          about {
            url
          }
        }
      }
    `,
  },
};
</script>
