<template>
  <!-- Mobile Content Navigation -->
  <div class="px-6 py-9 bg-gray-200 border border-gray-300 md:hidden sm:block">
    <div class="dropdown inline-block w-full relative">
      <button
        class="bg-white w-full text-gray-700 font-semibold py-2 px-4 pr-2 rounded inline-flex items-between justify-between focus:outline-none"
      >
        <span class="mr-1">Select Topic</span>
        <svg
          class="fill-current h-5 w-5 relative top-1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <path
            d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
          />
        </svg>
      </button>
      <ul
        class="dropdown-content bg-white border border-gray-300 rounded-md dropdown-menu absolute hidden text-gray-700 mt-1 pt-1 z-10 w-full"
      >
        <li class="font-bold">
          <p class="text-gray-400 text-sm py-2 px-4 block whitespace-no-wrap">
            Section 1/1: Introduction
          </p>
        </li>
        <!-- Active Menu -->
        <!-- <li class="font-bold text-sm bg-gray-100 text-nra-blue-500"><a class="ml-4 py-3 px-4 block whitespace-no-wrap" href="#">Individual Taxpayer</a></li> -->

        <div>
          <li v-for="ag in agIntros" v-bind:key="ag.id">
            <router-link
              :to="{
                name: 'agentsIntro',
                params: { id: ag.id, slug: ag.slug },
              }"
              :key="ag.id"
              class="ml-4 py-2 px-4 block whitespace-no-wrap"
              >{{ ag.heading }}</router-link
            >
          </li>
        </div>
      </ul>
    </div>
  </div>
</template>

<script>
import gql from "graphql-tag";

export default {
  name: "",
  data() {
    return {
      agIntros: [],
      api_url: process.env.VUE_APP_STRAPI_API_URL,
      routeParam: this.$route.params.id,
    };
  },

  apollo: {
    agIntros: gql`
      query agIntros {
        agIntros {
          id
          title
          heading
          intro
          slug
        }
      }
    `,
    // variables() {
    //   return {
    //     id: this.routeParam,
    //   };
    // },
  },
};
</script>
<style scoped>
a.router-link-exact-active {
  --tw-text-opacity: 1;
  color: rgba(0, 93, 180, var(--tw-text-opacity));
}
a.router-link-exact-active {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
a.router-link-exact-active {
  font-weight: 700;
}
a.router-link-exact-active {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
}
</style>