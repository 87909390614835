<template>
  <div>
    <!-- Main NAV -->

    <scroll-fixed-header :fixed.sync="fixed" :threshold="163">
      <nav class="hidden sm:block">
        <div class="max-w-7xl mx-auto bg-gray-50">
          <div class="relative">
            <div class="flex border divide-x divide-gray-200">
              <div
                style="padding: 7px"
                v-if="fixed == true"
                class="flex items-center justify-center"
              >
                <img
                  class="w-auto h-9 mr-2"
                  src="../assets/img/SSRA.png"
                  alt="South Sudan Revenue Authority"
                />
                <h1 class="uppercase font-bold text-3xl">NRA</h1>
              </div>

              <router-link
                :to="{ name: 'index' }"
                class="px-8 text-sm text-gray-500 outline-none focus:outline-none py-4 inline-flex items-center uppercase"
              >
                <span>Home</span>
              </router-link>

              <router-link
                :to="{
                  name: 'indIntro',
                  params: { id: '1', slug: 'individual-taxpayer' },
                }"
                class="px-8 text-sm text-gray-500 outline-none focus:outline-none py-4 inline-flex items-center uppercase"
              >
                <span>Individual</span>
              </router-link>

              <router-link
                :to="{
                  name: 'bsnIntro',
                  params: { id: '1', slug: 'what-is-considered-a-business' },
                }"
                class="px-8 text-sm text-gray-500 outline-none focus:outline-none py-4 inline-flex items-center uppercase"
              >
                <span>Business</span>
              </router-link>

              <router-link
                :to="{
                  name: 'invIntro',
                  params: { id: '1', slug: 'investment-in-south-sudan' },
                }"
                class="px-8 text-sm text-gray-500 outline-none focus:outline-none py-4 inline-flex items-center uppercase"
              >
                <span>Investors</span>
              </router-link>

              <router-link
                :to="{
                  name: 'agentsIntro',
                  params: { id: '1', slug: 'agents' },
                }"
                class="px-8 text-sm text-gray-500 outline-none focus:outline-none py-4 inline-flex items-center uppercase"
              >
                <span>Agents</span>
              </router-link>

              <div
                style="padding: 12px"
                v-if="fixed == true"
                class="hidden sm:flex items-center justify-end sm:flex-1 lg:w-0"
              >
                <div
                  v-if="fixed == true"
                  class="sm:flex items-center justify-end space-x-4 sm:flex-1 lg:w-0"
                >
                  <a
                    v-bind:href="loginRegLink.login"
                    target="_blank"
                    class="btn btn-blue"
                  >
                    LOG IN
                  </a>
                  <a
                    v-bind:href="loginRegLink.register"
                    target="_blank"
                    class="btn btn-orange"
                  >
                    <svg
                      class="h-5 w-5 text-gray-900 mr-2 -ml-3"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M8 11V7a4 4 0 118 0m-4 8v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2z"
                      />
                    </svg>
                    <span>REGISTER</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </scroll-fixed-header>

    <!-- End Head -->

    <main class="relative mt-12">
      <div v-if="newsUpdate" class="max-w-5xl mx-auto">
        <div class="border-b border-gray-300 pb-4 mb-8 px-8 md:px-0">
          <p
            class="border-l-4 mb-8 pl-1 border-nra-cyan-500 text-nra-blue-500 font-bold text-xs"
          >
            STORY TAG
          </p>
          <p class="text-3xl font-extrabold sm:text-4xl mt-2">
            {{ newsUpdate.body }}
          </p>
          <p class="mt-20 text-xs">
            Foreign Policy <span class="text-black mx-1">|</span>
            <span class="text-nra-blue-500">{{ newsUpdate.date }}</span>
          </p>
        </div>

        <div class="">
          <!-- <img  class="w-full h-1/4 bg-gray-200 mr-10 mb-8" :src="api_url + newsUpdate.image.url" alt="" /> -->
          <iframe
            class="w-full"
           width="500"
           height="500"
            v-bind:src="newsUpdate.videoUrl"
          >
          </iframe>
        </div>

        <div class="mb-8 md:flex px-8 md:px-0">
          <div class="w-full md:w-1/6">
            <p class="flex font-bold text-nra-blue-600">
              <span class="inline-block mr-2 mt-2">Share</span>
              <a
                href="http://www.twitter.com/share?url=https://nra.gov.ss/media-center"
                target="_blank"
                class="inline-block p-2"
              >
                <svg
                  class="h-6 w-6"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84"
                    clip-rule="evenodd"
                  />
                </svg>
              </a>
              <a
                href="https://www.facebook.com/sharer/sharer.php?u=https://nra.gov.ss/media-center"
                target="_blank"
                class="inline-block p-2"
              >
                <svg
                  class="h-6 w-6 inline-block"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                    clip-rule="evenodd"
                  />
                </svg>
              </a>
            </p>
          </div>
          <div class="w-full md:w-5/6">
            <div class="md:w-10/12">
              <!-- <p class="mb-8">
                            {{newsUpdate.par1}}
                        </p>
                        <p class="mb-8">
                            {{newsUpdate.par2}}
                        </p>
                        <p class="mb-8">
                            {{newsUpdate.par3}}
                        </p>
                        <p class="mb-8">
                            {{newsUpdate.par4}}
                        </p>
                        <p class="mb-8">
                            {{newsUpdate.par5}}
                        </p>
                        <p class="mb-8">
                            {{newsUpdate.par6}}
                        </p>
                        <p class="mb-8">
                            {{newsUpdate.par7}}
                        </p>
                        <p class="mb-8">
                            {{newsUpdate.par8}}
                        </p>
                        <p class="mb-8">
                            {{newsUpdate.par9}}
                        </p>
                        <p class="mb-8">
                            {{newsUpdate.par10}}
                        </p> -->
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import gql from "graphql-tag";

export default {
  data() {
    return {
      fixed: false,
      newsUpdates: [],
      loginRegLink: [],
      api_url: process.env.VUE_APP_STRAPI_API_URL,
      routeParam: this.$route.params.id,
    };
  },
  components: {
    // Header,
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  apollo: {
    newsUpdate: {
      query: gql`
        query newsUpdate($id: ID!) {
          newsUpdate(id: $id) {
            id
            date
            body
            videoUrl
            youtubeUrl
            par1
            par2
            par3
            par4
            par5
            par6
            par7
            par8
            par9
            par10
            image {
              url
            }
          }
        }
      `,
      variables() {
        return {
          id: this.routeParam,
        };
      },
      loginRegLink: gql`
        query loginRegLink {
          loginRegLink {
            login
            register
          }
        }
      `,
    },
  },
};
</script>

<style></style>
