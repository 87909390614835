<template>
  <div>
    <section
      id="top-nav"
      class="hidden sm:block relative bg-nra-blue-500 shadow"
    >
      <div class="max-w-7xl mx-auto px-4 md:px-0">
        <div class="py-4 sm:space-x-10">
          <div class="text-right">
            <nav class="space-x-10">
              <router-link :to="{ name: 'about-us' }" class="top-link">
                About Us
              </router-link>
              <router-link :to="{ name: 'resources' }" class="top-link">
                Resources
              </router-link>
              <router-link
                :to="{name: 'reports'}"
                class="top-link"
              >
                Reports
              </router-link>

                <router-link
                :to="{name: 'career'}"
                class="top-link"
              >
              Career
              </router-link>
              <router-link :to="{ name: 'mediaCenter' }" class="top-link">
                Media Center
              </router-link>
              <a
                style="pointer-events: none; opacity: 40%"
                href="#"
                class="top-link"
              >
                Help & Support
              </a>
              <router-link :to="{ name: 'tools' }" class="top-link">
                Tools
              </router-link>
              <router-link :to="{ name: 'tinStatus' }" class="top-link">
                Application Status
              </router-link>
            </nav>
          </div>
        </div>
      </div>
    </section>
    <!-- <scroll-fixed-header :fixed.sync="fixed" :threshold="163" v-if="size == small"> -->
    <section
      id="header"
      x-data="{ mobileMenuOpen: false }"
      class="relative bg-white"
    >
      <div
        class="max-w-7xl mx-auto px-4 md:px-0"
        style="background-color: white"
      >
        <div
          class="flex justify-between items-center py-3 sm:justify-start sm:space-x-10"
        >
          <div class="w-0 flex-1 flex">
            <a href="/" class="inline-flex">
              <div class="flex">
                <div class="flex-shrink-0">
                  <div
                    class="flex items-center justify-center sm:h-20 sm:w-20 rounded-full bg-white"
                  >
                    <img
                      class="h-16 w-auto sm:h-20 p-1"
                      src="../assets/img/SSRA.png"
                      alt="South Sudan Revenue Authority"
                    />
                  </div>
                </div>
                <div class="ml-3 self-center">
                  <h5
                    class="text-xs font-medium text-black m-0 p-0 sm:text-lg sm:leading-6"
                  >
                    The Republic of South Sudan
                  </h5>
                  <h5
                    class="text-base font-semibold text-black sm:text-2xl sm:leading-6 mt-0 pt-0"
                  >
                    South Sudan Revenue Authority
                  </h5>
                </div>
              </div>
            </a>
          </div>
          <div class="-mr-1 -my-2 sm:hidden">
            <button
              x-on:click="mobileMenuOpen = true"
              type="button"
              class="rounded-md p-2 inline-flex items-center justify-center text-nra-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-700"
            >
              <span class="sr-only">Open menu</span>
              <svg
                class="h-6 w-6"
                x-description="Heroicon name: menu"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M4 6h16M4 12h16M4 18h16"
                ></path>
              </svg>
            </button>
          </div>
          <div class="hidden sm:flex items-center justify-end sm:flex-1 lg:w-0">
            <div
              class="sm:flex items-center justify-end space-x-4 sm:flex-1 lg:w-0"
            >
              <a
                v-if="loginRegLink.login"
                v-bind:href="loginRegLink.login"
                target="_blank"
                class="btn btn-blue"
              >
                LOG IN
              </a>
              <a
                v-bind:href="loginRegLink.register"
                target="_blank"
                class="btn btn-orange"
              >
                <svg
                  class="h-5 w-5 text-gray-900 mr-2 -ml-3"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M8 11V7a4 4 0 118 0m-4 8v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2z"
                  />
                </svg>
                <span>REGISTER</span>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div
        x-description="Mobile menu, show/hide based on mobile menu state."
        x-show="mobileMenuOpen"
        x-transition:enter="duration-200 ease-out"
        x-transition:enter-start="opacity-0 scale-95"
        x-transition:enter-end="opacity-100 scale-100"
        x-transition:leave="duration-100 ease-in"
        x-transition:leave-start="opacity-100 scale-100"
        x-transition:leave-end="opacity-0 scale-95"
        class="absolute top-0 inset-x-0 z-10 transition transform origin-top-right sm:hidden"
        style="display: none"
      >
        <div
          class="shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-gray-50"
        >
          <div class="">
            <div class="flex items-center justify-between pt-3 px-4">
              <div>
                <a href="/" class="inline-flex">
                  <div class="flex">
                    <div class="flex-shrink-0">
                      <div
                        class="flex items-center justify-center sm:h-20 sm:w-20 rounded-full bg-white"
                      >
                        <img
                          class="h-16 w-auto sm:h-20 p-1"
                          src="../assets/img/SSRA.png"
                          alt="South Sudan Revenue Authority"
                        />
                      </div>
                    </div>
                    <div class="ml-3 self-center">
                      <h5 class="text-xs font-medium m-0 p-0">
                        The Republic of South Sudan
                      </h5>
                      <h5 class="text-base font-semibold">
                        South Sudan Revenue Authority
                      </h5>
                    </div>
                  </div>
                </a>
              </div>
              <div class="">
                <button
                  x-on:click="mobileMenuOpen = false"
                  type="button"
                  class="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-700"
                >
                  <span class="sr-only">Close menu</span>
                  <svg
                    class="h-6 w-6"
                    x-description="Heroicon name: x"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M6 18L18 6M6 6l12 12"
                    ></path>
                  </svg>
                </button>
              </div>
            </div>
            <div class="mt-3">
              <nav class="grid" x-on:click="mobileMenuOpen = false">
                <router-link
                  :to="{
                    name: 'indIntro',
                    params: { id: '1', slug: 'individual-taxpayer' },
                  }"
                  class="px-8 text-sm text-gray-500 outline-none focus:outline-none py-4 inline-flex items-center uppercase"
                >
                  <span>Individual</span>
                </router-link>

                <router-link
                  :to="{
                    name: 'bsnIntro',
                    params: {
                      id: '1',
                      slug: 'what-is-considered-a-business',
                    },
                  }"
                  class="px-8 text-sm text-gray-500 outline-none focus:outline-none py-4 inline-flex items-center uppercase"
                >
                  <span>Business</span>
                </router-link>

                <router-link
                  :to="{
                    name: 'invIntro',
                    params: { id: '1', slug: 'investment-in-south-sudan' },
                  }"
                  class="px-8 text-sm text-gray-500 outline-none focus:outline-none py-4 inline-flex items-center uppercase"
                >
                  <span>Investors</span>
                </router-link>

                <router-link
                  :to="{
                    name: 'agentsIntro',
                    params: { id: '1', slug: 'agents' },
                  }"
                  class="px-8 text-sm text-gray-500 outline-none focus:outline-none py-4 inline-flex items-center uppercase"
                >
                  <span>Agents</span>
                </router-link>
              </nav>
            </div>
          </div>
          <div class="">
            <div
              class="grid grid-cols-2 gap-y-4 py-6 px-5 bg-gray-50"
              x-on:click="mobileMenuOpen = false"
            >
              <router-link
                :to="{ name: 'about-us' }"
                class="text-base font-medium text-gray-900 hover:text-gray-700"
              >
                About Us
              </router-link>

              <router-link
                :to="{ name: 'resources' }"
                class="text-base font-medium text-gray-900 hover:text-gray-700"
              >
                Resources
              </router-link>

              <router-link
              :to="{name: 'reports'}"
                class="text-base font-medium text-gray-900 hover:text-gray-700"
              >
                Reports
              </router-link>

                <router-link
              :to="{name: 'career'}"
                class="text-base font-medium text-gray-900 hover:text-gray-700"
              >
                Career
              </router-link>

              <router-link
                :to="{ name: 'mediaCenter' }"
                class="text-base font-medium text-gray-900 hover:text-gray-700"
              >
                Media Center
              </router-link>

              <a
                style="pointer-events: none; opacity: 40%"
                href="#"
                class="text-base font-medium text-gray-900 hover:text-gray-700"
              >
                Help & Support
              </a>

              <router-link
                :to="{ name: 'tools' }"
                class="text-base font-medium text-gray-900 hover:text-gray-700"
              >
                Tools
              </router-link>
              <router-link
                :to="{ name: 'tinStatus' }"
                class="text-base font-medium text-gray-900 hover:text-gray-700"
              >
                Application Status
              </router-link>
            </div>
            <div
              v-if="loginRegLink.login"
              class="grid grid-cols-2 gap-x-4 py-6 px-5 bg-gray-100"
            >
              <a
                v-bind:href="loginRegLink.login"
                target="_blank"
                class="btn btn-blue"
              >
                Login
              </a>
              <a
                v-bind:href="loginRegLink.register"
                target="_blank"
                class="btn btn-orange"
              >
                Register
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- </scroll-fixed-header> -->
  </div>
</template>

<script>
import gql from "graphql-tag";
export default {
  data() {
    return {
      // size: false,
      fixed: false,
      loginRegLink: [],
    };
  },

  apollo: {
    loginRegLink: gql`
      query {
        loginRegLink {
          login
          register
        }
      }
    `,
  },
};
</script>
