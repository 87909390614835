<template>
   <footer class="bg-black" aria-labelledby="footerHeading">
      <h2 id="footerHeading" class="sr-only">Footer</h2>
      <div class="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">

        <div class="mb-12 pb-12">
            <a href="/" class="inline-flex">
              <div class="flex">
                <div class="flex-shrink-0">
                  <div class="flex items-center justify-center sm:h-20 sm:w-20 rounded-full bg-white">
                    <img class="h-16 w-auto sm:h-20 p-1" src="../assets/img/SSRA.png" alt="South Sudan Revenue Authority" />
                  </div>
                </div>
                <div class="ml-3 self-center">
                  <h5 class="text-xs font-medium text-white m-0 p-0 sm:text-lg sm:leading-6">The Republic of South Sudan
                  </h5>
                  <h5 class="text-base font-semibold text-white sm:text-2xl sm:leading-6 mt-0 pt-0">South Sudan Revenue Authority</h5>
                </div>
              </div>
            </a>
        </div>

        <div class="mt-12 pb-8 xl:grid xl:grid-cols-6 xl:gap-8">

          <div class="mt-12 sm:mt-0">
            <h3 class="text-xs font-semibold text-white tracking-wider pb-4 border-b border-gray-700">
              Individual
            </h3>
             <ul class="mt-4 space-y-4">
                    <li>
                        <router-link :to="{
                  name: 'indIntro',
                  params: { id: '1', slug: 'individual-taxpayer' },
                }" class="text-smsm text-gray-400 hover:text-white">
                            Introduction
                        </router-link>
                    </li>
                    <li>
                        <router-link :to="{
                  name: 'tinRegistration',
                  params: { id: '1', slug: 'registering-for-a-tin' },
                }" class="text-smsm text-gray-400 hover:text-white">
                            TIN Registration
                        </router-link>
                    </li>
                    <li>
                        <router-link :to="{
                  name: 'tax-for-ind',
                  params: { id: '1', slug: 'personal-income-tax-pit' },
                }" class="text-smsm text-gray-400 hover:text-white">
                            Taxes for Individuals
                        </router-link>
                    </li>
                    <li>
                        <router-link :to="{ name: 'indFaqs' }" class="text-smsm text-gray-400 hover:text-white">
                            FAQs
                        </router-link>
                    </li>
                </ul>
          </div>

          <div class="mt-12 sm:mt-0">
            <h3 class="text-xs font-semibold text-white tracking-wider pb-4 border-b border-gray-700">
              Business
            </h3>
           <ul class="mt-4 space-y-4">
                    <li>
                        <router-link :to="{
                  name: 'bsnIntro',
                  params: { id: '1', slug: 'what-is-considered-a-business' },
                }" class="text-sm text-gray-400 hover:text-white">
                            Introduction
                        </router-link>
                    </li>
                    <li>
                        <router-link :to="{
                  name: 'bsnTinReg',
                  params: {
                    id: '1',
                    slug: 'how-a-business-can-register-for-tax',
                  },
                }" class="text-sm text-gray-400 hover:text-white">
                            TIN Registration
                        </router-link>
                    </li>
                    <!-- <li>
                        <a href="#" class="text-sm text-gray-400 hover:text-white">
                            Filing and Payment
                        </a>
                    </li> -->
                    <li>
                        <router-link :to="{
                  name: 'taxComp',
                  params: {
                    id: '1',
                    slug: 'what-is-tax-compliance-certificate',
                  },
                }" class="text-sm text-gray-400 hover:text-white">
                            Tax Compliance Certificate
                        </router-link>
                    </li>
                    <li>
                        <router-link :to="{ name: 'bsnFaqs' }" class="text-sm text-gray-400 hover:text-white">
                            FAQs
                        </router-link>
                    </li>
                </ul>
          </div>

          <div class="mt-12 sm:mt-0">
            <h3 class="text-xs font-semibold text-white tracking-wider pb-4 border-b border-gray-700">
              Investors
            </h3>
            <ul class="mt-4 space-y-4">
                    <li>
                        <router-link :to="{
                  name: 'invIntro',
                  params: { id: '1', slug: 'investment-in-south-sudan' },
                }" class="text-sm text-gray-400 hover:text-white">
                            Investing in South Sudan
                        </router-link>
                    </li>
                    <!-- <li>
              <router-link
                :to="{
                  name: 'invIntro',
                  params: { id: '1', slug: 'investment-in-south-sudan' },
                }"
                class="text-sm text-gray-400 hover:text-white"
              >
                Investment Certificate
              </router-link>
            </li>
            <li>
              <router-link
                :to="{
                  name: 'invIntro',
                  params: { id: '1', slug: 'investment-in-south-sudan' },
                }"
                class="text-sm text-gray-400 hover:text-white"
              >
                Foreign investors
              </router-link>
            </li>
            <li>
              <router-link
                :to="{
                  name: 'invIntro',
                  params: { id: '1', slug: 'investment-in-south-sudan' },
                }"
                class="text-sm text-gray-400 hover:text-white"
              >
                How to register
              </router-link>
            </li> -->
                </ul>
            <h3 class="mt-12 text-xs font-semibold text-white tracking-wider pb-4 border-b border-gray-700">
              Agents
            </h3>
           <ul class="mt-4 space-y-4">
                    <li>
                        <router-link :to="{
                  name: 'agentsIntro',
                  params: { id: '2', slug: 'becoming-a-tax-agent' },
                }" class="text-sm text-gray-400 hover:text-white">
                            Becoming a tax agent
                        </router-link>
                    </li>
                </ul>
          </div>

          <div class="mt-12 sm:mt-0">
            <h3 class="text-xs font-semibold text-white tracking-wider pb-4 border-b border-gray-700">
              About NRA
            </h3>
           <ul class="mt-4 space-y-4">
                    <li>
                        <router-link :to="{ name: 'about-us' }" class="text-sm text-gray-400 hover:text-white">
                            Who we are
                        </router-link>
                    </li>
                    <li>
                        <router-link :to="{ name: 'leadership' }" class="text-sm text-gray-400 hover:text-white">
                            Leadership
                        </router-link>
                    </li>
                    <!-- <li>
                        <a href="#" class="text-sm text-gray-400 hover:text-white">
                            Contact Us
                        </a>
                    </li> -->
                </ul>
          </div>

          <div class="mt-12 sm:mt-0">
            <h3 class="text-xs font-semibold text-white tracking-wider pb-4 border-b border-gray-700">
              Support
            </h3>
            <ul class="mt-4 space-y-4">
              <li>
                <a href="tel:+211918806493" class="text-sm text-gray-400 hover:text-white">
                  +211 918806493 
                </a>
              </li>
              <li>
                <a href="tel:+211912388318" class="text-sm text-gray-400 hover:text-white">
                  +211 912388318
                </a>
              </li>
              <li>
                <a href="tel:+211919382390" class="text-sm text-gray-400 hover:text-white">
                  +211 919382390
                </a>
              </li>
              <li>
                <a href="tel:+211917803637" class="text-sm text-gray-400 hover:text-white">
                  +211 917803637
                </a>
              </li>
               <li>
                <a href="tel:+211921058043" class="text-sm text-gray-400 hover:text-white">
                  +211 921058043
                </a>
              </li>
            </ul>
          </div>


          <div class="mt-12 xl:mt-0">
            <h3 class="text-xs font-semibold text-white tracking-wider pb-4 border-b border-gray-700">
              Legal
            </h3>
            <ul class="mt-4 space-y-4">
              <!-- <li>
                <a href="#" class="text-sm text-gray-400 hover:text-white">
                  Terms & Conditions
                </a>
              </li> -->
              <li>
                <router-link :to="{ name: 'dataPrivacy' }" class="text-sm text-gray-400 hover:text-white">
                  Data & Privacy
                </router-link>
              </li>
            </ul>
          </div>

        </div>

        <div class="pt-8 lg:flex lg:items-center lg:justify-between xl:mt-0">
          <div>
            <div class="mb-4 md:mb-0">
              <a href="https://eservices.gov.ss/" target="_blank">
                <img class="h-35 w-auto" src="../assets/img/eservices-gov-ss.svg-light.svg" alt="eServices.gov.ss" />
              </a>
            </div>
          </div>
          <div class="md:flex md:space-x-6 sm:order-2">
            <a href="mailto:support@nra.gov.ss" class="flex mb-4 sm:mb-0 text-nra-orange-500 hover:text-nra-orange-400">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" viewBox="0 0 24 24" fill="currentColor">
                <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
              </svg>
              <span class="text-gray-500 ml-2">support@nra.gov.ss</span>
            </a>
            <a href="https://twitter.com/NRA_SouthSudan" target="_blank" class="flex mb-4 sm:mb-0 text-nra-orange-500 hover:text-nra-orange-400">
              <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                <path fill-rule="evenodd"
                  d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84"
                  clip-rule="evenodd" />
              </svg>
              <span class="text-gray-500 ml-2">Follow us on Twitter</span>
            </a>
            <a href="https://www.facebook.com/National-Revenue-Authority-110567787879455" target="_blank" class="flex mb-4 sm:mb-0 text-nra-orange-500 hover:text-nra-orange-400">
              <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                <path fill-rule="evenodd"
                  d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                  clip-rule="evenodd" />
              </svg>
              <span class="text-gray-500 ml-2">Follow us on Facebook</span>
            </a>
          </div>
        </div>

        <div class="mt-8 border-t border-gray-700 pt-8 sm:flex sm:items-center sm:justify-between">
          <div class="flex space-x-6 sm:order-2">
            <div class="flex text-nra-orange-500 hover:text-nra-orange-400">
              <img class="h-25 w-auto" src="../assets/img/ss-flag.svg" alt="" />
              <span class="text-sm text-gray-500 ml-2">Proudly South Sudanese</span>
            </div>
          </div>
          <p class="mt-8 text-sm text-gray-400 sm:mt-0 sm:order-1">
            &copy; South Sudan Revenue Authority {{year}}. All Rights Reserved.
          </p>
        </div>
      </div>
    </footer>
</template>

<script>
export default {
  data() {
    return {
      year:""
    }
  },
  mounted () {
  window.scrollTo(0, 0)
const d = new Date();
 this.year = d.getFullYear();
},
};
</script>