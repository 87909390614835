<template>
  <div>
    <!-- Main NAV -->

    <scroll-fixed-header :fixed.sync="fixed" :threshold="163">
      <nav class="hidden sm:block">
        <div class="max-w-7xl mx-auto bg-gray-50">
          <div class="relative">
            <div class="flex border divide-x divide-gray-200">
              <div
                style="padding: 7px"
                v-if="fixed == true"
                class="flex items-center justify-center"
              >
                <img
                  class="w-auto h-9 mr-2"
                  src="../assets/img/SSRA.png"
                  alt="South Sudan Revenue Authority"
                />
                <h1 class="uppercase font-bold text-3xl">NRA</h1>
              </div>

              <router-link
                :to="{ name: 'index' }"
                class="px-8 text-sm text-gray-500 outline-none focus:outline-none py-4 inline-flex items-center uppercase"
              >
                <span>Home</span>
              </router-link>

              <router-link
                :to="{
                  name: 'indIntro',
                  params: { id: '1', slug: 'individual-taxpayer' },
                }"
                class="px-8 text-sm text-gray-500 outline-none focus:outline-none py-4 inline-flex items-center uppercase"
              >
                <span>Individual</span>
              </router-link>

              <router-link
                :to="{
                  name: 'bsnIntro',
                  params: { id: '1', slug: 'what-is-considered-a-business' },
                }"
                class="px-8 text-sm text-gray-500 outline-none focus:outline-none py-4 inline-flex items-center uppercase"
              >
                <span>Business</span>
              </router-link>

              <router-link
                :to="{
                  name: 'invIntro',
                  params: { id: '1', slug: 'investment-in-south-sudan' },
                }"
                class="px-8 text-sm text-gray-500 outline-none focus:outline-none py-4 inline-flex items-center uppercase"
              >
                <span>Investors</span>
              </router-link>

              <router-link
                :to="{
                  name: 'agentsIntro',
                  params: { id: '1', slug: 'agents' },
                }"
                class="px-8 text-sm text-gray-500 outline-none focus:outline-none py-4 inline-flex items-center uppercase"
              >
                <span>Agents</span>
              </router-link>

              <div
                style="padding: 12px"
                v-if="fixed == true"
                class="hidden sm:flex items-center justify-end sm:flex-1 lg:w-0"
              >
                <div
                  v-if="loginRegLink.login"
                  class="sm:flex items-center justify-end space-x-4 sm:flex-1 lg:w-0"
                >
                  <a
                    v-bind:href="loginRegLink.login"
                    target="_blank"
                    class="btn btn-blue"
                  >
                    LOG IN
                  </a>
                  <a
                    v-bind:href="loginRegLink.register"
                    target="_blank"
                    class="btn btn-orange"
                  >
                    <svg
                      class="h-5 w-5 text-gray-900 mr-2 -ml-3"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M8 11V7a4 4 0 118 0m-4 8v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2z"
                      />
                    </svg>
                    <span>REGISTER</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </scroll-fixed-header>

    <!-- End Head -->

    <main class="relative">
      <div class="md:max-w-4xl mx-auto">
        <div class="my-10 px-8 md:px-0">
          <div class="mb-10">
            <form @submit.prevent="isShowing = true">
              <div class="mt-0 mb-3 flex rounded-md shadow-sm">
                <div
                  class="relative flex items-stretch flex-grow focus-within:z-10"
                >
                  <input
                    required
                    v-model="message.filterval"
                    v-on:keyup="filter(), (isShowing = true)"
                    type="text"
                    class="pl-4 block w-full rounded-none rounded-l-md sm:text-lg border border-gray-300"
                    placeholder="I would Like to..."
                  />
                </div>
                <button
                  @click="filter"
                  type="submit"
                  class="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 text-base rounded-r-md text-black bg-nra-orange-500 hover:bg-nra-orange-400 focus:outline-none"
                >
                  <svg
                    class="h-5 w-5 text-gray-900"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                    />
                  </svg>
                  <span>Search</span>
                </button>
              </div>
              <b>
                <p
                  v-if="message.filterval != ''"
                  v-show="isShowing"
                  class="mb-8 mt-3 ml-2 text-sm"
                >
                  Showing results for <i>" {{ message.filterval }} "</i>
                </p>
                <p v-show="!isShowing" class="mb-8 mt-3 ml-2 text-sm">
                  Click to search . . .
                </p>
              </b>
            </form>
          </div>

          <!-- Search Results Container -->
          <div
            class="w-full"
            v-for="result in pageOfItems"
            v-bind:key="result.id"
          >
            <div
              v-if="message.filterval != ''"
              v-show="isShowing"
              class="pb-8 mb-8 border-b border-gray-300"
            >
              <a v-bind:href="result.link">
                <h3 class="font-bold text-xl mb-4 text-nra-blue-600">
                  {{ result.title }}
                </h3>
                <p class="text-sm my-3 text-gray-500">
                  {{ result.link }}
                </p>
              </a>
              <p class="">
                {{ result.intro }}
              </p>
            </div>
          </div>
          <center>
            <div v-show="isShowing" class="row justify-content-center">
              <div class="col-sm-12">
                <jw-pagination
                  :pageSize="6"
                  :items="results"
                  @changePage="onChangePage"
                ></jw-pagination>
              </div>
            </div>
          </center>

          <!-- Results Pagination -->
          <!-- <div class="mt-14 mb-12 max-w-3xl mx-auto">
                    <nav class="px-4 flex items-center justify-between sm:px-0">
                        <div class="-mt-px w-0 flex-1 flex">
                            <a href="#" class="pt-4 pr-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-nra-blue-300">
                                <svg class="mr-3 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fill-rule="evenodd" d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z" clip-rule="evenodd" />
                                </svg>
                                Previous
                            </a>
                        </div>
                        <div class="hidden md:-mt-px md:flex">
                            <a href="#" class="text-gray-500 hover:text-gray-700 pt-4 px-4 inline-flex items-center text-sm font-medium">
                                1
                            </a>
                            <a href="#" class="text-nra-blue-600 pt-4 px-4 inline-flex items-center text-sm font-medium" aria-current="page">
                                2
                            </a>
                            <a href="#" class="text-gray-500 hover:text-nra-blue-700 pt-4 px-4 inline-flex items-center text-sm font-medium">
                                3
                            </a>
                            <span class="text-gray-500 pt-4 px-4 inline-flex items-center text-sm font-medium">
                                ...
                            </span>
                            <a href="#" class="text-gray-500 hover:text-nra-blue-700 pt-4 px-4 inline-flex items-center text-sm font-medium">
                                8
                            </a>
                            <a href="#" class="text-gray-500 hover:text-nra-blue-700 pt-4 px-4 inline-flex items-center text-sm font-medium">
                                9
                            </a>
                            <a href="#" class="text-gray-500 hover:text-nra-blue-700 pt-4 px-4 inline-flex items-center text-sm font-medium">
                                10
                            </a>
                        </div>
                        <div class="-mt-px w-0 flex-1 flex justify-end">
                            <a href="#" class="pt-4 pl-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-nra-blue-700">
                                Next
                                <svg class="ml-3 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fill-rule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd" />
                                </svg>
                            </a>
                        </div>
                    </nav>
                </div> -->
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import gql from "graphql-tag";

export default {
  data() {
    return {
      fixed: false,
      isShowing: false,
      newsUpdates: [],
      loginRegLink: [],
      search: "",
      keyword: "",
      api_url: process.env.VUE_APP_STRAPI_API_URL,
      routeParam: this.$route.params.id,
      pageOfItems: [],
      message: {},
      results: [
        {
          //this is te data?yes
          id: "1",
          title: "Individual Taxpayer",
          link:
            "https://nra.gov.ss/individual/introduction/1/individual-taxpayer",
          intro:
            "An individual taxpayer is any person who is liable for paying taxes. Any person having an income can be registered as a taxpayer...",
        },
        {
          id: "2",
          title: "Individual Taxable Income",
          link:
            "https://nra.gov.ss/individual/introduction/2/individual-taxable-income",
          intro:
            "Taxable Income is the income that the government imposes tax on. Taxable income not only covers one’s salary but also earnings or compensation in other forms such as...",
        },
        {
          id: "3",
          title: "The Taxpayer Identification Number (TIN)",
          link:
            "https://nra.gov.ss/individual/introduction/3/the-taxpayer-identification-number",
          intro:
            "A TIN is a unique number specifically issued to identify a person upon successfully registering as a taxpayer...",
        },
        {
          id: "4",
          title: "Where the TIN applies",
          link:
            "https://nra.gov.ss/individual/introduction/4/where-the-tin-applies",
          intro:
            "A TIN is created for tax purposes and any related tax matters linked to the registered individual, such as...",
        },
        {
          id: "5",
          title: "Registering for a TIN",
          link:
            "https://nra.gov.ss/individual/tin-registration/1/registering-for-a-tin",
          intro:
            "Individuals registering for a TIN will require the following...",
        },
        {
          id: "6",
          title: "Where to register as a taxpayer",
          link:
            "https://nra.gov.ss/individual/tin-registration/2/where-to-register-as-a-taxpayer",
          intro:
            "Upon registration, a taxpayer will be issued a unique tax identification number (TIN) which will be reflected on all tax related documents related to...",
        },
        {
          id: "7",
          title: "Cancellation of a TIN Registration",
          link:
            "https://nra.gov.ss/individual/tin-registration/3/cancellation-of-a-tin-registration",
          intro:
            "A registered TIN can be cancelled following any of these conditions.If an individual dies, If the legal statues of a person...",
        },
        {
          id: "8",
          title: "Changing of Registration Status",
          link:
            "https://nra.gov.ss/individual/tin-registration/4/changing-of-registration-status",
          intro:
            "In case of any changes in the legal status of a person, a notice of changes within 15 day should be addressed to the Directorate of Taxation...",
        },
        {
          id: "9",
          title: "Personal-income-tax(PIT)",
          link:
            "https://nra.gov.ss/individual/taxes-for-individuals/1/personal-income-tax-pit",
          intro:
            "Is a direct tax taxed based on the monthly income earned by the individual. According to the Financial Act of 2019/2020, the following are the percentages for Perso...",
        },
        {
          id: "10",
          title: "Withholding Tax",
          link:
            "https://nra.gov.ss/individual/taxes-for-individuals/2/withholding-tax",
          intro:
            "Is a Direct Tax taxed on the individual’s earnings can be withheld by an employer, business or any entity that is responsible for withholding tax, and paid directly to the gover...",
        },
        {
          id: "11",
          title: "Advance Tax",
          link:
            "https://nra.gov.ss/individual/taxes-for-individuals/3/advance-tax",
          intro:
            "Is an indirect tax, taxpayers shall remit an advance payment of tax in an amount prescribed by regulation on or before the following dates",
        },
        {
          id: "12",
          title: "Frequently Asked Questions (FAQs)",
          link: "https://nra.gov.ss/individual/frequently-asked-questions",
          intro:
            "What is a TIN?. A Tax Identification Number (TIN) Is a unique number issued by the NRA to every taxpayer for tax related matters.",
        },
        {
          id: "13",
          title: "What is considered a Business",
          link:
            "https://nra.gov.ss/business/introduction/1/what-is-considered-a-business",
          intro:
            "A business is any small, medium or large organization which is required to be legally registered. Any Business registered within the Republic of South Sudan...",
        },
        {
          id: "14",
          title: "Categories of Businesses",
          link:
            "https://nra.gov.ss/business/introduction/2/categories-of-businesses",
          intro:
            "Businesses in the Republic of South Sudan include profit making companies, non governmental organizations and government institutions, they are categorised in...",
        },
        {
          id: "15",
          title: "The NRA TIN for business",
          link:
            "https://nra.gov.ss/business/introduction/3/the-nra-tin-for-business",
          intro:
            "A TIN (Tax Identification Number) is a unique number issued to any legally registered entity to identify the taxpayer and help in management of all tax related...",
        },
        {
          id: "16",
          title: "Why businesses need to register for a TIN",
          link:
            "https://nra.gov.ss/business/introduction/4/why-businesses-need-to-register-for-a-tin",
          intro:
            "A TIN is made for tax purposes and any related tax matters linked to the specific business or company, such as:Filing and submitting returns, for transaction...",
        },
        {
          id: "17",
          title: "Types of Taxes that Apply on Businesses",
          link:
            "https://nra.gov.ss/business/introduction/5/types-of-taxes-that-apply-on-business",
          intro:
            "Taxes are levied according to the nature of the business. Taxes that apply to businesses include the following: Business Profit Tax Withholding Tax Advance...",
        },
        {
          id: "18",
          title: "Key terms to understand",
          link:
            "https://nra.gov.ss/business/introduction/6/key-terms-to-understand",
          intro:
            "This defined in the taxation act as income earned, including, but not limited to income from production, trade, financial investment, professional or other...",
        },
        {
          id: "19",
          title: "How a business can register for tax",
          link:
            "https://nra.gov.ss/business/tin-registration-for-business/1/how-a-business-can-register-for-tax",
          intro:
            "Registration can be done through any of the following ways; Online through the NRA website NRA taxation officer at NRA offices Through a tax agent",
        },
        {
          id: "20",
          title: "Documents required to register",
          link:
            "https://nra.gov.ss/business/tin-registration-for-business/2/documents-required-to-register",
          intro:
            "After all required documents have been validated, a business is issued with a TIN. The number will be reflected on all tax related documents for...",
        },
        {
          id: "21",
          title: "Cancellation of Registration",
          link:
            "https://nra.gov.ss/business/tin-registration-for-business/3/cancellation-of-registration",
          intro:
            "Cancellation of a business TIN can occur if The incorporated entity closes down If an individual dies (In the case of sole proprietorship) If the legal status...",
        },
        {
          id: "22",
          title: "Change of Registration Status",
          link:
            "https://nra.gov.ss/business/tin-registration-for-business/4/change-of-registration-status",
          intro:
            "In case of any changes in the legal status of a person, a notice of changes within 15 day should be addressed to the NRA.",
        },
        {
          id: "23",
          title: "Business profit tax",
          link:
            "https://nra.gov.ss/business/taxes-applied-to-business/1/business-profit-tax",
          intro:
            "Business profit tax is an indirect tax charged on the taxable profit (Net profit) on the percentages prescribed in Schedule 1 of the Taxation Act of 2019/2020.",
        },
        {
          id: "24",
          title: "Withholding tax",
          link:
            "https://nra.gov.ss/business/taxes-applied-to-business/2/withholding-tax",
          intro:
            "This is a method where the payer of certain incomes is responsible for deducting tax at source from payments made. The deducted tax must be remitted to the NRA...",
        },
        {
          id: "25",
          title: "Advance Tax",
          link:
            "https://nra.gov.ss/business/taxes-applied-to-business/3/advance-tax",
          intro:
            "Advance tax is an indirect tax charged based on the projected net profit of a business per quarter. This is then reconciled annually. Taxpayers shall remit an advance...",
        },
        {
          id: "26",
          title: "Excise tax",
          link:
            "https://nra.gov.ss/business/taxes-applied-to-business/4/excise-tax",
          intro:
            "Excise duty is a tax imposed on goods and services manufactured in South Sudan or imported into South Sudan. This is an indirect tax which is included in the selling ...",
        },
        {
          id: "27",
          title: "Penalty for underestimate",
          link:
            "https://nra.gov.ss/business/penalties/1/penalty-for-underestimate",
          intro:
            "In a situation where the taxpayer notified the Commissioner General of the NRA about the underestimation, the taxpayer will be liable for penalty of 5%",
        },
        {
          id: "28",
          title: "Penalty for late payment/partial payment",
          link:
            "https://nra.gov.ss/business/penalties/2/penalty-for-late-payment-partial-payment",
          intro:
            "NB: In a situation where the taxpayer notified the Commissioner General of the NRA about the underestimation, the taxpayer will be liable for penalty of 5%",
        },
        {
          id: "29",
          title: "Due dates for tax payment",
          link:
            "https://nra.gov.ss/business/penalties/3/due-dates-for-tax-payment",
          intro:
            "All tax payments are due on or before the 15th of the following month when the tax is due, with the exception of PIT and business profit tax",
        },
        {
          id: "30",
          title: "Deadlines for filing tax return and payment",
          link:
            "https://nra.gov.ss/business/penalties/4/deadlines-for-filing-tax-return-and-payment",
          intro:
            "The deadline for the filing and payment of the tax is normally based on the type of tax the taxpayer is required to pay.",
        },
        {
          id: "31",
          title: "Depreciation on Tangible Property",
          link:
            "https://nra.gov.ss/business/deduction-and-depreciation/1/depreciation-on-tangible-property",
          intro:
            "Tangible properties acquired by the business owner and used for his/her economical activities shall be recovered with...",
        },
        {
          id: "32",
          title: "Deduction of Intangible Property",
          link:
            "https://nra.gov.ss/business/deduction-and-depreciation/2/deduction-of-intangible-property",
          intro:
            "Based on section 75 intangible property which has limited life i.e. including patents, copyrights, licenses for drawings and models...",
        },
        {
          id: "33",
          title: "What is tax compliance certificate",
          link:
            "https://nra.gov.ss/business/tax-compliance-certificate/1/what-is-tax-compliance-certificate",
          intro:
            "A tax compliance certificate, otherwise known as a Tax Clearance Certificate is an official document issued by NRA, as proof of having...",
        },
        {
          id: "34",
          title: "Tax Compliance certificate Validity",
          link:
            "https://nra.gov.ss/business/tax-compliance-certificate/2/tax-compliance-certificate-validity",
          intro:
            "Tax Compliance Certificates are valid for twelve (3) months only. Application for a TCC (Tax Compliance Certificate) is done through iTax...",
        },
        {
          id: "35",
          title: "Applying for a Compliance Certificate",
          link:
            "https://nra.gov.ss/business/tax-compliance-certificate/3/applying-for-a-compliance-certificate",
          intro:
            "Taxpayers seeking Tax Compliance Certificate must be compliant in: Filing of tax returns on or before the due date for all applicable tax obligations.",
        },
        {
          id: "36",
          title: "Frequently Asked Questions(faqs)",
          link: "https://nra.gov.ss/business/frequently-asked-questions",
          intro:
            "How can I register for TIN? Registration can be done online on NRA website nra.gov.ss or at the taxation officers at NRA officers or at an authorized tax agent.",
        },
        {
          id: "37",
          title: "Investment in South Sudan",
          link:
            "https://nra.gov.ss/investors/introduction/1/investment-in-south-sudan",
          intro:
            "South Sudan has developed strong growth prospects supported by flourishing peace, rising levels of literacy and increasing opportunities for business. As the...",
        },
        {
          id: "38",
          title: "Agents",
          link: "https://nra.gov.ss/agents/introduction/1/agents",
          intro:
            "Agents are individuals or companies that prepare taxes on behalf of a taxpayer. Agents play the important role of assisting people or companies that are not...",
        },
        {
          id: "39",
          title: "About Us",
          link: "https://nra.gov.ss/about/about-us",
          intro:
            "The South Sudan Revenue Authority is the revenue service of the South Sudan Government; it is a non-oil Revenue collecting body that was established in 2016 under...",
        },
        {
          id: "40",
          title: "Leadership",
          link: "https://nra.gov.ss/about/leadership",
          intro:
            "Commissioner General Dr. Patrick Mugaya, Deputy Commissioner General Mr. Africano Mande, Executive Director of the office of the Commissioner General,Commissioner for...",
        },
        {
          id: "41",
          title: "Resources",
          link: "https://nra.gov.ss/resources",
          intro:
            "Resources from the South Sudan Revenue Authority, Laws of South Sudan: South Sudan Taxation Act 2009, Laws of South Sudan: Finance Act 2019-2020",
        },
        {
          id: "42",
          title: "Media Center-NRA News",
          link: "https://nra.gov.ss/media-center",
          intro:
            "All news at Media Center about South Sudan Revenue Authority, Truck drivers Operations, Vehicles clearance,press Conference, Tax Service Center.",
        },
        {
          id: "43",
          title: "TIN Verification-Search",
          link: "https://nra.gov.ss/tin-search",
          intro: "Search to verify a Tax Identification Number",
        },
        // {
        //     id: '44',
        //     title: '',
        //     link: '',
        //     intro: ''
        // },
        // {
        //     id: '45',
        //     title: '',
        //     link: '',
        //     intro: ''
        // },
        // {
        //     id: '46',
        //     title: '',
        //     link: '',
        //     intro: ''
        // },
        // {
        //     id: '47',
        //     title: '',
        //     link: '',
        //     intro: ''
        // },
        // {
        //     id: '48',
        //     title: '',
        //     link: '',
        //     intro: ''
        // },
        // {
        //     id: '49',
        //     title: '',
        //     link: '',
        //     intro: ''
        // },
        // {
        //     id: '50',
        //     title: '',
        //     link: '',
        //     intro: ''
        // },
      ],
      result2: [],
    };
  },
  components: {
    // Header,
  },
  mounted() {
    window.scrollTo(0, 0);
    this.message.filterval = localStorage.getItem("keyword");
  },
  created() {
    this.result2 = this.results;
  },
  methods: {
    filter() {
      let filterval = this.message.filterval;
      if (filterval === "" || filterval === null) {
        this.results = this.result2;
      } else {
        let data = this.result2.filter(function (product) {
          return (
            product.title.toLowerCase().includes(filterval.toLowerCase()) ||
            product.intro.toLowerCase().includes(filterval.toLowerCase()) ||
            product.link.toLowerCase().includes(filterval.toLowerCase())
          );
        }); //which objects do yoi want to filer with?title and intro//lipua tuone
        this.results = data;
      }
      //  return true
    },
    onChangePage(pageOfItems) {
      this.pageOfItems = pageOfItems;
    },
  },
  apollo: {
    loginRegLink: gql`
      query loginRegLink {
        loginRegLink {
          login
          register
        }
      }
    `,
  },
};
</script>

<style></style>
