<template>
  <div>
    <!-- main nav -->
    <mainNav />

    <!-- breadcumbs -->
    <nav
      class="hidden sm:flex bg-white border-b border-gray-200"
      aria-label="Breadcrumb"
    >
      <ol
        class="max-w-screen-xl w-full mx-auto p-4 flex space-x-4 sm:px-6 lg:px-8 uppercase border-l border-r"
      >
        <li class="flex">
          <div class="flex items-center">
            <router-link
              :to="{ name: 'index' }"
              class="ml-4 text-xs text-gray-500 hover:text-gray-700"
              >Home</router-link
            >
          </div>
        </li>
        <li class="flex">
          <div class="flex items-center">
            <svg
              class="flex-shrink-0 h-5 w-5 text-gray-300"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clip-rule="evenodd"
              />
            </svg>
            <router-link
              :to="{ name: '' }"
              class="ml-4 text-xs text-gray-500 hover:text-gray-700"
              >About</router-link
            >
          </div>
        </li>
        <li class="flex">
          <div class="flex items-center">
            <svg
              class="flex-shrink-0 h-5 w-5 text-gray-300"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clip-rule="evenodd"
              />
            </svg>
            <router-link
              :to="{ name: '' }"
              class="ml-4 text-xs text-gray-500 hover:text-gray-700"
              >Our Locations</router-link
            >
          </div>
        </li>
      </ol>
    </nav>
    <main class="relative">
      <div class="relative vh-70 bg-gray-900 max-w-7xl mx-auto">
        <img
          class="absolute inset-0 w-full h-full object-cover z-0"
          src="../assets/img/heroimg.jpg"
          alt="NRA"
        />
        <div class="relative">
          <div class="px-8 py-12 md:pt-16 md:pb-28 md:px-24">
            <h1
              class="text-2xl sm:text-xl ont-extrabold text-white sm:mt-1 lg:mt-1 lg:text-4xl"
            >
              <span class="sm:block">Our Locations</span>
            </h1>
          </div>
        </div>
      </div>
      <div class="bg-white">
        <div class="pb-16 sm:pb-20">
          <mobileDrop />
          <div
            class="max-w-7xl mx-auto p-8 md:p-24 sm:p-24 lg:px-24 bg-white border-t-8 border-nra-blue-500 -mt-2 relative"
          >
            <div class="grid grid-cols-3 gap-12">
              <!-- sidenav -->
              <!-- <sideNav /> -->

              <div
                class="w-auto col-span-3 md:col-span-1"
                x-data="{selected:0}"
              >
                <div
                  class="flex flex-col overflow-hidden border-b border-gray-300"
                >
                  <router-link
                    :to="{ name: 'about-us' }"
                    class="block text-xl font-extrabold text-gray-900 py-3 cursor-pointer"
                  >
                    About Us
                    <div class="relative float-right">
                      <svg
                        class="h-5 w-5 absolute top-1 right-0 transition-all duration-300 text-gray-300"
                        x-bind:style="selected == 6 ? 'transform: rotate(90deg); color: #000000;' : ''"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M9 5l7 7-7 7"
                        />
                      </svg>
                    </div>
                  </router-link>
                </div>

                <div
                  class="flex flex-col overflow-hidden border-b border-gray-300"
                >
                  <router-link
                    :to="{ name: 'leadership' }"
                    class="block text-xl font-extrabold text-gray-900 py-3 cursor-pointer"
                  >
                    Leadership
                    <div class="relative float-right">
                      <svg
                        class="h-5 w-5 absolute top-1 right-0 transition-all duration-300 text-gray-300"
                        x-bind:style="selected == 6 ? 'transform: rotate(90deg); color: #000000;' : ''"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M9 5l7 7-7 7"
                        />
                      </svg>
                    </div>
                  </router-link>
                </div>

                <div
                  class="flex flex-col overflow-hidden border-b border-gray-300"
                >
                  <router-link
                    :to="{ name: 'locations' }"
                    class="block text-xl font-extrabold text-gray-900 py-3 cursor-pointer"
                  >
                    Our Locations
                    <div class="relative float-right">
                      <svg
                        class="h-5 w-5 absolute top-1 right-0 transition-all duration-300 text-gray-700"
                        x-bind:style="selected == 6 ? 'transform: rotate(90deg); color: #000000;' : ''"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M9 5l7 7-7 7"
                        />
                      </svg>
                    </div>
                  </router-link>
                </div>
              </div>

              <!-- <div class="w-auto col-span-3 md:col-span-1" x-data="{selected:2}">
                            <div class="flex flex-col overflow-hidden border-b border-gray-300">
                                <h3 id="0" class="block text-xl font-extrabold text-gray-900 py-3 cursor-pointer" x-on:click="selected !== 0 ? selected = 0 : selected = null">
                                    About Us
                                    <div class="relative float-right">
                                        <svg class="h-5 w-5 absolute top-0 right-0 transition-all duration-300 text-gray-300" x-bind:style="selected == 0 ? 'transform: rotate(90deg); color: #000000;' : ''" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
                                        </svg>
                                    </div>
                                </h3>
                                <div class="relative overflow-hidden transition-all max-h-0 duration-300" x-ref="section1" x-bind:style="selected == 0 ? 'max-height: ' + $refs.section1.scrollHeight + 'px' : ''">
                                    <div class="py-6 ">
                                        <ul class="space-y-4">

                                            <li class="text-base truncate pb-3" v-for="ab in abouts" v-bind:key="ab.id">
                                                <router-link :to="{ path: '/about/about-us/' + ab.id + ab.slug }" :key="ab.id" class="hover:text-gray-700">
                                                    {{ ab.heading }}
                                                </router-link>
                                            </li>

                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div class="flex flex-col overflow-hidden border-b border-gray-300">
                                <h3 class="block text-xl font-extrabold text-gray-900 py-3 cursor-pointer" x-on:click.stop.prevent="selected !== 1 ? selected = 1 : selected = null">
                                    Leadership
                                    <div class="relative float-right">
                                        <svg class="h-5 w-5 absolute top-0 right-0 transition-all duration-300 text-gray-300" x-bind:style="selected == 1 ? 'transform: rotate(90deg); color: #000000;' : ''" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
                                        </svg>
                                    </div>
                                </h3>
                                <div class="relative overflow-hidden transition-all max-h-0 duration-300" x-ref="section2" x-bind:style="selected == 1 ? 'max-height: ' + $refs.section2.scrollHeight + 'px' : ''">
                                    <div class="py-6 ">
                                        <ul class="space-y-4">
                                            <li class="text-base truncate pb-3" v-for="lship in leaderships" v-bind:key="lship.id">
                                                <router-link :to="{ path: '/about/leadership/' + lship.id + lship.slug }" :key="lship.id" class="hover:text-gray-700">
                                                    {{ lship.heading }}
                                                </router-link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div class="flex flex-col overflow-hidden border-b border-gray-300">
                                <h3 class="block text-xl font-extrabold text-gray-900 py-3 cursor-pointer" x-on:click="selected !== 2 ? selected = 2 : selected = null">
                                    Our Locations
                                    <div class="relative float-right">
                                        <svg class="h-5 w-5 absolute top-0 right-0 transition-all duration-300 text-gray-300" x-bind:style="selected == 2 ? 'transform: rotate(90deg); color: #000000;' : ''" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
                                        </svg>
                                    </div>
                                </h3>
                                <div class="relative overflow-hidden transition-all max-h-0 duration-300" x-ref="section3" x-bind:style="selected == 2 ? 'max-height: ' + $refs.section3.scrollHeight + '200px' : ''">
                                    <div class="py-6 ">
                                        <ul class="space-y-4">
                                            <li class="text-base truncate pb-3" v-for="our in ourLocations" v-bind:key="our.id">
                                                <router-link :to="{ path: '/about/headquarters/' + our.id + our.slug }" :key="our.id" class="hover:text-gray-700">
                                                    {{ our.heading }}
                                                </router-link>
                                               
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                        </div> -->

              <!-- ... -->
              <div class="w-auto col-span-3 md:col-span-2">
                <div class="border-b border-gray-300 pb-6 mb-6">
                  <p
                    id="0"
                    class="border-l-4 border-nra-cyan-500 pl-1 font-bold text-xs"
                  >
                    SECTION 1/4
                  </p>
                  <h2
                    class="text-3xl font-extrabold text-gray-900 sm:text-4xl mt-2"
                  >
                    Our Locations
                  </h2>
                </div>

                <!-- <cta /> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import gql from "graphql-tag";
// import sideNav from './nav/sideNav'
import mainNav from "./nav/mainNav";
import mobileDrop from "./nav/mobileDrop";
// import cta from '../components/callToAction'

export default {
  data() {
    return {
      ourLocation: [],
      abouts: [],
      leaderships: [],
      ourLocations: [],
      api_url: process.env.VUE_APP_STRAPI_API_URL,
      routeParam: this.$route.params.id,
    };
  },
  components: {
    // sideNav,
    mainNav,
    mobileDrop,
    // cta
  },
  mounted() {
    window.scrollTo(0, 0);
  },

  apollo: {
    // ourLocation: {
    //   query: gql`
    //     query ourLocation($id: ID!) {
    //       ourLocation(id: $id) {
    //         id
    //         title
    //         heading
    //         intro
    //       }
    //     }
    //   `,

    //   variables() {
    //     return {
    //       id: this.routeParam,
    //     };
    //   },
    // },
    abouts: gql`
      query abouts {
        abouts {
          id
          title
          heading
          intro
          slug
        }
      }
    `,
    leaderships: gql`
      query leaderships {
        leaderships {
          id
          title
          heading
          intro
          slug
        }
      }
    `,
    ourLocations: gql`
      query ourLocations {
        ourLocations {
          id
          title
          heading
          intro
          slug
        }
      }
    `,
  },
};
</script>
