<template>
  <div>
    <!-- Main NAV -->
    <MainNav />

    <!-- End Head -->

    <nav
      class="hidden sm:flex bg-white border-b border-gray-200"
      aria-label="Breadcrumb"
    >
      <ol
        class="max-w-screen-xl w-full mx-auto p-4 flex space-x-4 sm:px-6 lg:px-8 uppercase border-l border-r"
      >
        <li class="flex">
          <div class="flex items-center">
            <router-link
              :to="{ name: 'index' }"
              class="ml-4 text-xs text-gray-500 hover:text-gray-700"
              >Home</router-link
            >
          </div>
        </li>
        <li class="flex">
          <div class="flex items-center">
            <svg
              class="flex-shrink-0 h-5 w-5 text-gray-300"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clip-rule="evenodd"
              />
            </svg>
            <router-link
              :to="{ name: '' }"
              class="ml-4 text-xs text-gray-500 hover:text-gray-700"
              >Business</router-link
            >
          </div>
        </li>
        <li class="flex">
          <div class="flex items-center">
            <svg
              class="flex-shrink-0 h-5 w-5 text-gray-300"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clip-rule="evenodd"
              />
            </svg>
            <router-link
              :to="{ name: '' }"
              class="ml-4 text-xs text-gray-500 hover:text-gray-700"
              >Introduction</router-link
            >
          </div>
        </li>
      </ol>
    </nav>
    <main class="relative">
      <div class="relative vh-70 bg-gray-900 max-w-7xl mx-auto">
        <!-- <img
          class="absolute inset-0 w-full h-full object-cover z-0"
          src="../assets/img/hero-business.jpg"
          alt="NRA"
        /> -->
        <img
          v-if="headerImage.business"
          class="absolute inset-0 w-full h-full object-cover z-0"
          :src="api_url + headerImage.business.url"
          alt="NRA"
        />
        <div class="relative">
          <div class="px-8 py-12 md:pt-16 md:pb-28 md:px-24">
            <p
              class="mt-3 uppercase text-xs sm:text-base text-nra-yellow-500 sm:mt-5"
            >
              TAX INFORMATION
            </p>
            <h1
              class="text-2xl sm:text-xl ont-extrabold text-white sm:mt-1 lg:mt-1 lg:text-4xl"
            >
              <!-- <span class="sm:block">{{ businessIntroduction.heading }}</span> -->
              <span class="sm:block">Businesses and Organizations</span>
            </h1>
          </div>
        </div>
      </div>
      <div class="bg-white">
        <div class="pb-16 sm:pb-20">
          <mobileDrop />
          <div
            class="max-w-7xl mx-auto p-8 md:p-24 sm:p-24 lg:px-24 bg-white border-t-8 border-nra-blue-500 -mt-2 relative"
          >
            <div class="grid grid-cols-3 gap-12">
              <!-- sidenav -->
              <sideNav />

              <!-- ... -->
              <div
                v-if="businessIntroduction.body"
                class="w-auto col-span-3 md:col-span-2"
              >
                <div class="border-b border-gray-300 pb-6 mb-6">
                  <p
                    id="0"
                    class="border-l-4 border-nra-cyan-500 pl-1 font-bold text-xs"
                  >
                    SECTION 1/8
                  </p>
                  <h2
                    class="text-3xl font-extrabold text-gray-900 sm:text-4xl mt-2"
                  >
                    {{ businessIntroduction.title }}
                  </h2>
                </div>

                <h2 class="text-xl font-extrabold text-gray-900">
                  {{ businessIntroduction.heading }}
                </h2>
                <p class="mt-4 text-lg text-gray-800">
                  {{ businessIntroduction.intro }}
                </p>

                <ul class="list-disc mt-4 text-lg text-gray-800 pl-6">
                  <li v-if="businessIntroduction.list.item1">
                    {{ businessIntroduction.list.item1 }}
                  </li>
                  <li v-if="businessIntroduction.list.item2">
                    {{ businessIntroduction.list.item2 }}
                  </li>
                  <li v-if="businessIntroduction.list.item3">
                    {{ businessIntroduction.list.item3 }}
                  </li>
                  <li v-if="businessIntroduction.list.item4">
                    {{ businessIntroduction.list.item4 }}
                  </li>
                  <li v-if="businessIntroduction.list.item5">
                    {{ businessIntroduction.list.item5 }}
                  </li>
                  <li v-if="businessIntroduction.list.item6">
                    {{ businessIntroduction.list.item6 }}
                  </li>
                  <li v-if="businessIntroduction.list.item7">
                    {{ businessIntroduction.list.item7 }}
                  </li>
                </ul>

                <h3 class="text-xl font-semibold text-gray-900">
                  {{ businessIntroduction.body.item1 }}
                </h3>
                <p class="mt-4 text-lg text-gray-800">
                  {{ businessIntroduction.body.defn1 }}
                </p>
                <br />
                <h3 class="text-xl font-semibold text-gray-900">
                  {{ businessIntroduction.body.item2 }}
                </h3>
                <p class="mt-4 text-lg text-gray-800">
                  {{ businessIntroduction.body.defn2 }}
                </p>

                <cta />
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import gql from "graphql-tag";
import sideNav from "./nav/sideNav";
import mainNav from "./nav/mainNav";
import MainNav from "@/components/MainNav.vue"

import cta from "../components/callToAction";
import mobileDrop from "./nav/mobileDrop";

export default {
  data() {
    return {
      businessIntroduction: [],
      headerImage: [],
      api_url: process.env.VUE_APP_STRAPI_API_URL,
      routeParam: this.$route.params.id,
    };
  },
  components: {
    sideNav,
    mainNav,
    cta,
    mobileDrop,
    MainNav
  },
  mounted() {
    window.scrollTo(0, 0);
  },

  apollo: {
    businessIntroduction: {
      query: gql`
        query businessIntroduction($id: ID!) {
          businessIntroduction(id: $id) {
            id
            title
            heading
            intro
            body {
              item1
              defn1
              item2
              defn2
            }
            list {
              item1
              item2
              item3
              item4
              item5
              item6
              item7
            }
          }
        }
      `,
      variables() {
        return {
          id: this.routeParam,
        };
      },
    },
    headerImage: gql`
      query headerImage {
        headerImage {
          business {
            url
          }
        }
      }
    `,
  },
};
</script>
