<template>
  <div>
    <!-- main nav -->
    <mainNav />

    <!-- breadcumbs -->
    <nav
      class="hidden sm:flex bg-white border-b border-gray-200"
      aria-label="Breadcrumb"
    >
      <ol class="max-w-screen-xl w-full mx-auto p-4 flex space-x-4 sm:px-6 lg:px-8 uppercase border-l border-r">
        <li class="flex">
          <div class="flex items-center">
            <router-link
              :to="{ name: 'index' }"
              class="ml-4 text-xs text-gray-500 hover:text-gray-700"
            >Home</router-link>
          </div>
        </li>
        <li class="flex">
          <div class="flex items-center">
            <svg
              class="flex-shrink-0 h-5 w-5 text-gray-300"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clip-rule="evenodd"
              />
            </svg>
            <router-link
              :to="{ name: '' }"
              class="ml-4 text-xs text-gray-500 hover:text-gray-700"
            >About</router-link>
          </div>
        </li>
        <li class="flex">
          <div class="flex items-center">
            <svg
              class="flex-shrink-0 h-5 w-5 text-gray-300"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clip-rule="evenodd"
              />
            </svg>
            <router-link
              :to="{ name: '' }"
              class="ml-4 text-xs text-gray-500 hover:text-gray-700"
            >Leadership</router-link>
          </div>
        </li>
      </ol>
    </nav>
    <main class="relative">
      <div class="relative vh-70 bg-gray-900 max-w-7xl mx-auto">
        <!-- <img
          class="absolute inset-0 w-full h-full object-cover z-0"
          src="../assets/img/heroimg.jpg"
          alt="NRA"
        /> -->

        <img
          v-if="headerImage.about"
          class="absolute inset-0 w-full h-full object-cover z-0"
          :src="api_url + headerImage.about.url"
          alt="NRA"
        />
        <div class="relative">
          <div class="px-8 py-12 md:pt-16 md:pb-28 md:px-24">
            <h1 class="text-2xl sm:text-xl ont-extrabold text-white sm:mt-1 lg:mt-1 lg:text-4xl">
              <span class="sm:block">{{ leadershp.title }}</span>
            </h1>
          </div>
        </div>
      </div>
      <div class="bg-white">
        <div class="pb-16 sm:pb-20">
          <mobileDrop />
          <div class="max-w-7xl mx-auto p-8 md:p-24 sm:p-24 lg:px-24 bg-white border-t-8 border-nra-blue-500 -mt-2 relative">
            <div class="grid grid-cols-3 gap-12">
              <!-- sidenav -->
              <!-- <sideNav /> -->

              <div
                class="w-auto col-span-3 md:col-span-1 hidden md:block"
                x-data="{selected:0}"
              >
                <div class="flex flex-col overflow-hidden border-b border-gray-300">
                  <router-link
                    :to="{ name: 'about-us' }"
                    class="block text-xl font-extrabold text-gray-900 py-3 cursor-pointer"
                  >
                    About Us
                    <div class="relative float-right">
                      <svg
                        class="h-5 w-5 absolute top-1 right-0 transition-all duration-300 text-gray-300"
                        x-bind:style="selected == 6 ? 'transform: rotate(90deg); color: #000000;' : ''"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M9 5l7 7-7 7"
                        />
                      </svg>
                    </div>
                  </router-link>
                </div>

                <div class="flex flex-col overflow-hidden border-b border-gray-300">
                  <router-link
                    :to="{ name: 'leadership' }"
                    class="block text-xl font-extrabold text-gray-900 py-3 cursor-pointer"
                  >
                    Leadership
                    <div class="relative float-right">
                      <svg
                        class="h-5 w-5 absolute top-1 right-0 transition-all duration-300 text-gray-700"
                        x-bind:style="selected == 6 ? 'transform: rotate(90deg); color: #000000;' : ''"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M9 5l7 7-7 7"
                        />
                      </svg>
                    </div>
                  </router-link>
                </div>

                <div class="flex flex-col overflow-hidden border-b border-gray-300">
                  <a
                    href="#"
                    class="block text-xl font-extrabold text-gray-900 py-3 cursor-pointer"
                  >
                    Our Locations
                    <div class="relative float-right">
                      <svg
                        class="h-5 w-5 absolute top-1 right-0 transition-all duration-300 text-gray-300"
                        x-bind:style="selected == 6 ? 'transform: rotate(90deg); color: #000000;' : ''"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M9 5l7 7-7 7"
                        />
                      </svg>
                    </div>
                  </a>
                </div>
              </div>

              <!-- ... -->
              <div
                v-if="leadershp.photo"
                class="w-auto col-span-3 md:col-span-2"
              >
                <div class="border-b border-gray-300 pb-6 mb-6">
                  <p
                    id="0"
                    class="border-l-4 border-nra-cyan-500 pl-1 font-bold text-xs"
                  >
                    SECTION 1/4
                  </p>
                  <h2 class="text-3xl font-extrabold text-gray-900 sm:text-4xl mt-2">
                    {{ leadershp.title }}
                  </h2>
                </div>

                <div
                  v-if="leadershp.photo"
                  class="mb-16 border-b border-gray-300 pb-6"
                >
                  <div class="w-44 h-44 bg-gray-300 mb-6">
                    <!-- <img class="w-full h-auto" src="../assets/img/Dr-Patrick-Mugaya.jpg" alt="Dr. Patrick Mugaya" /> -->
                    <img
                      class="w-full h-auto"
                      :src="api_url + leadershp.photo.url"
                      alt=""
                    />
                  </div>
                  <p class="mt-4 text-lg text-gray-800 font-bold">
                    {{ leadershp.par1 }}
                  </p>
                  <p class="mt-1 text-lg text-gray-800 font-semibold">
                    {{ leadershp.par2 }}
                  </p>
                  <p
                    v-html="leadershp.textarea1"
                    class="mt-6 text-gray-800"
                  ></p>
                </div>

                <div
                  v-if="leadershp.photo2"
                  class="mb-16 border-b border-gray-300 pb-6"
                >
                  <div class="w-44 h-44 bg-gray-300 mb-6">
                    <!-- <img class="w-full h-auto" src="../assets/img/Mr-Africano-Mande.jpg" alt="Mr.Africano Mande" /> -->
                    <img
                      class="w-full h-auto"
                      :src="api_url + leadershp.photo2.url"
                      alt=""
                    />
                  </div>
                  <p class="mt-4 text-lg text-gray-800 font-bold">
                    {{ leadershp.par7 }}
                  </p>
                  <p class="mt-1 text-lg text-gray-800 font-semibold">
                    {{ leadershp.par8 }}
                  </p>
                  <p
                    v-html="leadershp.textarea2"
                    class="mt-6 text-gray-800"
                  ></p>
                </div>

                <div
                  v-if="leadershp.photo3"
                  class="mb-16 border-b border-gray-300 pb-6"
                >
                  <div class="w-44 h-44 bg-gray-300 mb-6">
                    <!-- <img class="w-full h-auto" src="" alt="" /> -->
                    <img
                      class="w-full h-auto"
                      :src="api_url + leadershp.photo3.url"
                      alt=""
                    />
                  </div>
                  <p class="mt-4 text-lg text-gray-800 font-bold">
                    {{ leadershp.par9 }}
                  </p>
                  <p class="mt-1 text-lg text-gray-800 font-semibold">
                    {{ leadershp.par10 }}
                  </p>

                  <p
                    v-html="leadershp.textarea3"
                    class="mt-6 text-gray-800"
                  ></p>
                </div>

                <div
                  v-if="leadershp.photo4"
                  class="mb-16 border-b border-gray-300 pb-6"
                >
                  <div class="w-44 h-44 bg-gray-300 mb-6">
                    <img
                      class="w-full h-auto"
                      :src="api_url + leadershp.photo4.url"
                      alt=""
                    />
                  </div>
                  <p class="mt-4 text-lg text-gray-800 font-bold">
                    {{ leadershp.par11 }}
                  </p>
                  <p class="mt-1 text-lg text-gray-800 font-semibold">
                    {{ leadershp.par12 }}
                  </p>

                  <p
                    v-html="leadershp.textarea4"
                    class="mt-6 text-gray-800"
                  ></p>

                </div>

                <div
                  v-if="leadershp.photo5"
                  class="mb-16 border-b border-gray-300 pb-6"
                >
                  <div class="w-44 h-44 bg-gray-300 mb-6">
                    <img
                      class="w-full h-auto"
                      :src="api_url + leadershp.photo5.url"
                      alt=""
                    />
                  </div>
                  <p class="mt-4 text-lg text-gray-800 font-bold">
                    {{ leadershp.par13 }}
                  </p>
                  <p class="mt-1 text-lg text-gray-800 font-semibold">
                    {{ leadershp.par14 }}
                  </p>

                  <p
                    v-html="leadershp.textarea5"
                    class="mt-6 text-gray-800"
                  ></p>

                </div>

                <div
                  v-if="leadershp.photo6"
                  class="mb-16 border-b border-gray-300 pb-6"
                >
                  <div class="w-44 h-44 bg-gray-300 mb-6">
                    <img
                      class="w-full h-auto"
                      :src="api_url + leadershp.photo6.url"
                      alt=""
                    />
                  </div>
                  <p class="mt-4 text-lg text-gray-800 font-bold">
                    {{ leadershp.par21 }}
                  </p>
                  <p class="mt-1 text-lg text-gray-800 font-semibold">
                    {{ leadershp.par16 }}
                  </p>

                  <p
                    v-html="leadershp.textarea6"
                    class="mt-6 text-gray-800"
                  ></p>

                </div>

                <div
                  v-if="leadershp.photo7"
                  class="mb-16 border-b border-gray-300 pb-6"
                >
                  <div class="w-44 h-44 bg-gray-300 mb-6">
                    <img
                      class="w-full h-auto"
                      :src="api_url + leadershp.photo7.url"
                      alt=""
                    />
                  </div>
                  <p class="mt-4 text-lg text-gray-800 font-bold">
                    {{ leadershp.par17 }}
                  </p>
                  <p class="mt-1 text-lg text-gray-800 font-semibold">
                    {{ leadershp.par18 }}
                  </p>

                  <p
                    v-html="leadershp.textarea7"
                    class="mt-6 text-gray-800"
                  ></p>

                </div>

                <div
                  v-if="leadershp.photo8"
                  class="mb-16 border-b border-gray-300 pb-6"
                >
                  <div class="w-44 h-44 bg-gray-300 mb-6">
                    <img
                      class="w-full h-auto"
                      :src="api_url + leadershp.photo8.url"
                      alt=""
                    />
                  </div>
                  <p class="mt-4 text-lg text-gray-800 font-bold">
                    {{ leadershp.par19 }}
                  </p>
                  <p class="mt-1 text-lg text-gray-800 font-semibold">
                    {{ leadershp.par20 }}
                  </p>

                  <p
                    v-html="leadershp.textarea8"
                    class="mt-6 text-gray-800"
                  ></p>

                </div>

                <!-- <cta /> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import gql from "graphql-tag";
// import sideNav from './nav/sideNav'
import mainNav from "./nav/mainNav";
import mobileDrop from "./nav/mobileDrop";
// import cta from '../components/callToAction'

export default {
  data() {
    return {
      leadershp: [],
      abouts: [],
      headerImage: [],
      // leaderships: [],
      ourLocations: [],
      api_url: process.env.VUE_APP_STRAPI_API_URL,
      routeParam: this.$route.params.id,
    };
  },
  components: {
    // sideNav,
    mainNav,
    mobileDrop,
    // cta
  },
  mounted() {
    window.scrollTo(0, 0);
  },

  apollo: {
    leadershp: gql`
      query leadershp {
        leadershp {
          id
          title
          photo {
            url
          }
          par1
          par2
          photo2 {
            url
          }
          par7
          par8
          photo3 {
            url
          }
          par9
          par10
          photo4 {
            url
          }
          photo5 {
            url
          }
          photo6 {
            url
          }
          photo7 {
            url
          }
          photo8 {
            url
          }
          par11
          par12
          par13
          par14
          par21
          par16
          par17
          par18
          par19
          par20

          textarea1
          textarea2
          textarea3
          textarea4
          textarea5
          textarea6
          textarea7
          textarea8
        }
      }
    `,
    headerImage: gql`
      query headerImage {
        headerImage {
          about {
            url
          }
        }
      }
    `,
  },
};
</script>
