<template>
<div>
    <!-- Main NAV -->

    <scroll-fixed-header :fixed.sync="fixed" :threshold="163">
        <nav class="hidden sm:block">
            <div class="max-w-7xl mx-auto bg-gray-50">
                <div class="relative">
                    <div class="flex border divide-x divide-gray-200">
                        <div style="padding: 7px" v-if="fixed == true" class="flex items-center justify-center">
                            <img class="w-auto h-9 mr-2" src="../assets/img/SSRA.png" alt="South Sudan Revenue Authority" />
                            <h1 class="uppercase font-bold text-3xl">NRA</h1>
                        </div>

                        <router-link :to="{ name: 'index' }" class="px-8 text-sm text-gray-500 outline-none focus:outline-none py-4 inline-flex items-center uppercase">
                            <span>Home</span>
                        </router-link>

                        <router-link :to="{
                  name: 'indIntro',
                  params: { id: '1', slug: 'individual-taxpayer' },
                }" class="px-8 text-sm text-gray-500 outline-none focus:outline-none py-4 inline-flex items-center uppercase">
                            <span>Individual</span>
                        </router-link>

                        <router-link :to="{
                  name: 'bsnIntro',
                  params: { id: '1', slug: 'what-is-considered-a-business' },
                }" class="px-8 text-sm text-gray-500 outline-none focus:outline-none py-4 inline-flex items-center uppercase">
                            <span>Business</span>
                        </router-link>

                        <router-link :to="{
                  name: 'invIntro',
                  params: { id: '1', slug: 'investment-in-south-sudan' },
                }" class="px-8 text-sm text-gray-500 outline-none focus:outline-none py-4 inline-flex items-center uppercase">
                            <span>Investors</span>
                        </router-link>

                        <router-link :to="{
                  name: 'agentsIntro',
                  params: { id: '1', slug: 'agents' },
                }" class="px-8 text-sm text-gray-500 outline-none focus:outline-none py-4 inline-flex items-center uppercase">
                            <span>Agents</span>
                        </router-link>

                        <div style="padding: 12px" v-if="fixed == true" class="hidden sm:flex items-center justify-end sm:flex-1 lg:w-0">
                            <div v-if="loginRegLink.login" class="sm:flex items-center justify-end space-x-4 sm:flex-1 lg:w-0">
                                <a v-bind:href="loginRegLink.login" target="_blank" class="btn btn-blue">
                                    LOG IN
                                </a>
                                <a v-bind:href="loginRegLink.register" target="_blank" class="btn btn-orange">
                                    <svg class="h-5 w-5 text-gray-900 mr-2 -ml-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 11V7a4 4 0 118 0m-4 8v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2z" />
                                    </svg>
                                    <span>REGISTER</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    </scroll-fixed-header>

    <!-- End Head -->

    <main class="relative md:mt-12">
        <div class="max-w-7xl mx-auto bg-nra-blue-500 text-white">
            <div class="md:flex" v-for="news in newsUpdates.slice(-1)" v-bind:key="news.id">
                <div class="sm:w-full md:w-1/2">
                    <router-link :to="{
                name: 'mediaStory',
                params: { id: news.id },
              }" :key="news.id" class="w-full">
                        <!-- <img class="w-full" src="../assets/img/hero-media.png"/> -->
                        <img class="w-full" :src="api_url + news.image.url" alt="" />
                    </router-link>
                </div>
                <div class="sm:w-full md:w-1/2 relative">
                    <p class="border-l-4 m-8 md:m-14 pl-1 border-nra-cyan-500 font-bold text-xs">
                        <!-- STORY TAG -->
                    </p>
                    <div class="md:absolute md:bottom-0 md:left-0 m-8 md:m-14 pb-8 md:pb-0">
                        <router-link :to="{
                  name: 'mediaStory',
                  params: { id: news.id },
                }" :key="news.id" class="text-3xl font-extrabold sm:text-4xl mt-2">{{ news.body }}</router-link>
                        <!-- <p class="mt-4 text-xl">
                                        Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                                        accusantium doloremque laudantium, totam rem.
                                      </p> -->
                        <p class="mt-6">
                            <span class="text-black mx-1"></span>{{ news.date }}
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div class="mt-14 mb-12 max-w-6xl mx-auto" v-for="news in pageOfItems.slice(0,-1).reverse()" v-bind:key="news.id">
            <div class="mb-10 md:flex">
                <!-- <iframe
            class="inline-block w-full h-56 md:h-auto md:w-1/4 bg-gray-200 mr-10"
            v-bind:src="news.videoUrl"
          >
          </iframe> -->

                <router-link :to="{
              name: 'mediaStory',
              params: { id: news.id },
            }" :key="news.id">
                    <img v-if="news.image.url" :src="api_url + news.image.url" alt="" />
                </router-link>
                <div class="w-full md:w-3/4 p-5 border-b border-gray-300">
                    <div class="w-full md:w-8/12">
                        <!-- <p class="border-l-4 pl-1 border-nra-cyan-500 font-bold text-xs">
                STORY
              </p> -->
                        <router-link :to="{
                  name: 'mediaStory',
                  params: { id: news.id },
                }" :key="news.id" class="inline-block text-2xl font-extrabold sm:text-3xl mt-2">{{ news.body }}</router-link>
                        <!-- <p class="mt-4 text-gray-700">{{ news.body }}</p> -->
                        <p class="mt-6 text-xs text-gray-500">
                            <span class="text-black mx-1"></span>
                            {{ news.date }}
                        </p>
                    </div>
                </div>
            </div>  
        </div>
        <center>
            <div class="row justify-content-center">
                <div class="col-sm-12">
                    <jw-pagination :pageSize="6" :items="newsUpdates" @changePage="onChangePage"></jw-pagination>
                </div>
            </div>
        </center>  
        <!-- <div class="mt-14 mb-12 max-w-3xl mx-auto">
        This example requires Tailwind CSS v2.0+ //
        <nav class="px-4 flex items-center justify-between sm:px-0">
          <div class="-mt-px w-0 flex-1 flex">
            <a
              href="#" 
              class="pt-4 pr-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-nra-blue-300"
            >
              Heroicon name: solid/arrow-narrow-left //
              <svg
                class="mr-3 h-5 w-5 text-gray-400"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                  clip-rule="evenodd"
                />
              </svg>
              Previous
            </a>
          </div>
          <div class="hidden md:-mt-px md:flex">
            <a
              href="#"
              class="text-gray-500 hover:text-gray-700 pt-4 px-4 inline-flex items-center text-sm font-medium"
            >
              1
            </a>
            Current //
            <
              href="#"
              class="text-nra-blue-600 pt-4 px-4 inline-flex items-center text-sm font-medium"
              aria-current="page"
            >
              2
            </>
            <a
              href="#"
              class="text-gray-500 hover:text-nra-blue-700 pt-4 px-4 inline-flex items-center text-sm font-medium"
            >
              3
            </a>
            <span
              class="text-gray-500 pt-4 px-4 inline-flex items-center text-sm font-medium"
            >
              ...
            </span>
            <a
              href="#"
              class="text-gray-500 hover:text-nra-blue-700 pt-4 px-4 inline-flex items-center text-sm font-medium"
            >
              8
            </a>
            <a
              href="#"
              class="text-gray-500 hover:text-nra-blue-700 pt-4 px-4 inline-flex items-center text-sm font-medium"
            >
              9
            </a>
            <a
              href="#"
              class="text-gray-500 hover:text-nra-blue-700 pt-4 px-4 inline-flex items-center text-sm font-medium"
            >
              10
            </a>
          </div>
          <div class="-mt-px w-0 flex-1 flex justify-end">
            <a
              href="#"
              class="pt-4 pl-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-nra-blue-700"
            >
              Next
              Heroicon name: solid/arrow-narrow-right //
              <svg
                class="ml-3 h-5 w-5 text-gray-400"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                />
              </svg>
            </a>
          </div>
        </nav>
      </div> -->
    </main>
</div>
</template>

<script>
import gql from "graphql-tag";

export default {
    data() {
        return {
            fixed: false,
            newsUpdates: [],
            pageOfItems: [],
            loginRegLink: [],
            headerImage: [],
            api_url: process.env.VUE_APP_STRAPI_API_URL,
            routeParam: this.$route.params.id,
        };
    },
    components: {
        // Header,
    },
    mounted() {
        window.scrollTo(0, 0);
    },
    methods: {
        onChangePage(pageOfItems) {
            //Thisnis a method, do finf how to put it
            // update page of items
            this.pageOfItems = pageOfItems;
        },
    },

    apollo: {
        newsUpdates: gql `
      query {
        newsUpdates {
          id
          date
          body
          videoUrl
          youtubeUrl
          image {
            url
          }
        }
      }
    `,
        loginRegLink: gql `
      query loginRegLink {
        loginRegLink {
          login
          register
        }
      }
    `,
        headerImage: gql `
      query headerImage {
        headerImage {
          mediaCenter {
            url
          }
        }
      }
    `,
    },
};
</script>

<style></style>
