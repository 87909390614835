<template>
<!-- Call to action -->
<div class="relative rounded-lg overflow-hidden vh-60 w-full mx-auto bg-blue-600 mt-20">
    <img class="absolute right-0 h-full w-auto z-0" src="../assets/img/cta-img.jpg" />
    <div class="relative bg-gradient-to-r via-blue-600 from-blue-600">
        <div class="w-full md:w-2/3 px-10 py-10 md:py-20 md:px-20">
            <p class="text-3xl font-bold text-white sm:text-4xl mb-4">Let’s go digital</p>
            <p class="text-white mb-4">Technology is transforming how the NRA conducts business. Enjoy the
                ease and convenience. Register for your TIN today!</p>
            <a v-bind:href="loginRegLink.register" target="_blank" class="btn btn-orange">
                <svg class="h-5 w-5 text-gray-900 mr-2 -ml-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 11V7a4 4 0 118 0m-4 8v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2z" />
                </svg>
                <span>REGISTER</span>
            </a>
        </div>
    </div>
</div>
</template>

<script>
import gql from "graphql-tag";
export default {
  data() {
    return {
      loginRegLink: [],
    };
  },

  apollo: {
    loginRegLink: gql`
      query {
        loginRegLink {
          login
          register
        }
      }
    `,
  },
};
</script>

