var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('scroll-fixed-header',{attrs:{"fixed":_vm.fixed,"threshold":163},on:{"update:fixed":function($event){_vm.fixed=$event}}},[_c('nav',{staticClass:"hidden sm:block"},[_c('div',{staticClass:"max-w-7xl mx-auto bg-gray-50"},[_c('div',{staticClass:"relative"},[_c('div',{staticClass:"flex border divide-x divide-gray-200"},[(_vm.fixed == true)?_c('div',{staticClass:"flex items-center justify-center",staticStyle:{"padding":"7px"}},[_c('img',{staticClass:"w-auto h-9 mr-2",attrs:{"src":require("../assets/img/SSRA.png"),"alt":"South Sudan Revenue Authority"}}),_c('h1',{staticClass:"uppercase font-bold text-3xl"},[_vm._v("NRA")])]):_vm._e(),_c('router-link',{staticClass:"px-8 text-sm text-gray-500 outline-none focus:outline-none py-4 inline-flex items-center uppercase",attrs:{"to":{ name: 'index' }}},[_c('span',[_vm._v("Home")])]),_c('router-link',{staticClass:"px-8 text-sm text-gray-500 outline-none focus:outline-none py-4 inline-flex items-center uppercase",attrs:{"to":{
                  name: 'indIntro',
                  params: { id: '1', slug: 'individual-taxpayer' },
                }}},[_c('span',[_vm._v("Individual")])]),_c('router-link',{staticClass:"px-8 text-sm text-gray-500 outline-none focus:outline-none py-4 inline-flex items-center uppercase",attrs:{"to":{
                  name: 'bsnIntro',
                  params: { id: '1', slug: 'what-is-considered-a-business' },
                }}},[_c('span',[_vm._v("Business")])]),_c('router-link',{staticClass:"px-8 text-sm text-gray-500 outline-none focus:outline-none py-4 inline-flex items-center uppercase",attrs:{"to":{
                  name: 'invIntro',
                  params: { id: '1', slug: 'investment-in-south-sudan' },
                }}},[_c('span',[_vm._v("Investors")])]),_c('router-link',{staticClass:"px-8 text-sm text-gray-500 outline-none focus:outline-none py-4 inline-flex items-center uppercase",attrs:{"to":{
                  name: 'agentsIntro',
                  params: { id: '1', slug: 'agents' },
                }}},[_c('span',[_vm._v("Agents")])]),(_vm.fixed == true)?_c('div',{staticClass:"hidden sm:flex items-center justify-end sm:flex-1 lg:w-0",staticStyle:{"padding":"12px"}},[(_vm.loginRegLink.login)?_c('div',{staticClass:"sm:flex items-center justify-end space-x-4 sm:flex-1 lg:w-0"},[_c('a',{staticClass:"btn btn-blue",attrs:{"href":_vm.loginRegLink.login,"target":"_blank"}},[_vm._v(" LOG IN ")]),_c('a',{staticClass:"btn btn-orange",attrs:{"href":_vm.loginRegLink.register,"target":"_blank"}},[_c('svg',{staticClass:"h-5 w-5 text-gray-900 mr-2 -ml-3",attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","stroke":"currentColor"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M8 11V7a4 4 0 118 0m-4 8v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2z"}})]),_c('span',[_vm._v("REGISTER")])])]):_vm._e()]):_vm._e()],1)])])])]),_c('main',{staticClass:"relative md:mt-12"},[_c('div',{staticClass:"max-w-7xl mx-auto bg-nra-blue-500 text-white"},_vm._l((_vm.newsUpdates.slice(-1)),function(news){return _c('div',{key:news.id,staticClass:"md:flex"},[_c('div',{staticClass:"sm:w-full md:w-1/2"},[_c('router-link',{key:news.id,staticClass:"w-full",attrs:{"to":{
                name: 'mediaStory',
                params: { id: news.id },
              }}},[_c('img',{staticClass:"w-full",attrs:{"src":_vm.api_url + news.image.url,"alt":""}})])],1),_c('div',{staticClass:"sm:w-full md:w-1/2 relative"},[_c('p',{staticClass:"border-l-4 m-8 md:m-14 pl-1 border-nra-cyan-500 font-bold text-xs"}),_c('div',{staticClass:"md:absolute md:bottom-0 md:left-0 m-8 md:m-14 pb-8 md:pb-0"},[_c('router-link',{key:news.id,staticClass:"text-3xl font-extrabold sm:text-4xl mt-2",attrs:{"to":{
                  name: 'mediaStory',
                  params: { id: news.id },
                }}},[_vm._v(_vm._s(news.body))]),_c('p',{staticClass:"mt-6"},[_c('span',{staticClass:"text-black mx-1"}),_vm._v(_vm._s(news.date)+" ")])],1)])])}),0),_vm._l((_vm.pageOfItems.slice(0,-1).reverse()),function(news){return _c('div',{key:news.id,staticClass:"mt-14 mb-12 max-w-6xl mx-auto"},[_c('div',{staticClass:"mb-10 md:flex"},[_c('router-link',{key:news.id,attrs:{"to":{
              name: 'mediaStory',
              params: { id: news.id },
            }}},[(news.image.url)?_c('img',{attrs:{"src":_vm.api_url + news.image.url,"alt":""}}):_vm._e()]),_c('div',{staticClass:"w-full md:w-3/4 p-5 border-b border-gray-300"},[_c('div',{staticClass:"w-full md:w-8/12"},[_c('router-link',{key:news.id,staticClass:"inline-block text-2xl font-extrabold sm:text-3xl mt-2",attrs:{"to":{
                  name: 'mediaStory',
                  params: { id: news.id },
                }}},[_vm._v(_vm._s(news.body))]),_c('p',{staticClass:"mt-6 text-xs text-gray-500"},[_c('span',{staticClass:"text-black mx-1"}),_vm._v(" "+_vm._s(news.date)+" ")])],1)])],1)])}),_c('center',[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-sm-12"},[_c('jw-pagination',{attrs:{"pageSize":6,"items":_vm.newsUpdates},on:{"changePage":_vm.onChangePage}})],1)])])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }