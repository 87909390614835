<template>
  <div>
    <!-- Main nav -->
    <MainNav />

    <!-- Breadcumb -->
    <nav
      class="hidden sm:flex bg-white border-b border-gray-200"
      aria-label="Breadcrumb"
    >
      <ol
        class="max-w-screen-xl w-full mx-auto p-4 flex space-x-4 sm:px-6 lg:px-8 uppercase border-l border-r"
      >
        <li class="flex">
          <div class="flex items-center">
            <router-link
              :to="{ name: 'index' }"
              class="ml-4 text-xs text-gray-500 hover:text-gray-700"
              >Home</router-link
            >
          </div>
        </li>
        <li class="flex">
          <div class="flex items-center">
            <svg
              class="flex-shrink-0 h-5 w-5 text-gray-300"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clip-rule="evenodd"
              />
            </svg>
            <router-link
              :to="{ name: '' }"
              class="ml-4 text-xs text-gray-500 hover:text-gray-700"
              >Business</router-link
            >
          </div>
        </li>
        <li class="flex">
          <div class="flex items-center">
            <svg
              class="flex-shrink-0 h-5 w-5 text-gray-300"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clip-rule="evenodd"
              />
            </svg>
            <router-link
              :to="{ name: '' }"
              class="ml-4 text-xs text-gray-500 hover:text-gray-700"
              >Tax Compliance Certificate</router-link
            >
          </div>
        </li>
      </ol>
    </nav>
    <main class="relative">
      <div class="relative vh-70 bg-gray-900 max-w-7xl mx-auto">
        <!-- <img
          class="absolute inset-0 w-full h-full object-cover z-0"
          src="../assets/img/hero-business.jpg"
          alt="NRA"
        /> -->
        <img
          v-if="headerImage.business"
          class="absolute inset-0 w-full h-full object-cover z-0"
          :src="api_url + headerImage.business.url"
          alt="NRA"
        />
        <div class="relative">
          <div class="px-8 py-12 md:pt-16 md:pb-28 md:px-24">
            <p
              class="mt-3 uppercase text-xs sm:text-base text-nra-yellow-500 sm:mt-5"
            >
              TAX INFORMATION
            </p>
            <h1
              class="text-2xl sm:text-xl ont-extrabold text-white sm:mt-1 lg:mt-1 lg:text-4xl"
            >
              <span class="sm:block">{{ taxComCert.heading }}</span>
            </h1>
          </div>
        </div>
      </div>
      <div class="bg-white">
        <div class="pb-16 sm:pb-20">
          <mobileDrop />
          <div
            class="max-w-7xl mx-auto p-8 md:p-24 sm:p-24 lg:px-24 bg-white border-t-8 border-nra-blue-500 -mt-2 relative"
          >
            <div class="grid grid-cols-3 gap-12">
              <!-- sidenav -->
              <!-- <sideNav /> -->

              <div
                class="w-auto col-span-3 md:col-span-1 hidden md:block"
                x-data="{selected:6}"
              >
                <div
                  class="flex flex-col overflow-hidden border-b border-gray-300"
                >
                  <h3
                    id="0"
                    class="block text-xl font-extrabold text-gray-900 py-3 cursor-pointer"
                    x-on:click="selected !== 0 ? selected = 0 : selected = null"
                  >
                    Introduction
                    <div class="relative float-right">
                      <svg
                        class="h-5 w-5 absolute top-1 right-0 transition-all duration-300 text-gray-300"
                        x-bind:style="selected == 0 ? 'transform: rotate(90deg); color: #000000;' : ''"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M9 5l7 7-7 7"
                        />
                      </svg>
                    </div>
                  </h3>
                  <div
                    class="relative overflow-hidden transition-all max-h-0 duration-300"
                    x-ref="section1"
                    x-bind:style="selected == 0 ? 'max-height: ' + $refs.section1.scrollHeight + 'px' : ''"
                  >
                    <div class="py-6">
                      <ul class="space-y-4">
                        <li
                          class="text-base truncate pb-3"
                          v-for="bsn in businessIntroductions"
                          v-bind:key="bsn.id"
                        >
                          <router-link
                            :to="{
                              name: 'bsnIntro',
                              params: { id: bsn.id, slug: bsn.slug },
                            }"
                            :key="bsn.id"
                            class="hover:text-gray-700"
                          >
                            {{ bsn.heading }}
                          </router-link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div
                  class="flex flex-col overflow-hidden border-b border-gray-300"
                >
                  <h3
                    class="block text-xl font-extrabold text-gray-900 py-3 cursor-pointer"
                    x-on:click="selected !== 1 ? selected = 1 : selected = null"
                  >
                    TIN Registration for business
                    <div class="relative float-right">
                      <svg
                        class="h-5 w-5 absolute top-1 right-0 transition-all duration-300 text-gray-300"
                        x-bind:style="selected == 1 ? 'transform: rotate(90deg); color: #000000;' : ''"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M9 5l7 7-7 7"
                        />
                      </svg>
                    </div>
                  </h3>
                  <div
                    class="relative overflow-hidden transition-all max-h-0 duration-300"
                    x-ref="section2"
                    x-bind:style="selected == 1 ? 'max-height: ' + $refs.section2.scrollHeight + 'px' : ''"
                  >
                    <div class="py-6">
                      <ul class="space-y-4">
                        <li
                          class="text-base truncate pb-3"
                          v-for="tin in tinRegBuses"
                          v-bind:key="tin.id"
                        >
                          <router-link
                            :to="{
                              name: 'bsnTinReg',
                              params: { id: tin.id, slug: tin.slug },
                            }"
                            :key="tin.id"
                            class="hover:text-gray-700"
                          >
                            {{ tin.heading }}
                          </router-link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div
                  class="flex flex-col overflow-hidden border-b border-gray-300"
                >
                  <h3
                    class="block text-xl font-extrabold text-gray-900 py-3 cursor-pointer"
                    x-on:click="selected !== 2 ? selected = 2 : selected = null"
                  >
                    Taxes applied to Businesses
                    <div class="relative float-right">
                      <svg
                        class="h-5 w-5 absolute top-1 right-0 transition-all duration-300 text-gray-300"
                        x-bind:style="selected == 2 ? 'transform: rotate(90deg); color: #000000;' : ''"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M9 5l7 7-7 7"
                        />
                      </svg>
                    </div>
                  </h3>
                  <div
                    class="relative overflow-hidden transition-all max-h-0 duration-300"
                    x-ref="section3"
                    x-bind:style="selected == 2 ? 'max-height: ' + $refs.section3.scrollHeight + 'px' : ''"
                  >
                    <div class="py-6">
                      <ul class="space-y-4">
                        <li
                          class="text-base truncate"
                          v-for="tax in taxAppBsns"
                          v-bind:key="tax.id"
                        >
                          <router-link
                            :to="{
                              name: 'taxApp',
                              params: { id: tax.id, slug: tax.slug },
                            }"
                            :key="tax.id"
                            class="hover:text-gray-700"
                          >
                            {{ tax.heading }}
                          </router-link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <!-- <div
                  class="flex flex-col overflow-hidden border-b border-gray-300"
                >
                  <h3
                    class="block text-xl font-extrabold text-gray-900 py-3 cursor-pointer"
                    x-on:click="selected !== 3 ? selected = 3 : selected = null"
                  >
                    Filing and Payment
                    <div class="relative float-right">
                      <svg
                        class="h-5 w-5 absolute top-1 right-0 transition-all duration-300 text-gray-300"
                        x-bind:style="selected == 3 ? 'transform: rotate(90deg); color: #000000;' : ''"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M9 5l7 7-7 7"
                        />
                      </svg>
                    </div>
                  </h3>
                  <div
                    class="relative overflow-hidden transition-all max-h-0 duration-300"
                    x-ref="section4"
                    x-bind:style="selected == 3 ? 'max-height: ' + $refs.section4.scrollHeight + 'px' : ''"
                  >
                    <div class="py-6">
                      <ul class="space-y-4">
                        <li class="text-base truncate pb-3">
                          <a href="#0" class="hover:text-gray-700"> - </a>
                        </li>
                        <li class="text-base truncate pb-3">
                          <a href="#0" class="hover:text-gray-700"> - </a>
                        </li>
                        <li class="text-base truncate pb-3">
                          <a href="#0" class="hover:text-gray-700"> - </a>
                        </li>
                        <li class="text-base truncate pb-3">
                          <a href="#0" class="hover:text-gray-700"> - </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div> -->

                <div
                  class="flex flex-col overflow-hidden border-b border-gray-300"
                >
                  <h3
                    class="block text-xl font-extrabold text-gray-900 py-3 cursor-pointer"
                    x-on:click="selected !== 4 ? selected = 4 : selected = null"
                  >
                    Penalties
                    <div class="relative float-right">
                      <svg
                        class="h-5 w-5 absolute top-1 right-0 transition-all duration-300 text-gray-300"
                        x-bind:style="selected == 4 ? 'transform: rotate(90deg); color: #000000;' : ''"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M9 5l7 7-7 7"
                        />
                      </svg>
                    </div>
                  </h3>
                  <div
                    class="relative overflow-hidden transition-all max-h-0 duration-300"
                    x-ref="section5"
                    x-bind:style="selected == 4 ? 'max-height: ' + $refs.section5.scrollHeight + 'px' : ''"
                  >
                    <div class="py-6">
                      <ul class="space-y-4">
                        <li
                          class="text-base truncate pb-3"
                          v-for="penalty in penalties"
                          v-bind:key="penalty.id"
                        >
                          <router-link
                            :to="{
                              name: 'penalties',
                              params: { id: penalty.id, slug: penalty.slug },
                            }"
                            :key="penalty.id"
                            class="hover:text-gray-700"
                          >
                            {{ penalty.heading }}
                          </router-link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div
                  class="flex flex-col overflow-hidden border-b border-gray-300"
                >
                  <h3
                    class="block text-xl font-extrabold text-gray-900 py-3 cursor-pointer"
                    x-on:click="selected !== 5 ? selected = 5 : selected = null"
                  >
                    Deduction and depreciation
                    <div class="relative float-right">
                      <svg
                        class="h-5 w-5 absolute top-1 right-0 transition-all duration-300 text-gray-300"
                        x-bind:style="selected == 5 ? 'transform: rotate(90deg); color: #000000;' : ''"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M9 5l7 7-7 7"
                        />
                      </svg>
                    </div>
                  </h3>
                  <div
                    class="relative overflow-hidden transition-all max-h-0 duration-300"
                    x-ref="section6"
                    x-bind:style="selected == 5 ? 'max-height: ' + $refs.section6.scrollHeight + 'px' : ''"
                  >
                    <div class="py-6">
                      <ul class="space-y-4">
                        <li
                          class="text-base truncate"
                          v-for="dnd in dedAndDeps"
                          v-bind:key="dnd.id"
                        >
                          <router-link
                            :to="{
                              name: 'deductions',
                              params: { id: dnd.id, slug: dnd.slug },
                            }"
                            :key="dnd.id"
                            class="hover:text-gray-700"
                          >
                            {{ dnd.heading }}
                          </router-link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div
                  class="flex flex-col overflow-hidden border-b border-gray-300"
                >
                  <h3
                    class="block text-xl font-extrabold text-gray-900 py-3 cursor-pointer"
                    x-on:click="selected !== 6 ? selected = 6 : selected = null"
                  >
                    Tax Compliance Certificate
                    <div class="relative float-right">
                      <svg
                        class="h-5 w-5 absolute top-1 right-0 transition-all duration-300 text-gray-300"
                        x-bind:style="selected == 6 ? 'transform: rotate(90deg); color: #000000;' : ''"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M9 5l7 7-7 7"
                        />
                      </svg>
                    </div>
                  </h3>
                  <div
                    class="relative overflow-hidden transition-all max-h-0 duration-300"
                    x-ref="section7"
                    x-bind:style="selected == 6 ? 'max-height: ' + $refs.section7.scrollHeight + '200px' : ''"
                  >
                    <div class="py-6">
                      <ul class="space-y-4">
                        <li
                          class="text-base truncate pb-3"
                          v-for="tcc in taxComCerts"
                          v-bind:key="tcc.id"
                        >
                          <router-link
                            :to="{
                              name: 'taxComp',
                              params: { id: tcc.id, slug: tcc.slug },
                            }"
                            :key="tcc.id"
                            class="hover:text-gray-700"
                          >
                            {{ tcc.heading }}
                          </router-link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div
                  class="flex flex-col overflow-hidden border-b border-gray-300"
                >
                  <router-link
                    :to="{ name: 'bsnFaqs' }"
                    class="block text-xl font-extrabold text-gray-900 py-3 cursor-pointer"
                  >
                    Frequently Asked Questions
                    <div class="relative float-right">
                      <svg
                        class="h-5 w-5 absolute top-1 right-0 text-gray-300"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M9 5l7 7-7 7"
                        />
                      </svg>
                    </div>
                  </router-link>
                </div>
              </div>

              <!-- ... -->
              <div
                v-if="taxComCert.list"
                class="w-auto col-span-3 md:col-span-2"
              >
                <div class="border-b border-gray-300 pb-6 mb-6">
                  <p
                    id="0"
                    class="border-l-4 border-nra-cyan-500 pl-1 font-bold text-xs"
                  >
                    SECTION 7/8
                  </p>
                  <h2
                    class="text-3xl font-extrabold text-gray-900 sm:text-4xl mt-2"
                  >
                    {{ taxComCert.title }}
                  </h2>
                </div>

                <h2 class="text-xl font-extrabold text-gray-900">
                  {{ taxComCert.heading }}
                </h2>
                <p class="mt-4 text-lg text-gray-800">{{ taxComCert.intro }}</p>
                <p class="mt-4 text-lg text-gray-800">
                  {{ taxComCert.content }}
                </p>

                <ul class="list-disc mt-4 text-lg text-gray-800 pl-6">
                  <li v-if="taxComCert.list.item1">
                    {{ taxComCert.list.item1 }}
                  </li>
                  <li v-if="taxComCert.list.item2">
                    {{ taxComCert.list.item2 }}
                  </li>
                  <li v-if="taxComCert.list.item3">
                    {{ taxComCert.list.item3 }}
                  </li>
                  <li v-if="taxComCert.list.item4">
                    {{ taxComCert.list.item4 }}
                  </li>
                  <li v-if="taxComCert.list.item5">
                    {{ taxComCert.list.item5 }}
                  </li>
                  <li v-if="taxComCert.list.item6">
                    {{ taxComCert.list.item6 }}
                  </li>
                </ul>

                <cta />
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import gql from "graphql-tag";
// import sideNav from './nav/sideNav'
import MainNav from "@/components/MainNav.vue"

import mainNav from "./nav/mainNav";
import cta from "../components/callToAction";
import mobileDrop from "./nav/mobileDrop";

export default {
  data() {
    return {
      taxComCert: [],

      businessIntroductions: [],
      tinRegBuses: [],
      taxAppBsns: [],
      penalties: [],
      dedAndDeps: [],
      taxComCerts: [],
      headerImage: [],
      api_url: process.env.VUE_APP_STRAPI_API_URL,
      routeParam: this.$route.params.id,
    };
  },
  components: {
    // sideNav,
    mainNav,
    cta,
    mobileDrop,
    MainNav
  },
  mounted() {
    window.scrollTo(0, 0);
  },

  apollo: {
    taxComCert: {
      query: gql`
        query taxComCert($id: ID!) {
          taxComCert(id: $id) {
            id
            title
            heading
            intro
            content
            list {
              item1
              item2
              item3
              item4
              item5
              item6
            }
          }
        }
      `,

      variables() {
        return {
          id: this.routeParam,
        };
      },
    },
    businessIntroductions: gql`
      query businessIntroductions {
        businessIntroductions {
          id
          heading
          slug
        }
      }
    `,
    tinRegBuses: gql`
      query tinRegBuses {
        tinRegBuses {
          id
          heading
          slug
        }
      }
    `,
    taxAppBsns: gql`
      query taxAppBsns {
        taxAppBsns {
          id
          heading
          slug
        }
      }
    `,
    penalties: gql`
      query penalties {
        penalties {
          id
          heading
          slug
        }
      }
    `,
    dedAndDeps: gql`
      query dedAndDeps {
        dedAndDeps {
          id
          heading
          slug
        }
      }
    `,
    taxComCerts: gql`
      query taxComCerts {
        taxComCerts {
          id
          heading
          slug
        }
      }
    `,
    headerImage: gql`
      query headerImage {
        headerImage {
          business {
            url
          }
        }
      }
    `,
  },
};
</script>
