<template>
  <div
    class="w-auto col-span-3 md:col-span-1 hidden md:block"
    x-data="{selected:0}"
  >
    <div class="flex flex-col overflow-hidden border-b border-gray-300">
      <h3
        id="0"
        class="block text-xl font-extrabold text-gray-900 py-3 cursor-pointer"
        x-on:click="selected !== 0 ? selected = 0 : selected = null"
      >
        Introduction
        <div class="relative float-right">
          <svg
            class="h-5 w-5 absolute top-1 right-0 transition-all duration-300 text-gray-300"
            x-bind:style="selected == 0 ? 'transform: rotate(90deg); color: #000000;' : ''"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M9 5l7 7-7 7"
            />
          </svg>
        </div>
      </h3>
      <div
        class="relative overflow-hidden transition-all max-h-0 duration-300"
        x-ref="section1"
        x-bind:style="selected == 0 ? 'max-height: ' + $refs.section1.scrollHeight + '200px' : ''"
      >
        <div class="py-6">
          <ul class="space-y-4">
            <li
              class="text-base truncate pb-3"
              v-for="bsn in businessIntroductions"
              v-bind:key="bsn.id"
            >
              <router-link
                :to="{
                  name: 'bsnIntro',
                  params: { id: bsn.id, slug: bsn.slug },
                }"
                :key="bsn.id"
                class="hover:text-gray-700"
              >
                {{ bsn.heading }}
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="flex flex-col overflow-hidden border-b border-gray-300">
      <h3
        class="block text-xl font-extrabold text-gray-900 py-3 cursor-pointer"
        x-on:click="selected !== 1 ? selected = 1 : selected = null"
      >
        TIN Registration for business
        <div class="relative float-right">
          <svg
            class="h-5 w-5 absolute top-1 right-0 transition-all duration-300 text-gray-300"
            x-bind:style="selected == 1 ? 'transform: rotate(90deg); color: #000000;' : ''"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M9 5l7 7-7 7"
            />
          </svg>
        </div>
      </h3>
      <div
        class="relative overflow-hidden transition-all max-h-0 duration-300"
        x-ref="section2"
        x-bind:style="selected == 1 ? 'max-height: ' + $refs.section2.scrollHeight + 'px' : ''"
      >
        <div class="py-6">
          <ul class="space-y-4">
            <li
              class="text-base truncate pb-3"
              v-for="tin in tinRegBuses"
              v-bind:key="tin.id"
            >
              <router-link
                :to="{
                  name: 'bsnTinReg',
                  params: { id: tin.id, slug: tin.slug },
                }"
                :key="tin.id"
                class="hover:text-gray-700"
              >
                {{ tin.heading }}
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="flex flex-col overflow-hidden border-b border-gray-300">
      <h3
        class="block text-xl font-extrabold text-gray-900 py-3 cursor-pointer"
        x-on:click="selected !== 2 ? selected = 2 : selected = null"
      >
        Taxes applied to Businesses
        <div class="relative float-right">
          <svg
            class="h-5 w-5 absolute top-1 right-0 transition-all duration-300 text-gray-300"
            x-bind:style="selected == 2 ? 'transform: rotate(90deg); color: #000000;' : ''"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M9 5l7 7-7 7"
            />
          </svg>
        </div>
      </h3>
      <div
        class="relative overflow-hidden transition-all max-h-0 duration-300"
        x-ref="section3"
        x-bind:style="selected == 2 ? 'max-height: ' + $refs.section3.scrollHeight + 'px' : ''"
      >
        <div class="py-6">
          <ul class="space-y-4">
            <li
              class="text-base truncate"
              v-for="tax in taxAppBsns"
              v-bind:key="tax.id"
            >
              <router-link
                :to="{
                  name: 'taxApp',
                  params: { id: tax.id, slug: tax.slug },
                }"
                :key="tax.id"
                class="hover:text-gray-700"
              >
                {{ tax.heading }}
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- <div class="flex flex-col overflow-hidden border-b border-gray-300">
      <h3
        class="block text-xl font-extrabold text-gray-900 py-3 cursor-pointer"
        x-on:click="selected !== 3 ? selected = 3 : selected = null"
      >
        Filing and Payment
        <div class="relative float-right">
          <svg
            class="h-5 w-5 absolute top-1 right-0 transition-all duration-300 text-gray-300"
            x-bind:style="selected == 3 ? 'transform: rotate(90deg); color: #000000;' : ''"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M9 5l7 7-7 7"
            />
          </svg>
        </div>
      </h3>
      <div
        class="relative overflow-hidden transition-all max-h-0 duration-300"
        x-ref="section4"
        x-bind:style="selected == 3 ? 'max-height: ' + $refs.section4.scrollHeight + 'px' : ''"
      >
        <div class="py-6">
          <ul class="space-y-4">
            <li class="text-base truncate pb-3">
              <a href="#0" class="hover:text-gray-700"> - </a>
            </li>
            <li class="text-base truncate pb-3">
              <a href="#0" class="hover:text-gray-700"> - </a>
            </li>
            <li class="text-base truncate pb-3">
              <a href="#0" class="hover:text-gray-700"> - </a>
            </li>
            <li class="text-base truncate pb-3">
              <a href="#0" class="hover:text-gray-700"> - </a>
            </li>
          </ul>
        </div>
      </div>
    </div> -->

    <div class="flex flex-col overflow-hidden border-b border-gray-300">
      <h3
        class="block text-xl font-extrabold text-gray-900 py-3 cursor-pointer"
        x-on:click="selected !== 4 ? selected = 4 : selected = null"
      >
        Penalties
        <div class="relative float-right">
          <svg
            class="h-5 w-5 absolute top-1 right-0 transition-all duration-300 text-gray-300"
            x-bind:style="selected == 4 ? 'transform: rotate(90deg); color: #000000;' : ''"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M9 5l7 7-7 7"
            />
          </svg>
        </div>
      </h3>
      <div
        class="relative overflow-hidden transition-all max-h-0 duration-300"
        x-ref="section5"
        x-bind:style="selected == 4 ? 'max-height: ' + $refs.section5.scrollHeight + 'px' : ''"
      >
        <div class="py-6">
          <ul class="space-y-4">
            <li
              class="text-base truncate pb-3"
              v-for="penalty in penalties"
              v-bind:key="penalty.id"
            >
              <router-link
                :to="{
                  name: 'penalties',
                  params: { id: penalty.id, slug: penalty.slug },
                }"
                :key="penalty.id"
                class="hover:text-gray-700"
              >
                {{ penalty.heading }}
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="flex flex-col overflow-hidden border-b border-gray-300">
      <h3
        class="block text-xl font-extrabold text-gray-900 py-3 cursor-pointer"
        x-on:click="selected !== 5 ? selected = 5 : selected = null"
      >
        Deduction and depreciation
        <div class="relative float-right">
          <svg
            class="h-5 w-5 absolute top-1 right-0 transition-all duration-300 text-gray-300"
            x-bind:style="selected == 5 ? 'transform: rotate(90deg); color: #000000;' : ''"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M9 5l7 7-7 7"
            />
          </svg>
        </div>
      </h3>
      <div
        class="relative overflow-hidden transition-all max-h-0 duration-300"
        x-ref="section6"
        x-bind:style="selected == 5 ? 'max-height: ' + $refs.section6.scrollHeight + 'px' : ''"
      >
        <div class="py-6">
          <ul class="space-y-4">
            <li
              class="text-base truncate"
              v-for="dnd in dedAndDeps"
              v-bind:key="dnd.id"
            >
              <router-link
                :to="{
                  name: 'deductions',
                  params: { id: dnd.id, slug: dnd.slug },
                }"
                :key="dnd.id"
                class="hover:text-gray-700"
              >
                {{ dnd.heading }}
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="flex flex-col overflow-hidden border-b border-gray-300">
      <h3
        class="block text-xl font-extrabold text-gray-900 py-3 cursor-pointer"
        x-on:click="selected !== 6 ? selected = 6 : selected = null"
      >
        Tax Compliance Certificate
        <div class="relative float-right">
          <svg
            class="h-5 w-5 absolute top-1 right-0 transition-all duration-300 text-gray-300"
            x-bind:style="selected == 6 ? 'transform: rotate(90deg); color: #000000;' : ''"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M9 5l7 7-7 7"
            />
          </svg>
        </div>
      </h3>
      <div
        class="relative overflow-hidden transition-all max-h-0 duration-300"
        x-ref="section7"
        x-bind:style="selected == 6 ? 'max-height: ' + $refs.section7.scrollHeight + 'px' : ''"
      >
        <div class="py-6">
          <ul class="space-y-4">
            <li
              class="text-base truncate pb-3"
              v-for="tcc in taxComCerts"
              v-bind:key="tcc.id"
            >
              <router-link
                :to="{
                  name: 'taxComp',
                  params: { id: tcc.id, slug: tcc.slug },
                }"
                :key="tcc.id"
                class="hover:text-gray-700"
              >
                {{ tcc.heading }}
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="flex flex-col overflow-hidden border-b border-gray-300">
      <router-link
        :to="{ name: 'bsnFaqs' }"
        class="block text-xl font-extrabold text-gray-900 py-3 cursor-pointer"
      >
        Frequently Asked Questions
        <div class="relative float-right">
          <svg
            class="h-5 w-5 absolute top-1 right-0 text-gray-300"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M9 5l7 7-7 7"
            />
          </svg>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import gql from "graphql-tag";

export default {
  name: "",
  data() {
    return {
      businessIntroductions: [],
      tinRegBuses: [],
      taxAppBsns: [],
      penalties: [],
      dedAndDeps: [],
      taxComCerts: [],
      api_url: process.env.VUE_APP_STRAPI_API_URL,
      routeParam: this.$route.params.id,
    };
  },
  apollo: {
    businessIntroductions: gql`
      query businessIntroductions {
        businessIntroductions {
          id
          heading
          slug
        }
      }
    `,
    tinRegBuses: gql`
      query tinRegBuses {
        tinRegBuses {
          id
          heading
          slug
        }
      }
    `,
    taxAppBsns: gql`
      query taxAppBsns {
        taxAppBsns {
          id
          heading
          slug
        }
      }
    `,
    penalties: gql`
      query penalties {
        penalties {
          id
          heading
          slug
        }
      }
    `,
    dedAndDeps: gql`
      query dedAndDeps {
        dedAndDeps {
          id
          heading
          slug
        }
      }
    `,
    taxComCerts: gql`
      query taxComCerts {
        taxComCerts {
          id
          heading
          slug
        }
      }
    `,
    // variables() {
    //   return {
    //     id: this.routeParam,
    //   };
    // },
  },
};
</script>
