<template>
<div>
    <!-- Main NAV -->
    <MainNav />

    <main class="relative">
        <section class="bg-white">
            <div v-if="home.section1" class="relative vh-70 bg-gray-900 max-w-7xl mx-auto">
                <img v-if="headerImage.homepage" class="absolute inset-0 w-full h-full object-cover z-0" :src="api_url + headerImage.homepage.url" alt="NRA" />
                <div class="max-w-7xl mx-auto relative">
                    <div class="py-16 sm:py-40">
                        <div class="px-8 sm:px-24">
                            <div class="max-w-xs sm:max-w-xl">
                                <p class="mt-3 uppercase text-xs sm:text-base text-nra-orange-500 sm:mt-5">
                                    {{ home.section1.title }}
                                </p>
                                <h1 class="mt-4 text-3xl font-bold text-white sm:mt-5 lg:mt-6 lg:text-4xl">
                                    {{ home.section1.heading }}
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div class="relative lg:px-8">

            <div class="md:hidden px-8 py-6" v-if="loginRegLink">
                <h1 class="mb-3 font-medium text-center sm:mt-5 lg:mt-6 text-md md:text-2xl leading-0">Log In to access your account <span class="block md:inline">or Register to create a new account</span></h1>
                <div class="flex justify-center space-x-4">
                    <a v-bind:href="loginRegLink.login" class="btn btn-blue">
                        LOG IN
                    </a>
                    <a v-bind:href="loginRegLink.register" class="btn btn-orange">
                        <svg class="h-5 w-5 text-gray-900 mr-2 -ml-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 11V7a4 4 0 118 0m-4 8v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2z" />
                        </svg>
                        <span>REGISTER</span>
                    </a>
                </div>
            </div>
        </div>

        <div class="bg-nra-blue-500 max-w-7xl mx-auto px-8 py-6 md:px-24 pb-4 md:py-6">
            <div class="relative max-w-3xl">
                <form @submit.prevent="search">
                    <h1 class="mb-3 font-medium text-white sm:mt-5 lg:mt-6 text-md md:text-2xl leading-0">Search for
                        information about the NRA or taxes</h1>
                    <div class="mt-0 mb-8 flex rounded-md shadow-sm">
                        <div class="relative flex items-stretch flex-grow focus-within:z-10">
                            <input v-model="keyword.search" required type="text" class="pl-4 block w-full rounded-none rounded-l-md sm:text-lg" placeholder="I would Like to..." />
                        </div>
                        <button type="submit" class="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 text-base rounded-r-md text-black bg-nra-orange-500 hover:bg-nra-orange-400 focus:outline-none">
                            <svg class="h-5 w-5 text-gray-900" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                            </svg>
                            <span>Search</span>
                        </button>
                    </div>
                </form>
            </div>
        </div>

        <div v-if="home.section1" class="relative lg:px-8">

            <div class="relative bg-white max-w-7xl mx-auto overflow-hidden">
                <img class="absolute z-0 transform rotate-45 right-0" src="../assets/img/emblem.svg" alt="eServices Emblem" />
                <div class="relative px-8 py-6 md:px-24 md:py-12 lg:flex lg:items-center lg:justify-between">
                    <div class="flex items-center lg:mt-0 lg:flex-shrink-0">
                        <img class="h-8 w-auto sm:h-10 inline" src="../assets/img/eservices-gov-ss.svg" alt="eservices.gov.ss" />
                    </div>
                    <h2 class="mt-4 md:mt-0 text-md md:text-2xl text-gray-500 max-w-xl">
                        {{ home.section1.par2 }}
                    </h2>
                </div>
            </div>

            <div v-if="home.section2" class="py-16 md:py-20 bg-nra-cyan-100 max-w-7xl mx-auto px-8 md:px-24">
                <div class="mb-16">
                    <h2 class="text-4xl font-extrabold text-gray-900 sm:mt-5 mb-5">
                        {{ home.section2.title }}
                    </h2>
                    <p class="text-2xl text-gray-500 max-w-xl">
                        {{ home.section2.heading }}
                    </p>
                </div>
                <div class="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none sm:px-0">
                    <div>
                        <a class="block mt-2 text-xl font-semibold text-gray-900 mb-3">
                            {{ home.section2.title1 }}
                        </a>
                        <p class="text-lg text-gray-500 mb-10">
                            {{ home.section2.par1 }}
                        </p>

                        <!-- <a href="#" class="font-medium ext-blue-600"> Learn More </a> -->
                    </div>
                    <div>
                        <a class="block mt-2 text-xl font-semibold text-gray-900 mb-3">
                            {{ home.section2.title2 }}
                        </a>
                        <p class="text-lg text-gray-500 mb-10">
                            {{ home.section2.par2 }}
                        </p>

                        <!-- <a href="#" class="font-medium ext-blue-600"> Learn More </a> -->
                    </div>
                    <div>
                        <a class="block mt-2 text-xl font-semibold text-gray-900 mb-3">
                            {{ home.section2.title3 }}
                        </a>
                        <p class="text-lg text-gray-500 mb-10">
                            {{ home.section2.par3 }}
                        </p>

                        <!-- <a href="#" class="font-medium ext-blue-600"> Learn More </a> -->
                    </div>
                </div>
            </div>

            <div class="relative bg-white max-w-7xl mx-auto overflow-hidden">
                <div class="relative px-8 py-6 md:px-24 md:py-12 lg:flex lg:items-center lg:justify-between">
                    <div style="padding-right: 100px" class="mt-8 flex lg:mt-0 lg:flex-shrink-0">
                        <h2 class="text-4xl font-extrabold text-gray-900 sm:mt-5 mb-5">
                            {{ home.section3.title1 }}
                        </h2>
                    </div>
                    <p class="text-gray-500 text-2xl max-w-xl">
                        {{ home.section3.body1 }}
                    </p>
                </div>
            </div>

            <div class="mx-auto max-w-7xl">
                <div class="lg:mx-auto lg:max-w-7xl lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
                    <div class="px-8 py-6 md:px-24 md:py-12 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
                        <div>
                            <div class="mt-6">
                                <h2 class="text-3xl font-extrabold text-gray-900">
                                    {{ home.section3.title2 }}
                                </h2>
                                <p class="mt-4 text-lg text-gray-500">
                                    {{ home.section3.body2 }}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="mt-12 sm:mt-16 lg:mt-0 lg:col-start-1">
                        <div class="pr-4 -ml-48 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full overflow-hiddenZzZ">
                            <img class="w-full shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none" src="../assets/img/countingmoney.jpg" alt="Customer profile user interface" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="mx-auto max-w-7xl">
                <div class="lg:mx-auto lg:max-w-7xl lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
                    <div class="px-8 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-1">
                        <div>
                            <div class="mt-6">
                                <h2 class="text-3xl font-extrabold text-gray-900">
                                    {{ home.section3.title3 }}
                                </h2>
                                <p class="mt-4 text-lg text-gray-500">
                                    {{ home.section3.body3 }}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="mt-12 sm:mt-16 lg:mt-0 lg:col-start-2">
                        <div class="md:pr-4 -ml-48 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full overflow-hidden">
                            <img class="w-full shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none" src="../assets/img/truckport.jpg" alt="Customer profile user interface" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="mx-auto max-w-7xl">
                <div class="lg:mx-auto lg:max-w-7xl lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
                    <div class="px-8 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
                        <div>
                            <div class="mt-6">
                                <h2 class="text-3xl font-extrabold text-gray-900">
                                    {{ home.section3.title4 }}
                                </h2>
                                <p class="mt-4 text-lg text-gray-500">
                                    {{ home.section3.body4 }}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="mt-12 sm:mt-16 lg:mt-0 lg:col-start-1">
                        <div class="md:pr-4 -ml-48 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full overflow-hidden">
                            <img class="w-full shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none" src="../assets/img/ladyoncomp.jpg" alt="Customer profile user interface" />
                        </div>
                    </div>
                </div>
            </div>

            <div class="relative vh-70 bg-indigo-50 max-w-7xl mx-auto">
                <img class="absolute inset-0 w-full h-full object-cover z-0 rounded-md" src="../assets/img/invest.jpg" alt="National Revenue Athority" />

                <div class="relative py-12 px-8 sm:px-6 lg:py-24 lg:px-24 lg:flex lg:items-center lg:justify-between">
                    <h2 class="text-2xl font-extrabold ext-gray-900 sm:text-2xl max-w-sm">
                        <span class="block">{{ home.section4.title1 }}</span>
                    </h2>
                    <div class="mt-8 flex lg:mt-0 lg:flex-shrink-0">
                        <div class="inline-flex rounded-md">
                            <router-link :to="{
                    name: 'invIntro',
                    params: { id: '1', slug: 'investment-in-south-sudan' },
                  }" class="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md bg-nra-orange-500 hover:bg-nra-orange-400">
                                Get started
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>

            <!-- <div class="max-w-7xl mx-auto py-12 px-8 sm:px-6 lg:py-16 lg:px-24 bg-nra-cyan-100">
                <h1 class="mb-16 text-xl font-extrabold text-gray-900 sm:mt-5 lg:mt-6 lg:text-4xl text-center">
                    <span class="sm:block">{{ home.section4.title2 }}</span>
                </h1>
                <div class="pb-8 xl:grid xl:grid-cols-4 xl:gap-8">
                    <div>
                        <h3 class="text-xs font-semibold text-gray-900 tracking-wider uppercase">
                            Individual
                        </h3>
                        <ul class="mt-4 space-y-4">
                            <li>
                                <a href="#" class="text-base text-gray-700 underline hover:no-underline">
                                    Filling & paying
                                </a>
                            </li>

                            <li>
                                <a href="#" class="text-base text-gray-700 underline hover:no-underline">
                                    TIN registration
                                </a>
                            </li>

                            <li>
                                <a href="#" class="text-base text-gray-700 underline hover:no-underline">
                                    Calculate tax
                                </a>
                            </li>

                            <li>
                                <a href="#" class="text-base text-gray-700 underline hover:no-underline">
                                    Importing
                                </a>
                            </li>

                            <li>
                                <a href="#" class="text-base text-gray-700 underline hover:no-underline">
                                    Special needs
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="mt-12 sm:mt-0">
                        <h3 class="text-xs font-semibold text-gray-900 tracking-wider uppercase">
                            Business
                        </h3>
                        <ul class="mt-4 space-y-4">
                            <li>
                                <a href="#" class="text-base text-gray-700 underline hover:no-underline">
                                    Small traders
                                </a>
                            </li>

                            <li>
                                <a href="#" class="text-base text-gray-700 underline hover:no-underline">
                                    Compliances & penalties
                                </a>
                            </li>

                            <li>
                                <a href="#" class="text-base text-gray-700 underline hover:no-underline">
                                    Not-for-profit
                                </a>
                            </li>

                            <li>
                                <a href="#" class="text-base text-gray-700 underline hover:no-underline">
                                    Societies
                                </a>
                            </li>

                            <li>
                                <a href="#" class="text-base text-gray-700 underline hover:no-underline">
                                    Companies and Partnerships
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="mt-12 sm:mt-0">
                        <h3 class="text-xs font-semibold text-gray-900 tracking-wider uppercase">
                            Business
                        </h3>
                        <ul class="mt-4 space-y-4">
                            <li>
                                <a href="#" class="text-base text-gray-700 underline hover:no-underline">
                                    Incentives & Ceritifications
                                </a>
                            </li>

                            <li>
                                <a href="#" class="text-base text-gray-700 underline hover:no-underline">
                                    TIN registrations
                                </a>
                            </li>

                            <li>
                                <a href="#" class="text-base text-gray-700 underline hover:no-underline">
                                    Investment procedures
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="mt-12 sm:mt-0">
                        <h3 class="text-xs font-semibold text-gray-900 tracking-wider uppercase">
                            Agents
                        </h3>
                        <ul class="mt-4 space-y-4">
                            <li>
                                <a href="#" class="text-base text-gray-700 underline hover:no-underline">
                                    Customs Agents
                                </a>
                            </li>

                            <li>
                                <a href="#" class="text-base text-gray-700 underline hover:no-underline">
                                    Tax Agents
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div> -->
        </div>

        <div v-if="home.section5" class="">
            <div class="py-16 sm:py-20">
                <div class="max-w-7xl mx-auto px-8 sm:px-24 mb-16">
                    <p class="mt-3 uppercase text-bold text-gray-900 sm:mt-5 mb-4">
                        {{ home.section5.title }}
                    </p>
                    <h2 class="text-2xl font-extrabold ext-gray-900 sm:text-2xl max-w-md">
                        <span class="block">{{ home.section5.heading }}</span>
                    </h2>
                </div>
                <div class="relative max-w-7xl mx-auto px-4 sm:px-24">
                    <div class="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none sm:px-0">
                        <div class="flex flex-col overflow-hidden" v-for="news in newsUpdates.slice(-3).reverse()" v-bind:key="news.id">
                            <div class="flex-shrink-0">
                                <iframe class="h-48 w-full object-cover" v-bind:src="news.videoUrl">
                                </iframe>
                            </div>
                            <div class="flex-1 py-6 flex flex-col justify-between">
                                <div class="flex-1">
                                    <p class="text-sm font-medium text-gray-500 uppercase">
                                        <time datetime="2020-03-10">
                                            {{ news.date }}
                                        </time>
                                    </p>
                                    <a v-bind:href="news.youtubeUrl" target="_blank" class="block mt-2">
                                        <p class="text-xl font-semibold text-gray-900">
                                            {{ news.body }}
                                        </p>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="max-w-2xl mx-auto text-center">
                        <router-link :to="{ name: 'news' }" class="mt-8 w-full inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md bg-nra-orange-500 hover:bg-nra-orange-400 sm:w-auto">
                            View all News
                        </router-link>
                    </div> -->
                </div>
            </div>
        </div>
    </main>
</div>
</template>

<script>
import gql from "graphql-tag";
import MainNav from "@/components/MainNav.vue"
export default {
    data() {
        return {
            fixed: false,
            //  stickyHeight:[],

            home: [],
            keyword: [],
            newsUpdates: [],
            headerImage: [],
            api_url: process.env.VUE_APP_STRAPI_API_URL,
            routeParam: this.$route.params.id,
        };
    },
    components: {
        // stickyHeight,
        MainNav
    },
    methods: {
        search() {
            console.log(this.keyword)
            this.$router.push("/search?" + this.keyword.search);
            localStorage.setItem('keyword', this.keyword.search)
        }
    },
    mounted() {
        window.scrollTo(0, 0);
    },

    apollo: {
        home: gql `
      query {
        home {
          section1 {
            title
            heading
            par1
            par2
          }
          section2 {
            title
            heading
            title1
            par1
            title2
            par2
            title3
            par3
          }
          section3 {
            title1
            body1
            title2
            body2
            title3
            body3
            title4
            body4
          }
          section4 {
            title1
            title2
          }
          section5 {
            title
            heading
          }
        }
      }
    `,
        loginRegLink: gql `
      # authLink
      query {
        loginRegLink {
          login
          register
        }
      }
    `,
        newsUpdates: gql `
      query {
        newsUpdates {
          id
          date
          body
          videoUrl
          youtubeUrl
          image {
            url
          }
        }
      }
    `,
        headerImage: gql `
      query headerImage {
        headerImage {
          homepage {
            url
          }
        }
      }
    `,
    },
};
</script>
